<template>
    <div class="publiadd-wrapper">
        <div class="title-box ds-flex">
          <h1 class="tab-title">Publications 추가</h1>
        </div>
        <div class="contents-box">
            <div class="gray-box ds-flex start-between">
                <div class="left">
                    <div class="data-box short">
                        <h3 class="item-title">제목</h3>
                        <input 
                            type="text" 
                            v-model="publiData.title" 
                            class="input-box input"
                            :class="{'filled' : publiData.title!==''}" 
                            placeholder="제목을 입력해주세요."
                            @input="checkInput()"
                        >
                    </div>
                    <div class="data-box short">
                        <h3 class="item-title">외부링크</h3>
                        <input 
                            type="text" 
                            v-model="publiData.url" 
                            class="input-box input" 
                            :class="{'filled' : publiData.url!==''}" 
                            placeholder="www.example.co.kr"
                            @input="checkInput()"
                        >
                    </div>
                    <!-- <div class="data-box long">
                        <div class="file-box ds-flex center-between">
                            <h3 class="item-title">파일 첨부</h3>
                            <input
                                type="file"
                                ref="upload"
                                @change="setFileList(); checkInput();"
                                style="display: none;"
                                accept="image/*"
                            />
                            <div class="ds-flex">
                                <div class="btn blue" @click="$refs.upload.click()">파일 추가</div>
                                <div class="btn gray delete" @click="emptyFile(); checkInput();">파일 삭제</div>
                            </div>
                        </div>
                        <input 
                            type="text" 
                            v-model="publiData.originName" 
                            class="input-box input file" 
                            :class="{'filled' : publiData.originName!==''}" 
                            placeholder="첨부된 파일이 없습니다." 
                            @click="$refs.upload.click()"
                            readonly
                        >
                    </div> -->
                    <div class="data-box short">
                        <h3 class="item-title">날짜</h3>
                        <div v-if="datepicker.show" class="datepicker-box">
                            <DatePicker
                                v-model="datepicker.selectedDate"
                                class="datepicker"
                                mode="date"
                                :minute-increment="60"
                            />
                            <div class="datepicker-btn-box ds-flex center-between">
                                <div class="datepicker-btn" @click="setDatePicker(true)">확인</div>
                                <div class="datepicker-btn" @click="setDatePicker(false)">취소</div>
                            </div>
                        </div>
                        <input type="text" v-model="publiData.date" class="input-box input calendar" @click="datepicker.show = true" :class="{'filled' : publiData.date!==''}" placeholder="날짜를 선택해주세요." readonly>
                    </div>
                    <div class="data-box short">
                        <h3 class="item-title">논문지 정보</h3>
                        <input 
                            type="text" 
                            v-model="publiData.info" 
                            class="input-box input" 
                            :class="{'filled' : publiData.info!==''}" 
                            @input="checkInput()"
                        >
                    </div>
                    <div class="data-box short">
                        <h3 class="item-title">작성자</h3>
                        <input type="text" v-model="publiData.writer" class="input-box text" readonly>
                    </div>
                </div>
                <!-- <div class="right">
                    <div class="data-box">
                        <h3 class="item-title preview">이미지 미리보기</h3>
                        <div class="preview-box">
                            <div v-if="file.src === ''" class="noimage-box">
                                <img src="@/assets/admin/img_icon.png">
                                <p class="noimage-text">No image</p>
                            </div>
                            <img v-else :src="file.src">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="bottom-box ds-flex center-center">
          <div class="btn-box ds-flex">
            <div class="btn gray" :class="{'active' : publiData.isFilled}" @click="addList()">{{ msg }}</div>
            <div class="btn blue" @click="movePage('Publication')">취소</div>
          </div>
        </div>

        <div v-if="showPopup" class="popup-wrapper">
        <!-- <div class="popup-wrapper"> -->
            <div class="popup-box ds-flex center-center">
                <img src="@/assets/login/check_icon.png">
                <div class="popup-text-box ds-flex center-center">
                    <h3 class="ds-flex center-center">기술 / 특허 추가 완료</h3>
                    <p class=" ds-flex center-center">작성하신 내용으로 업로드가 완료되었습니다.</p>
                </div>
                <div class="blue-btn" @click="showPopup=false; movePage('Publication')">확인</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import {API} from "../../api.js";
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import moment from "moment";

export default {
  name: 'PublicationAdd',
  components: {
    DatePicker
  },
  data: () => ({
    user: {},
    upload: {
        server: '',
        dir: 'upload',
        menu: 'publication',
    },
    showPopup: false,
    file: { // 업로드된 파일
        data: '', // 파일 정보
        src: '', // 이미지 미리보기용 임시 경로
    },
    publiData:{
        idx: '',
        title: '',
        url: '',
        // selectedDate: '',
        originName: '', // 원본 파일 이름
        uploadName: '', // 업로드된 파일 이름
        writer: 'admin',
        info: '', // 논문지 정보
        date: '',
        isFilled: false
    },
    comboBox:{ 
        isopen: false,
        style: {},
        origin: ['인증서', '특허증 및 저작권 등록증', 'PCT 국제출원', '투자선정인증서 외 기타'], // 기존 순서
        option: [], // 사용자가 선택한 옵션에 따라 순서가 바뀜
        selected: '', // 사용자가 선택한 옵션
        fill: '#14141C', // 화살표 색상
    },
    msg: '추가',
    datepicker: {
      selectedDate: '', // 선택값
      show: false,
    },
  }),
  methods: {
    setDatePicker(confirm) {
      let picker = this.datepicker;
      let data = this.publiData;

      picker.show = false;

      if(confirm) {
        if(picker.selectedDate !== '' && picker.selectedDate !== null) { // 선택한 값이 있을 경우에만
        data.date = moment(picker.selectedDate).format('YYYY-MM-DD');
        this.checkInput();
        }
      }
    },
    // idx가 존재하면 해당되는 리스트를 가져온다.
    getPubliList() {
        let singleton = this;
        let param = {
            table: this.upload.menu,
            idx: this.publiData.idx,
            option: ''
        }
        API.getList(param, function(status, res){
            let data = singleton.publiData;
            let upload = singleton.upload;
            let file = singleton.file;

            for(let key in data){
                if(key === 'originName') {
                    data[key] = res.data[0]['origin_name'];
                } else if(key === 'uploadName') {
                    data[key] = res.data[0]['upload_name'];
                    file.src = upload.server+'/'+upload.dir+'/'+upload.menu+'/'+res.data[0]['upload_name'];
                } else if(key == 'date') {
                    data[key] = moment(res.data[0].date).format('YYYY-MM-DD');
                } else {
                    data[key] = res.data[0][key];
                }
            }
            data.isFilled = true;
            singleton.setOption();
        })
    },
    /* 선택한 파일을 저장하고, 미리보기 경로를 받아온다. */
    async setFileList() {
        let file = this.$refs.upload.files[0];
        this.file.data = file;
        this.file.src = URL.createObjectURL(file);
        this.publiData.originName = file.name;
    },
    // 파일을 업로드한다.
    async uploadFile(blob) {
        const formData = new FormData();
        formData.append('image', blob);

        return axios(this.upload.server + '/upload/file', {
            method: 'POST',
            data: formData,
            headers : {'Content-type' : 'multipart/form-data'},
            params: {'menu': this.upload.menu}
        }).then(response => {
            if (response.data) {
                return response.data.uploadName;
            }
            throw new Error('Server or network error');
        }).catch( () => {
            console.log('error..');
            return false;
        });
    },
    // 업로드된 파일을 삭제한다.
    async removeFile(fileName) {
        return axios(this.upload.server + '/delete/file', {
            method: 'POST',
            data: '',
            headers : {},
            params: {'menu': this.upload.menu, 'fileName': fileName}
        }).then(response => {
            if (response.data) {
                console.log('success .. >> ', response.data);
            }
            // throw new Error('Server or network error');
        }).catch( () => {
            console.log('error..');
            return false;
        });
    },
    emptyFile() {
        this.file.src = '';
        this.publiData.originName = '';
    },
    async addList() {
        let data = this.publiData;
        if(data.isFilled) {
            this.showPopup = true;

            if(this.file.data !== '') {
                if(data.uploadName !== '') { // 기존 리스트를 수정하는 경우
                    await this.removeFile(data.uploadName);
                }
                data.uploadName = await this.uploadFile(this.file.data);
            }

            if(data.idx !== undefined) { // UPDATE
                API.updatePubliList(data, function(status, res){
                    if(status==='error'){
                        console.log('error', res);
                    }
                })
            } else { // INSERT
                API.insertPubliList(data, function(status, res){
                    if(status==='error'){
                        console.log('error', res);
                    }
                })
            }
            
        }
    },
    setCurrTab(tab) {
      this.$router.push({name: tab.name});
      this.currTab = tab;
    },
    setOption() {
        let comb = this.comboBox;
        comb.option = [];
        
        if(comb.selected === '') {
            for(let i=0; i<comb.origin.length; i++) {
                comb.option.push(comb.origin[i]);
            }
        } else {
            comb.option.push(comb.selected);
            for(let i=0; i<comb.origin.length; i++) {
                if(comb.origin[i] !== comb.selected) {
                    comb.option.push(comb.origin[i]);
                }
            }
        }
    },
    setComBox(selected) {
        let comb = this.comboBox;
        let option = document.getElementsByClassName('option');
        let arrow = document.getElementById('arrow');
        
        if(comb.isopen) {
            for(let i=0; i<option.length; i++) {
                option[i].classList.remove('hover', 'active');
            }
            comb.style = {height: '40px'};
            comb.fill = '#14141C';
            comb.selected = selected;
            arrow.style.transform = `rotate(360deg)`
            this.setOption();
        } else {
            for(let i=0; i<option.length; i++) {
                if(i===0) { option[i].classList.add('hover', 'active'); } // comboBox가 열려있을 경우에만 hover css 동작하도록
                else { option[i].classList.add('hover'); }
            }
            comb.style = {height: (comb.origin.length*40)+'px', 'border-color': '#00ACEB'};
            comb.fill = '#00ACEB';
            arrow.style.transform = `rotate(180deg)`
        }
        
        comb.isopen = !comb.isopen;
    },
    // 모든 값이 입력되었는지 확인한다.
    checkInput() {
        for(let key in this.publiData){
            if(key !== 'originName' && key !== 'uploadName' && key !== 'url' && this.publiData[key] === '') {
                this.publiData.isFilled = false;
                break;
            } else {
                this.publiData.isFilled = true;
            }
        }
    },
    movePage(name) {
      this.$router.push({name: name});
    },
  },
  mounted() {
    window.scrollTo(0,0);
    let data = this.publiData;
    // this.user = JSON.parse(sessionStorage.getItem('user_info'));
    // data.writer = this.user.id;
    data.idx = this.$route.query.idx;
    if(data.idx) {
        this.getPubliList();
        this.msg = '수정';
    } else {
        this.setOption();
    }
  },
  created() {
  }
}
</script>

<style scoped>
.publiadd-wrapper {
    /* min-height: 1080px; */
    overflow: auto;
}

.title-box {
  width: 100%;
  height: 70px;
  padding: 15px 30px;
  box-sizing: border-box;
  align-items: center;
}

.tab-title {
  color: #14141C;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.count-box {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
  margin-left: 15px;
}

.blue-highlight {
  color: #00ACEB;
  font-weight: 600;
  margin-left: 3px;
}

.contents-box {
  padding: 0 20px;
}

.table-header {
  width: 100%;
  height: 40px;
  background-color: #555861;
}

.table-th {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-right: 1px solid #fff;
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.table-th:last-child {
  border-right: none;
}

.table-th.title {
  width: calc(100% - 400px);
}

.check-box {
  width: 40px;
}

.check-box img {
  cursor: pointer;
}

.table-body {
  padding-top: 6px;
}

.table-td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #fff;
  border: 1px solid #DCDEE2;
  box-sizing: border-box;
}

.table-td.title {
  width: calc(100% - 400px);
  justify-content: flex-start;
  padding-left: 13px;
}

.no-data-box {
  width: 100%;
  height: 880px;
  background-color: #F2F2F5;
  border: 1px solid #B3B6C0;
  color: #707070;
  font-size: 15px;
  box-sizing: border-box;
}

.btn-box {
  position: absolute;
  right: 20px;
}

.btn {
  width: 110px;
  height: 40px;
  border-radius: 5px;
  background-color: #F2F2F5;
  border: 1px solid;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  margin-right: 10px;
  font-weight: 600;
  box-sizing: border-box;
}

.btn:last-child {
  margin-right: 0;
}

.btn.blue {
  cursor: pointer;
  border-color: #00ACEB;
  color: #00ACEB;
}

.btn.blue:hover {
  color: #fff;
  background-color: #00ACEB;
}

.btn.gray {
  border-color: #B3B6C0;
  color: #B3B6C0;
}

.btn.delete:hover {
    border-color: #FF3E3E;
    color: #FF3E3E;
}

.btn.active {
    border-color: #00ACEB;
    color: #00ACEB;
    cursor: pointer;
}

.btn.active:hover {
    background-color: #00ACEB;
    color: #fff;
}

.bottom-box {
  position: relative;
  height: 80px;
  padding: 20px;
  box-sizing: border-box;
}

.gray-box {
    width: 100%;
    min-height: calc(100vh - 150px);
    background-color: #E3E4EA;
    border: 1px solid #B3B6C0;
    padding: 27.5px 35px;
    box-sizing: border-box;
}

.item-title {
    color: #14141C;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    text-align: left;
}

.data-box {
    width: calc(50% - 30px);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding: 22.5px 15px;
}

/* .data-box:first-child {
    margin-top: 0;
} */

.short {
    height: 75px;
}

.long {
    height: 80px;
}

.input-box {
    width: 100%;
    height: 40px;
    border: 1.5px solid #B3B6C0;
    border-radius: 5px;
    background-color: #F2F2F5;
    box-sizing: border-box;
    transition: .3s;
    color: #14141c;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

.input-box:hover {
    border-color: #00ACEB;
}

.input-box:focus {
    border-color: #00ACEB !important;
}

.input-box.input {
    padding: 11px 15px;
}

.input-box.text {
    border: none;
    border-bottom: 1px solid #14141C;
    border-radius: 0px;
    background-color: transparent;
    padding: 11px 15px;
}

.input-box.select {
    position: absolute;
    top: 35px;
    transition: .3s;
    overflow: hidden;
    border-color: #14141C;
    cursor: pointer;
    z-index: 10;
}

.input-box.select:hover {
    border-color: #00ACEB;
}

.input-box.select:hover path {
    fill: #00ACEB;
    transition: .3s;
}

.input-box.select .option {
    height: 40px;
    padding: 0 15px;
    transition: .3s;
}

.input-box.select .option:hover {
    color: #00ACEB;
    background-color: #E0E9F5;
}

.input-box.filled {
    border-color: #14141C;
}


.file-box {
    width: 100%;
}

.file-box  .btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    line-height: 14px;
}

.left {
    width: 100%;
    min-width: 560px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.right {
    width: 560px;
}

.item-title.preview {
    margin-bottom: 15px;
}

.preview-box {
    width: 560px;
    height: 792px;
    border: 1px solid #B3B6C0;
    background-color: #F2F2F5;
    transition: .3s;
    box-sizing: border-box;
}

.preview-box > img {
    width: 100%;
    height: 100%;
}

.noimage-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.noimage-text {
    color: #B3B6C0;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin-top: 5px;
}

.popup-wrapper {
    width: 100%;
    height: 100vh;
    background-color: rgba(14, 14, 19, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
}

.popup-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    height: 200px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 29px 0;
}

.popup-box h3 {
    width: 100%;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    height: 18px;
    align-content: center;
    margin-bottom: 10px;
}

.popup-box p {
    font-size: 12px;
    color: #000000;
    height: 14px;
    width: 100%;
    align-content: center;
}
 
.popup-box .blue-btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    margin: 0 5px;
    background-color: #00ACEB;
    align-content: center;
    cursor: pointer;
    transition: .3s;
    color: #fff;
    border-radius: 5px;
}

.popup-box .blue-btn:hover {
    background-color: #0088BB;
}

.popup-text-box {
    width: 100%;
    height: 82px;
    align-content: center;
}

.input-box.file {
    cursor: pointer;
}

.datepicker {
  border: none;
}

.datepicker-box {
  position: absolute;
  top: 100px;
  z-index: 100;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #00ACEB;
}

.input-box.calendar, .input-box.file {
  cursor: pointer;
}

.datepicker-btn-box {
  width: 170px;
  margin: auto auto 10px auto;
}

.datepicker-btn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #00ACEB;
  color: #00ACEB;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  transition: .3s;
}

.datepicker-btn:hover {
  color: #fff;
  background-color: #00ACEB;
}

@media screen and (max-width: 1439px) {
    .contents-wrapper {
        width: calc(100% - 270px);
    }

    .contents-box {
      padding: 0 15px;
    }

    .left {
        width: calc(100% - 400px);
        min-width: 415px;
    }

    .right {
        width: 360px;
    }

    .title-box {
      padding: 12px 20px;
      height: 40px;
      align-content: center;
    }

    .tab-title {
      font-size: 14px;
      line-height: 17px;
    }

    .item-title {
        font-size: 11px;
        line-height: 13px;
        padding-left: 5px;
    }

    .item-title.preview {
        margin-bottom: 5px;
    }

    .short {
        height: 48px;
    }

    .long {
        height: 55px;
    }

    .input-box {
        height: 30px;
        font-size: 11px;
        border-radius: 3px;
    }

    .input-box.input {
        padding: 5px 10px;
    }

    .input-box.text {
        padding: 5px 10px;
    }

    .input-box.select {
        top: 18px;
        z-index: 5;
    }

    .input-box.select.active {
        height: 120px;
    }

    .input-box.select .option {
        height: 30px;
        padding: 0 10px;
    }

    .input-box.number {
        width: 165px;
    }

    .posit {
        margin-right: 10px;
    }

    #arrow {
        width: 12px;
    }

    .data-box {
        margin-top: 20px;
    }

    .data-box p {
        font-size: 11px;
    }

    .file-box .btn {
        width: 55px;
        height: 20px;
        border-radius: 3px;
        font-size: 9px;
        margin-right: 5px;
    }

    .file-box .btn:last-child {
        margin-right: 0;
    }

    .btn {
        width: 80px;
        height: 25px;
        border-radius: 3px;
        font-size: 11px;
    }

    .gray-box {
        padding: 25px 30px;
    }

    .bottom-box {
        padding: 13px 0;
        height: 50px;
    }

    .preview-box {
        width: 360px;
        height: 510px
    }
}
</style>

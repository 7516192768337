<template>
    <!-- <div class="popup-wrapper ds-flex">
        <div class="left-box">
            <div class="top-logo-box ds-flex center-center">
                <img src="@/assets/admin/logo_White.png">
            </div>
            <div class="user-func-box ds-flex center-end">
                <div class="user-box ds-flex center-center">
                    <img src="@/assets/admin/profile_default.png">
                    <div class="ds-flex center-start">
                        <h3 class="id-box">admin</h3>
                        <p class="access-box">최근 접속 : 2022-00-00 00:00</p>
                    </div>
                </div>
                <div class="logout-box">
                    <img src="@/assets/admin/logout.png">
                    <p class="access-box">로그아웃</p>
                </div>
            </div>
            <div class="admin-menu-box">
                <div
                    v-for="menu in menuList" :key="menu" 
                    class="admin-menu ds-flex center-start"
                    :class="[menu.title===currMenu ? 'active':'']"
                    @click="movePage(menu.name)"
                >
                    {{ menu.title }}
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="top-title-box ds-flex center-start">
                <h2>{{ currMenu }}</h2>
            </div>
            <div class="bottom-contents-box">
                <p class="admin-sub-title">[팝업 레이어 관리]</p>
                <div class="admin-contents-box">
                    <p class="list-count-box ds-flex center-start">총 <span class="highlight">{{ popupList.length }}</span>개의 팝업이 있습니다.</p>
                    <div class="table-box">
                        <div class="table-header-box ds-flex center-start">
                            <div class="table-check-box">
                                <img src="@/assets/admin/checkbox.png">
                            </div>
                            <div class="table-title-box">제목</div>
                            <div class="table-date-box">업로드 날짜</div>
                            <div class="table-btn-box">관리</div>
                        </div>
                        <div v-for="list in popupList" :key="list" class="table-body-box ds-flex center-start">
                            <div class="table-check-box">
                                <div class="ds-flex center-center" @click="list.checked = !list.checked">
                                    <img v-if="list.checked" src="@/assets/admin/checked.png">
                                    <img v-else src="@/assets/admin/checkbox.png">
                                </div>
                            </div>
                            <div class="table-title-box">{{ list.title }}</div>
                            <div class="table-date-box">{{ list.date }}</div>
                            <div class="table-btn-box">
                                <button class="edit table-btn">수정</button>
                                <button class="delete table-btn" @click="showPopup=true">삭제</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ds-flex center-between">
                    <pagenation ref="pagenation" @page="changePage"></pagenation>
                    <div class="btn-wrapper ds-flex center-between">
                        <div class="btn new ds-flex center-between">
                            새 팝업
                            <img src="@/assets/admin/new.png">
                        </div>
                        <div class="btn delete ds-flex center-between">
                            삭제
                            <img src="@/assets/admin/delete.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    
    <div class="detailview-wrapper ds-flex">
        <!-- <div class="top-box ds-flex end-start">
            <div class="back-btn-box ds-flex center-start" @click="backToList()">
                <img src="@/assets/news/back.png">
                <div class="back-btn">Back to list</div>
            </div>
            <img class="top-box-bg" src="@/assets/news/worldwide.png" />
        </div> -->
        <div class="left-box">
            <div class="top-logo-box ds-flex center-center">
                <!-- <img src="@/assets/admin/logo_White.png"> -->
            </div>
            <div class="user-func-box ds-flex center-end">
                <div class="user-box ds-flex center-center">
                    <!-- <img src="@/assets/admin/profile_default.png"> -->
                    <div class="ds-flex center-start">
                        <h3 class="id-box">admin</h3>
                        <p class="access-box">최근 접속 : 2022-00-00 00:00</p>
                    </div>
                </div>
                <div class="logout-box">
                    <!-- <img src="@/assets/admin/logout.png"> -->
                    <p class="access-box">로그아웃</p>
                </div>
            </div>
            <div class="admin-menu-box">
                <div
                    v-for="menu in menuList" :key="menu" 
                    class="admin-menu ds-flex center-start"
                    :class="[menu.title===currMenu ? 'active':'']"
                    @click="movePage(menu.name)"
                >
                    {{ menu.title }}
                </div>
            </div>
        </div>

        <div class="news-detail-box right-box">
            <div style="width: 100%; display: flex;">
                <input
                    type="file"
                    ref="upload"
                    @change="setFileList()"
                    style="display: none;"
                />
                <button @click="$refs.upload.click()">thumbnail</button>
                <input readonly type="text" v-model="file.thumbnail" style="width: 20%;">
                <img :src="server+file.thumbnail" />
            </div>
            <div style="width: 100%; display: flex;">
                <input type="checkbox" v-model="exlink">
                <p>외부 링크 여부</p>
            </div>
            <div style="width: 100%; display: flex;">
                <p>url</p>
                <input type="text" v-model="file.url">
            </div>
            <input type="text" v-model="file.title" placeholder="제목을 입력해주세요." style="width: 100%; height: 40px;"/>
            <div id="editor" ref="editor"></div>
        </div>
    </div>
</template>

<script>
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css'
import {API} from "../../../api.js";
import axios from "axios"

export default {
  name: 'NewsWriteView',
  components: {
  },
  data: () => ({
    server: '',
    editor: null,
    exlink: false,
    file: {
        title: '',
        contents: '',
        thumbnail: '',
        exlink: false,
        url: ''
    },
  }),
  methods: {
    backToList() {
        this.$router.push({
            name:'Newsroom',
            // query: {idx: idx}
        });
    },
    setEditor() {
        this.editor = new Editor({
        el: this.$refs.editor,
        height: '700px',
        initialEditType: "wysiwyg",
        language: "ko",
        // events: {
        //   change: this.onChangeEditor,
        // },
        hooks: {
            addImageBlobHook: async (blob, callback) => {
            const uploadedImageURL = await this.uploadFile(blob);
            if(!uploadedImageURL) {
                alert('파일 업로드 실패\n서버 상태를 확인하세요.');
            } else {
                callback(this.server + uploadedImageURL, 'alt text');
            }
            }
        },
        });
    },
    /* 선택한 파일을 files에 저장한다. */
    async setFileList() {
      this.file.thumbnail = await this.uploadFile(this.$refs.upload.files[0]);
      console.log('thumbnail >> ', this.file.thumbnail);
    },
    async uploadFile(blob) {
        const formData = new FormData();
        formData.append('image', blob);

        return axios(this.server + 'news/file', {
            method: 'POST',
            data: formData,
            headers : {'Content-type' : 'multipart/form-data' }
        }).then(response => {
            if (response.data) {
                return response.data.uploadName;
            }
            throw new Error('Server or network error');
        }).catch( () => {
            console.log('error..');
            return false;
        });
    },
    saveEditor() {
        this.file.contents = this.editor.getHTML();
        if(this.exlink) {
            this.file.exlink = 1;
        } else {
            this.file.exlink = 0;
        }
        
        API.saveEditor(this.file, function(status, res){
            console.log(status, res);
        })
    }
  },
  mounted() {
    this.setEditor();
  },
  created() {
  }
}
</script>

<style scoped>
.tab-item:first-child {
    display: none;
}

.toastui-editor-defaultUI {
    z-index: 0;
}

.detailview-wrapper {
    color: #191E2C;
}

.detailview-wrapper .top-box {
    position: relative;
    padding-top: 250px;
    border-bottom: 1.5px solid #DDDDDD;
    overflow: hidden;
    height: 452px;
    box-sizing: border-box;
}

.news-detail-box {
    padding: 0px 158px;
}

.news-detail-title-box {
    width: 100%;
    height: 244px;
    border-bottom: 1px solid #DDDDDD;
}

.news-detail-title-box > div {
    width: 100%;
}

.news-detail-title-box h3 {
    font-size: 20px;
    color: #7A7A7A;
    text-align: left;
}

.news-detail-title-box input {
    font-size: 36px;
    font-weight: bold;
    text-align: left;
    padding: 10px 0 15px 0;
    width: 100%;
    border: none;
}

.news-detail-title-box p {
    font-size: 20px;
    color: #7A7A7A;
    text-align: left;
}

.news-detail-contents-box {
    padding: 40px;
}

.news-detail-contents-box a {
    padding-left: 10px;
}

.news-detail-img {
    width: 100%;
}

.news-detail-contents {
    min-height: 30vh;
    height: 100%;
    border: none;
    resize: none;
    width: 100%;
    color: #323232;
    font-size: 1.6rem;
    padding: 32px 0;
    font-size: 20px;
    text-align: left;
    line-height: 30px;
}

.news-detail-list-box {
    width: 100%;
    border-top: 1px solid #DDDDDD;
}

.prev-box, .next-box {
    border-bottom: 1px solid #DDDDDD;
}

.gray-box {
    background-color: #F2F2F2;
    width: 205px;
    height: 80px;
}

.gray-title-box {
    background-color: #fff;
    cursor: pointer;
    height: 80px;
}

.gray-title-box p {
    padding: 0 40px;
}

.left-box {
    width: 380px;
    height: 100vh;
    background-color: #222222;
    padding: 0 11px;
    box-sizing: border-box;
}

.left-box > div {
    border-bottom: 1px solid #696969;
}

.right-box {
    width: calc(100% - 380px);
    background-color: #F3F5FB;
    box-sizing: border-box;
}

.top-logo-box {
    height: 77px;
}

.user-func-box {
    height: 77px;
}

.id-box {
    color: #D5D5D5;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    text-align: left;
}

.access-box {
    color: #D5D5D5;
    font-size: 12px;
}

.user-box {
    width: 265px;
    height: 100%;
    border-right: 1px solid #696969;
}

.user-box img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

.logout-box {
    width: 88px;
    cursor: pointer;
}

.logout-box img {
    margin-bottom: 4px;
}

.left-box .admin-menu-box {
    border-bottom: none;
}

.admin-menu {
    color: #D5D5D5;
    font-size: 15px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid #414141;
    padding-left: 22px;
    cursor: pointer;
}

.admin-menu.active {
    text-decoration: underline;
    font-weight: bold;
}

.top-title-box {
    box-shadow: 0px 2px 5px #00000029;
    width: 100%;
    height: 77px;
    padding: 0 35px;
    box-sizing: border-box;
}

.top-title-box h2 {
    color: #222222;
    font-size: 24px;
    font-weight: bold;
}

.bottom-contents-box {
    padding: 35px;
    height: calc(100vh - 77px);
    box-sizing: border-box;
}

.admin-sub-title {
    color: #222222;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
}

.admin-contents-box {
    background-color: #FFFFFF;
    border: 1px solid #DCDCDC;
    margin-bottom: 25px;
    padding: 30px 0;
}

.btn-wrapper {
    width: 288px;
}

.btn-wrapper .btn {
    width: 136px;
    height: 44px;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #fff;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.btn-wrapper .btn.delete {
    border: 1.5px solid #843838;
    color: #843838;
}

.btn-wrapper .btn.new {
    border: 1.5px solid #0C2E6A;
    color: #0C2E6A;
}

.list-count-box {
    padding-left: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #222222;
}

.list-count-box .highlight {
    color: #00A7E4;
    margin-left: 3px;
    text-decoration: underline;
}

.table-box {
    margin-top: 35px;
}

.table-header-box {
    height: 57px;
    border-top: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
    box-sizing: border-box;
}

.table-header-box > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #696969;
    font-size: 15px;
    font-weight: bold;
}

.table-body-box {
    height: 57px;
    border-bottom: 1px solid #DCDCDC;
    cursor: pointer;
    box-sizing: border-box;
}

.table-body-box:hover {
    background-color: #6095F212;
    transition: .3s;
}

.table-body-box > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #696969;
    font-size: 15px;
}

.table-check-box {
    width: 5%;
}

.table-check-box > div {
    background-color: #fff;
}

.table-check-box img {
    cursor: pointer;
}

.table-title-box {
    width: 55%;
}

.table-date-box {
    width: 20%;
}

.table-btn-box {
    width: 20%;
}

.table-btn {
    width: 56px;
    height: 32px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 13px;
    border-radius: 3px;
    /* font-weight: bold; */
    cursor: pointer;
}

.table-btn.edit {
    color: #0C2E6A;
    border: 1px solid #0C2E6A;
    margin-right: 6px;
}

.table-btn.delete {
    color: #843838;
    border: 1px solid #843838;
}

.delete-popup-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.popup-box {
    width: 454px;
    height: 218px;
    box-sizing: border-box;
    border: 1px solid #DCDCDC;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
}

.popup-box > p {
    color: #4A4A4A;
    font-size: 15px;
    margin: 14px 0 24px 0;
}

.popup-btn-box button {
    width: 128px;
    height: 49px;
    box-sizing: border-box;
    border-radius: 3px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.popup-btn-box .delete {
    background-color: #843838;
    margin-right: 16px;
}

.popup-btn-box .cancel {
    background-color: #B8B8B8;
}

@media screen and (min-width: 1921px) { 
  /* .left-box {
    width: 20%;
  } */

  .right-box {
    width: calc(100% - 380px);
  }
}
</style>

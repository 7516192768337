<template>
    <div class="ci-wrapper">
      <div class="section-box">
        <p class="ci-info">ZIOVISION(지오비전)은 인공지능을 기반으로 <strong>딥러닝 영상분석, 의료 AI 관련 개발을 전문으로 하는 기업</strong>입니다.</p>
        <p class="ci-info">빠르고 정확한 인공지능 관제시스템을 통한 재해나 일상 속 위험, 인물, 자동차 탐색과 신뢰성 있는 서비스가 가능하도록</p>
        <p class="ci-info">의료 인공지능 개발을 통해 <strong>SAFETY AND WELLNESS</strong>에 기여하는 것을 목표로 하고 있습니다.</p>
        <p class="ci-info">의료(병원, 의료진), 지자체, 관공서(경찰, 학교)를 주 타겟으로 하고 있으며, 타 기업들과의 차별성을 두고 기업 이미지 강화를 위해</p>
        <p class="ci-info">ZIOVISION(지오비전)이 지향하는 아이덴티티를 정의합니다.</p>
        <img class="logo_guide">
      </div>
      <div class="section-box">
        <div class="be-box ds-flex center-between">
          <div class="brand-essence">
            <h3>BRAND</h3>
            <h3>ESSENCE</h3>
          </div>
          <div class="pc">
            <p>브랜드 에센스는 ZIOVISION(지오비전)을 하나로 묶는 목표이자 궁극적인 비전을 의미합니다.</p>
            <p>ZIOVISION(지오비전)은 새로운 심볼의 인상과 함께 발전된 분위기 속에서 전문적인 인공지능 기반의 관제 시스템, CDSS 등의</p>
            <p>경험을 제공하여 전문성 있는 기업 인지도를 높이고 ZIOVISION(지오비전) 새로운 기준을 만들어 나갈 것입니다.</p>
            <p>이를 위해 ZIOVISION(지오비전)이 지향하고자 하는 목표점을 찾고 핵심 가치를 분석합니다.</p>
          </div>
          <div class="mobile">
            <p>브랜드 에센스는 ZIOVISION(지오비전)을 하나로 묶는 목표이자 궁극적인 비전을 의미합니다.</p>
            <p>ZIOVISION(지오비전)은 새로운 심볼의 인상과 함께 발전된 분위기 속에서</p>
            <p>전문적인 인공지능 기반의 관제 시스템, CDSS 등의경험을 제공하여 전문성 있는 기업 인지도를 높이고</p>
            <p>ZIOVISION(지오비전) 새로운 기준을 만들어 나갈 것입니다.</p>
            <p>이를 위해 ZIOVISION(지오비전)이 지향하고자 하는 목표점을 찾고 핵심 가치를 분석합니다.</p>
          </div>
          <div class="mini">
            <p>브랜드 에센스는 ZIOVISION(지오비전)을 하나로 묶는 목표이자 궁극적인 비전을 의미합니다.</p>
            <p>ZIOVISION(지오비전)은 새로운 심볼의 인상과 함께</p>
            <p>발전된 분위기 속에서 전문적인 인공지능 기반의 관제 시스템,</p>
            <p>CDSS 등의경험을 제공하여 전문성 있는 기업 인지도를 높이고</p>
            <p>ZIOVISION(지오비전) 새로운 기준을 만들어 나갈 것입니다.</p>
            <p>이를 위해 ZIOVISION(지오비전)이 지향하고자 하는</p>
            <p>목표점을 찾고 핵심 가치를 분석합니다.</p>
          </div>

          <img class="essence-first">
          <img class="essence-second">
        </div>
      </div>
      <div class="section-box color ds-flex">
        <h3>BRAND COLOR</h3>
        <div class="brand-color-box ds-flex center-between">
          <div class="color-box">
            <div class="blue-box deep"></div>
            <div class="box-wrap deep">
              <div class="color-info-box">
                <div class="mini-box deep">HEX</div>
                #0C2E6A
              </div>
              <div class="color-info-box">
                <div class="mini-box deep">RGB</div>
                12 / 46 / 106
              </div>
              <div class="color-info-box">
                <div class="mini-box deep">CMYK</div>
                100 / 95 / 46 / 6
              </div>
            </div>
          </div>
          <div class="color-box">
            <div class="blue-box light"></div>
            <div class="box-wrap">
              <div class="color-info-box">
                <div class="mini-box light">HEX</div>
                #00ACEB
              </div>
              <div class="color-info-box">
                <div class="mini-box light">RGB</div>
                0 / 172 / 235
              </div>
              <div class="color-info-box">
                <div class="mini-box light">CMYK</div>
                73 / 18 / 3 / 0
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'CorpIdentity',
  components: {
    
  },
  data: () => ({
  }),
  methods: {
    downloadFile() {
      const link = document.createElement('a');
      link.href = '/Ziovision_CI.zip';
      link.setAttribute('download', 'Ziovision_CI.zip');
      document.body.appendChild(link);
      link.click();
    }
  },
}
</script>

<style scoped>
.ci-wrapper {
  /* padding: 80px 0; */
  color: #191E2C;
  border-top: 1.5px solid #00ACEB;
}

.section-box {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-box:nth-child(2n) {
  background-color: #F5F7FC;
}

.section-box > p {
  color: #1E1E1E;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.section-box .ci-info {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

strong {
  color: #0C2E6A;
  font-weight: bold;
  padding: 0 5px;
}

.be-box {
  width: 1240px
}

.be-box p {
  font-size: 14px;
  text-align: left;
  line-height: 25px;
  font-weight: 500;
}

.brand-essence h3 {
  font-size: 55px;
  font-weight: bold;
  line-height: 57px;
  text-align: left;
}

.brand-essence h3:first-child {
  color: #0C2E6A;
}

.brand-essence h3:last-child {
  color: #00ACEB;
}

.color h3 {
  color: #000000;
  font-size: 28px;
  font-weight: bold;
  width: 100%;

}

.blue-box {
  width: 165px;
  height: 165px;
  margin-right: 40px;
}

.blue-box.deep {
  background-color: #0C2E6A;
}

.blue-box.light {
  background-color: #00ACEB;
}

.mini-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 25px;
  box-sizing: border-box;
  border: 1.5px solid #000000;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

.mini-box.deep {
  color: #0C2E6A;
  border-color: #0C2E6A;
}

.mini-box.light {
  color: #00ACEB;
  border-color: #00ACEB;
}

.color-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: auto;
}

.color-info-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 15px;
}

.color-info-box:nth-child(3n) {
  padding-bottom: 0;
}

.box-wrap {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 160px;
}

.box-wrap.deep {
  width: 190px;
}

.logo_guide {
  padding-top: 80px;
  content: url("@/assets/about/logo_guide.svg");
}

.essence-first {
  padding-top: 80px;
  content: url("@/assets/about/customer_value.svg");
}

.essence-second {
  padding-top: 60px;
  content: url("@/assets/about/company_value.svg");
}

.brand-color-box {
  width: 1240px;
  padding-top: 60px;
  justify-content: space-evenly;
}

.mobile, .mini {
  display: none;
}

@media screen and (max-width: 1240px) {
  .section-box {
    padding: 40px 0;
  }

  .section-box > p {
    font-size: 12px;
    line-height: 22px;
  }

  strong {
    line-height: 22px;
  }

  .logo_guide {
    padding-top: 25px;
    width: 768px;
  }

  .be-box {
    width: 768px;
  }

  .be-box p {
    font-size: 11px;
    line-height: 20px;
  }

  .brand-essence h3 {
    font-size: 30px;
    line-height: 35px;
  }

  .essence-first {
    padding-top: 40px;
    width: 768px;
  }

  .essence-second {
    padding-top: 25px;
    width: 768px;
  }

  .color h3 {
    font-size: 18px;
  }

  .brand-color-box {
    width: 768px;
    padding-top: 20px;
  }

  .blue-box {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }

  .mini-box {
    width: 50px;
    height: 20px;
    font-size: 11px;
  }

  .color-info-box {
    font-size: 11px;
    padding-bottom: 15px;
    line-height: 13px;
  }

  .box-wrap {
    width: 130px;
  }

  .box-wrap.deep {
    width: 160px;
  }
}

@media screen and (max-width: 768px) {
  .ci-wrapper {
    border-top: none;
  }

  .pc {
    display: none;
  }

  .mobile {
    display: inline-block;
  }

  .section-box {
    padding: 30px 40px;
  }

  .section-box:first-child {
    padding-top: 0;
  }

  .logo_guide {
    width: 688px;
    height: 112px;
  }

  .essence-first {
    width: 688px;
    padding-top: 30px;
  }

  .essence-second {
    width: 688px;
    padding-top: 20px;
  }

  .brand-essence h3 {
    font-size: 35px;
  }

  .brand-color-box {
    min-width: 688px;
  }
}

@media screen and (max-width: 480px) {
  .pc, .mobile {
    display: none;
  }

  .mini {
    display: inline-block;
  }

  .section-box {
    padding: 40px 55px;
  }

  .section-box:first-child {
    padding-top: 10px;
  }

  .section-box:last-child {
    padding: 40px 90px;
  }

  .section-box > p {
    font-size: 15px;
  }

  .logo_guide {
    content: url("@/assets/about/ci_logo.svg");
    padding-top: 40px;
    min-width: 360px;
  }

  .brand-essence {
    width: 283px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .be-box {
    width: 401px;
  }

  .be-box p {
    font-size: 15px;
  }

  .essence-first {
    content: url("@/assets/about/mobile_customer_value.svg");
    width: 360px;
  }

  .essence-second {
    content: url("@/assets/about/mobile_company_value.svg");
    width: 360px;
  }

  .color-box:first-child {
    margin-bottom: 40px;
  }

  .box-wrap {
    width: 170px;
  }

  .brand-color-box {
    min-width: 300px;
  }

  .mini-box {
    width: 55px;
    height: 25px;
    font-size: 14px;
  }

  .color-info-box {
    font-size: 14px;
    padding-bottom: 10px;
  }

  .blue-box {
    width: 110px;
    height: 110px;
  }

  .color h3 {
    font-size: 20px;
  }

  .box-wrap.deep {
    width: 170px;
  }
}
</style>

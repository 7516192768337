<template>
  <div v-if="isPc" class="menu-wrapper ds-flex center-between" @mouseenter="openMenu()" @mouseleave="closeMenu()" :style=menuStyle>
    <div class="menu-box ds-flex center-center">
      <div class="main-menu-box">
        <div class="main-logo-box ds-flex center-center" @click="setRouter('Home')">
          <img src="@/assets/main/2_White.png">
        </div>
        <!-- main menu -->
        <div class="menu-list ds-flex start-between">
          <div v-for="(menu, i) in menuList" :key="i" class="main-menu" @click="setActive(menu)">
            <div class="main ds-flex center-start" @click="setRouter(menu.sub[0].name)">
              <div v-if="menu.active" class="active"></div>
              {{ menu.main }}
            </div>
              
            <div 
              v-for="subMenu in menuList[i].sub" 
              :key="subMenu" 
              :class="[subMenu.style === 'bold' ? 'bold':'', subMenu.style === 'indent' ? 'indent':'']"
              class="sub"
              @click="setRouter(subMenu.name, subMenu.url)"
            >
                {{ subMenu.menu }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="menu-wrapper ds-flex center-center" :style="tabStyle">
    <div class="menu-box ds-flex center-center" :style="headerStyle">
      <img :src="menuBtn" class="menu-btn" @click="openTab(!tabIsOpen)">
      <div class="header-logo-box ds-flex center-center">
        <img src="@/assets/admin/logo.svg"  @click="setRouter('Home')">
      </div>
    </div>
    <div class="menu-list-wrapper">
      <div v-for="(menu, i) in menuList" :key="menu" class="menu-list-box" @click="openSubMenu(menu, i)">
        <div class="main-menu-box">
          {{ menu.main }}
          <svg class="arrow" width="15" height="9" viewBox="0 0 15 9" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.0006 1.8577C15.0006 2.06371 14.927 2.26972 14.7432 2.44139L8.34616 8.72466C8.34616 8.72466 7.94175 8.99933 7.6844 8.99933C7.42704 8.99933 7.20646 8.89633 7.02263 8.75899L0.257927 2.47573C-0.072955 2.13238 -0.072955 1.58303 0.257927 1.23968C0.58881 0.89633 1.21381 0.930665 1.58146 1.23968L7.6844 6.90491L13.4197 1.27401C13.7873 0.930665 14.3388 0.930665 14.7065 1.27401C14.8903 1.44569 15.0006 1.68603 15.0006 1.89204V1.8577Z" />
          </svg>
        </div>
        <div class="mobile-sub-menu-box">
          <div v-for="sub in menu.sub" :key="sub" @click="setRouter(sub.name, sub.url)" :class="{'bold' : sub.style==='bold'}">
            {{ sub.menu }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {API} from "../api.js";

export default {
  name: 'Menu',
  components: {
    
  },
  props: [],
  data: () => ({
    isPc: true,
    tabStyle: {},
    headerStyle: {},
    tabIsOpen: false,
    menuBtn: require("@/assets/admin/menu_btn.svg"),
    menuStyle: {},
    menuList: [
      {
        main: 'ABOUT',
        sub: [
          { 
            menu: 'CEO 인사말', 
            name: 'Introduction'
          },
          { 
            menu: '경영진 소개', 
            name: 'Leadership'
          },
          { 
            menu: '연혁', 
            name: 'History'
          },
          { 
            menu: '기술 / 특허', 
            name: 'TechAndPatent'
          },
          { 
            menu: '파트너 / 구축사례', 
            name: 'Partner'
          },
          { 
            menu: 'Publications', 
            name: 'Publications'
          },
          {
            menu: 'CI', 
            name: 'CorpIdentity'
          },
        ],
        active: false, // pc
        isOpen: false, // mobile
      },
      {
        main: 'PRODUCT',
        sub: [
          { 
            menu: 'Social Safety AI', 
            name: 'ZioSummary',
            style: 'bold',
            class: 'social'
          },
          { 
            menu: '· Zio-Summary', 
            name: 'ZioSummary',
            style: 'indent',
            class: 'social'
          },
          { 
            menu: '· ZioVIS', 
            name: 'ZioVIS',
            style: 'indent',
            class: 'social'
          },
          { 
            menu: '· ZioKeeper',
            name: 'ZioKeeper',
            style: 'indent',
            class: 'social'
          },
          { 
            menu: '· ZioEdge', 
            name: 'ZioEdge',
            style: 'indent',
            class: 'social'
          },
          { 
            menu: 'Medical AI', 
            name: 'ZioMed',
            style: 'bold',
            class: 'medical'
          },
          { 
            menu: '· ZioMed', 
            name: 'ZioMed',
            style: 'indent',
            class: 'medical'
          },
          { 
            menu: '· AI CDSS', 
            name: 'AiCDSS',
            style: 'indent',
            class: 'medical'
          },
          { 
            menu: '· Smart Hospital', 
            name: 'SmartHospital',
            style: 'indent',
            class: 'medical'
          },
        ],
        active: false, // pc
        isOpen: false, // mobile
      },
      {
        main: 'NEWS',
        sub: [
          { 
            menu: 'Newsroom', 
            name: 'Newsroom'
          },
          { 
            menu: 'Notice', 
            name: 'IR'
          },
        ],
        active: false, // pc
        isOpen: false, // mobile
      },
      {
        main: 'CONTACT',
        sub: [
          { 
            menu: '제품 문의', 
            name: 'Inquiry'
          },
          { 
            menu: '오시는 길', 
            name: 'Location'
          },
        ],
        active: false, // pc
        isOpen: false, // mobile
      },
      {
        main: 'EXTERNAL',
        sub: [
          { 
            menu: 'CV&MIP', 
            name: '',
            url: 'https://visual.kangwon.ac.kr/'
          },
          { 
            menu: 'YOUTUBE', 
            name: '',
            url: 'https://www.youtube.com/channel/UC43_zIRDyV1QURSK2klLdtQ'
          },
        ],
        active: false, // pc
        isOpen: false, // mobile
      },
    ],
    currMenu: ''
  }),
  methods: {
    /* 모바일 */
    openTab(isOpen) {
      this.tabIsOpen = isOpen;
      if(isOpen) {
        this.tabStyle = {'height': '100%'};
        this.headerStyle = {'background-color': 'rgba(25, 30, 44, 1)'}
        this.menuBtn = require("@/assets/admin/X_btn.svg");
      } else {
        this.tabStyle = {'height': '50px'};
        this.menuBtn = require("@/assets/admin/menu_btn.svg");
        this.headerStyle = {'background-color': 'rgba(25, 30, 44, 0.95)'}
      }
    },
    openSubMenu(target, idx) {
      let menu = this.menuList;
      let menuBox = document.getElementsByClassName('menu-list-box');

      if(!target.isOpen) {
        menuBox[idx].classList.add('active');
        menu[idx].isOpen = true;

        for(let i=0; i<menuBox.length; i++) {
          if(i !== idx) {
            menuBox[i].classList.remove('active');
            menu[i].isOpen = false;
          }
        }
      } else {
        menuBox[idx].classList.remove('active');
        menu[idx].isOpen = false;
      }
    },
    /* 모바일 */
    setActive(menu) {
      if(menu.main !== "EXTERNAL") {
        for(let i=0; i<this.menuList.length; i++) {
          this.menuList[i].active = false;
        }
        menu.active=true;
      }
    },
    openMenu() {
      let width = window.innerWidth;
      if(width > 1240) {
        this.menuStyle = {height: '490px'};
      } else {
        this.menuStyle = {height: '350px'};
      }
    },
    closeMenu() {
      let width = window.innerWidth;
      if(width > 1240) {
        this.menuStyle = {height: '70px'};
      } else {
        this.menuStyle = {height: '40px'};
      }
    },
    setRouter(name, url) {
      if(url !== undefined) {
        window.open(url);
      } else {
        this.$router.push({name: name});
        window.scrollTo(0,0);
      }
      this.closeMenu();
      this.openTab(false);
    },
    handleResize() {
      let width = window.innerWidth;
      if(width > 768) {
        if(width > 1240) {
          this.menuStyle = {height: '70px'};
        } else {
          this.menuStyle = {height: '40px'};
        }
        this.isPc = true;
      } else {
        this.isPc = false;
      }
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    // '$route' (to, from) {
    '$route' (to) {
      // console.log(from);
      this.currMenu = to.matched[0].path;

      for(let i=0; i<this.menuList.length; i++) {
        if(this.currMenu.includes(this.menuList[i].main.toLowerCase())) {
          this.menuList[i].active = true;
        } else {
          this.menuList[i].active = false;
        }
      }
    }
  }
}
</script>

<style>
a {color: #fff; text-decoration: none; outline: none}
a:hover, a:active {text-decoration: none; color:#00ACEB;}

.menu-wrapper {
  width: 100%;
  height: 70px;
  /* height: 50vh; */
  background-color: rgba(25, 30, 44, 0.95);
  position: relative;
  transition: .5s;
  overflow: hidden;
}

.menu-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.main-menu-box {
  width: 1240px;
  display: flex;
  justify-content: space-between;
}

.main-logo-box {
  height: 70px;
}

.main-logo-box > img {
  width: 40px;
  height: 35px;
  box-sizing: border-box;
  cursor: pointer;
}

.main-menu:first-child {
  width: 170px;
  margin-right: 20px;
}

.main-menu:nth-child(2) {
  width: 194px;
  margin-right: 20px;
}

.main-menu:nth-child(3) {
  width: 160px;
  margin-right: 20px;
}

.main-menu:nth-child(4) {
  width: 180px;
  margin-right: 20px;
}

.main-menu:nth-child(5) {
  width: 120px;
}

.main-menu .main {
  height: 70px;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0;
}

.main-menu .sub {
  cursor: pointer;
  font-size: 16px;
  padding: 7px 0;
  font-weight: 400;
  letter-spacing: 0;
  color: #9EA5B6;
}

.sub:hover {
  color: #00ACEB;
}

.menu-list {
  color: #fff;
  font-size: 20px;
}

.menu-list .active {
  width: 8px;
  height: 8px;
  background-color:#00ACEB;
  position: absolute;
  top: 20px;
  left: -15px;
}

.main-menu {
  /* width: 25%; */
  text-align: left;
  font-weight: bold;
  position: relative;
  transition: .3s;
}

.main-menu:hover {
  /* text-decoration: underline; */
}

.sub-menu-box {
  width: 100%;
  height: 372px;
}

.sub-menu-list {
  width: 45%;
}

.sub-menu {
  width: 25%;
  text-align: left;
  padding-right: 50px;
  box-sizing: border-box;
}

.sub-menu div {
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
  cursor: pointer;
}

.sub-menu div:hover {
  color: #00ACEB;
}

.main-menu .sub.bold {
  color: #FFFFFF;
  font-weight: 500;
  text-decoration: underline;
  /* pointer-events: none; */
}

.main-menu .sub.indent {
  /* padding-left: 20px; */
}

.close-btn {
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  height: 54px;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  cursor: pointer;
  color: #FFFFFF;
}

@media screen and (max-width: 1240px) {
  .main-menu-box {
    width: 768px;
  }

  .main-menu .main {
    height: 40px;
    font-size: 14px; 
  }

  .main-menu:first-child {
    width: 110px;
  }

  .main-menu:nth-child(2) {
    width: 135px;
  }

  .main-menu:nth-child(3) {
    width: 115px;
  }

  .main-menu:nth-child(4) {
    width: 120px;
  }

  .main-menu:nth-child(5) {
    width: 70px;
  }

  .main-logo-box {
    height: 40px;
  }

  .menu-wrapper {
    height: 40px;
  }

  .main-logo-box > img {
    width: 30px ;
    height: 25px;
  }

  .main-menu .sub {
    font-size: 12px;
    line-height: 14px;
    padding: 10px 0;
  }

  .menu-list .active {
    width: 4px;
    height: 4px;
    top: 11px;
    left: -7px;
  }
}

@media screen and (max-width: 768px) {
  .menu-wrapper {
    height: 50px;
    /* height: 100%; */
    background-color: transparent;
  }

  .menu-box {
    height: 50px;
    background-color: rgba(25, 30, 44, 0.95);
    position: relative;
    transition: .5s;
  }

  .menu-box > img {
    position: absolute;
    left: 20px;
    cursor: pointer;
    width: 18px;
    height: 18px;
  }

  .header-logo-box img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .menu-list-wrapper {
    background-color: rgba(25, 30, 44, 0.9);
    width: 100%;
    height: 100%;
    transition: .5s;
    border-top: 1px solid #979CA8;
  }

  .menu-list-box {
    height: 50px;
    transition: .5s;
    overflow: hidden;
  }

  .menu-list-box.active {
    border-bottom: 1px solid #979CA8;
  }

  .menu-list-box.active:first-child {
    height: 350px;
  }

  .menu-list-box.active:nth-child(2) {
    height: 500px;
  }

  .menu-list-box.active:nth-child(3) {
    height: 150px;
  }

  .menu-list-box.active:nth-child(4) {
    height: 150px;
  }

  .menu-list-box.active:nth-child(5) {
    height: 150px;
  }

  .menu-list-box.active > div {
    color: #00ACEB;
    background-color: #2D3243;
    border-top: 1px solid #979CA8;
    border-bottom: 1px solid #979CA8;
    transition: .5s;
  }

  .menu-list-box.active svg {
    fill: #00ACEB;
    transform: rotate(180deg);
  }

  .main-menu-box {
    width: 100%;
    height: 50px;
    background-color: #191E2C;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    padding: 0 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid #3A445B;
  }

  .menu-list-box:last-child {
    border-bottom: 1px solid #979CA8;
  }

  .mobile-sub-menu-box > div {
    height: 50px;
    font-size: 16px;
    color: #9EA5B6;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding: 0 70px;
    background-color: #191E2C;
    cursor: pointer;
  }

  .mobile-sub-menu-box .bold {
    color: #fff !important;
    text-decoration: underline;
  }
}

@media screen and (max-width: 480px) {
  .main-menu-box {
    padding: 0 60px;
  }

  /* .menu-box > img {
    width: 23px;
    height: 19px;
  } */
}
</style>

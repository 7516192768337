<template>
    <div class="ziokeeper-wrapper">
      <div class="section-box">
        <div>
          <img class="top-logo" src="@/assets/product/social/ziokeeper/ziokeeper_logo.svg">
          <div class="info-wrapper info-text">
            <p class="ds-flex center-center">지오지킴이(ZioKeeper)는 <span class="bold">AI를 통해</span><span class="bold blue">아동학대 의심 상황을 검출하는 시스템</span> 입니다.</p>
            <p class="ds-flex center-center">아동학대 상황을 사용자가 쉽고 편하게 확인할 수 있으며 다양한 영상 저장 장치들과 호환되는 플레이어로 개발되었습니다.</p>
          </div>
          <div class="ds-flex center-between main-box">
            <img class="main-img" src="@/assets/product/social/ziokeeper/ziokeeper2.png">
            <div class="ds-flex product-info-wrapper">
              <div class="blue-title-box light">
                <p>지오지킴이(ZioKeeper)</p>
              </div>
              <div class="product-info-box ds-flex">
                <div>
                  <p>· 구축된 데이터셋을 통한 분류, 인식, 검출 모델 개발 완료</p>
                  <p>- 어른/영유아 분류, 감정 인식, 행동 인식, 객체 검출, 객체 재식별</p>
                  <p>· 관심 객체를 선택하여 관심 객체에 대한 요약, 검색, 마스킹 기능을 사용할 수 있는 기능 개발</p>
                </div>
                <div>
                  <p class="bold-text">· 폴리스랩 2.0 사업 선정</p>
                  <p class="bold-text">- 유치원, 어린이집 도입을 목표로 경찰청과 공동 개발</p>
                </div>
              </div>
            </div>
          </div>
          <img src="@/assets/product/social/ziokeeper/report.png">
        </div>
      </div>
      <div class="section-box">
        <div>
          <img src="@/assets/product/social/ziokeeper/system.png">
        </div>
      </div>
      <div class="section-box">
        <div>
          <div class="ds-flex center-start chart-box">
            <div class="blue-title-box chart">
              <p>개발 결과 예상도</p>
            </div>
            <p class="ds-flex center-center" style="atuo"><span class="bold blue">· 안면인식, 감정분석, 행동인식</span>기술을 이용하여 개발된 지오지킴이는 학대 의심 상황을 검출하며, 경찰청에 도입을 1차 목표로 계획하고 있습니다.</p>
          </div>
          <img src="@/assets/product/social/ziokeeper/chart.png">
        </div>
      </div>
      <div class="section-box">
        <div>
          <iframe width="800" height="450" src="https://www.youtube.com/embed/HLppZ3w1m4U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'Ziokeeper',
  components: {
    
  },
  data: () => ({
  }),
  methods: {
    movePage(name) {
      this.$router.push({name: name});
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.ziokeeper-wrapper {
  color: #191E2C;
  border-top: 1.5px solid #00ACEB;
}

.section-box {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-box:nth-child(2n) {
  background-color: #F0F0FD;
}

.section-box > div {
  width: 1240px;
}

.section-box p {
  color: #505050;
  font-size: 16px;
  font-weight: 500;
}

.section-box .info-text p {
  font-size: 18px;
  word-break: keep-all;
}

.info-wrapper {
  padding: 40px 0 80px 0;
  width: 100%;
}

.section-box .bold {
  font-weight: bold;
  color: #000;
  padding-left: 5px;
}

.section-box .blue {
  color: #00ACEB;
}

.title-box {
  padding-bottom: 40px;
}

.title-box h3 {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
  padding-left: 10px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #00ACEB;
}

.main-text {
  font-size: 40px;
  color: #071C34;
  font-weight: bold;
}

.blue-wrapper {
  height: 150px;
  align-content: space-between;
}

.blue-box {
  width: 590px;
  height: 60px;  
  background-color: #444EDC;
  border-radius: 60px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.blue-title-box {
  padding: 13px 31px 14px 32px;
  border-radius: 24px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 285px;
  background-color: #444EDC;
}

.blue-title-box > p {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.blue-title-box.chart {
  margin-right: 30px;
}

.product-info-box {
  width: 100%;
  height: 210px;
  align-content: space-between;
}

.product-info-box div {
  width: 100%;
}

.product-info-box p {
  text-align: left;
}

.product-info-wrapper {
  width: 575px;
  height: 290px;
  align-content: space-between;
}

.main-box .main-img {
  box-shadow: 7px 11px 18px #383B4087;
}

.section-box .bold-text {
  color: #111111;
  font-size: 18px;
}

.bold-text:first-child {
  font-weight: 800;
}

.main-box {
  margin-bottom: 80px;
}

.chart-box {
  margin-bottom: 30px;
}

@media screen and (max-width: 1240px) {
  .section-box {
    padding: 40px 0;
  }

  .section-box > div {
    width: 768px;
    margin: auto;
  }

  .top-logo {
    width: 180px;    
  }

  .title-box h3 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 5px;
    left: 2px;
  }

  .title-box {
    position: relative;
    padding-bottom: 20px;
  }

  .main-text {
    font-size: 30px;
  }

  img {
    width: 430px;
  }

  .blue-wrapper {
    height: 100px;
  }

  .blue-box {
    width: 375px;
    height: 40px;
    font-size: 13px;
  }

  iframe {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ziokeeper-wrapper {
    border-top: none;
  }

  .section-box > div {
    padding: 0 40px;
  }

  .section-box:first-child {
    padding-top: 10px;
  }
  
  .section-box p {
    padding: 30px 0;
  }

  .top-logo {
    width: 138px;
    height: 37px;
  }

  img {
    width: 335px;
    height: 286px;
  }

  .blue-box {
    width: 100%;
  }

  .blue-wrapper {
    height: 220px;
  }
}

@media screen and (max-width: 480px) {
  .top-logo {
    width: 200px;
    height: 50px;
  }

  .section-box:first-child {
    padding: 10px 70px 40px 70px;
  }

  .section-box p {
    padding: 0;
  }

  .section-box .info-text p {
    font-size: 12px;
    width: 359px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .title-box h3 {
    font-size: 20px;
  }

  .mini-blue-box {
    width: 6px;
    height: 6px;
    top: 0;
  }

  .blue-wrapper {
    min-width: 360px;
    height: 205px;
  }

  .blue-box {
    font-size: 13px;
  }

  .section-box > div {
    padding: 0 60px;
    min-width: 360px;
  }

  .main-img {
    margin-bottom: 60px;
  }

  .blue-title-box.chart {
    margin-bottom: 30px;
  }

  .section-box .chart-box {
    margin-bottom: 30px;
  }

  .chart-box p {
    text-align: left;
    justify-content: flex-start;
  }

  .product-info-wrapper {
    height: 350px;
  }

  .product-info-box {
    height: 270px;
  }

  .info-wrapper {
    padding: 30px 0 40px 0;
  }
}
</style>

<template>
    <div class="login-wrapper">
        <img class="bg" src="@/assets/login/bg.png">
        <div class="login-box">
            <img class="logo" src="@/assets/login/logo.svg" @click="movePage('Home')">
            <div class="input-wrapper ds-flex center-between">
                <div class="input-box select" :style="comboBox.style">
                    <div 
                        v-for="(option, i) in comboBox.option" 
                        :key="option" 
                        class="option ds-flex center-between" 
                        @click="setComBox(option)"
                    >
                        {{ option }}
                        <svg id="arrow" v-if="i===0" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.0006 1.8577C15.0006 2.06371 14.927 2.26972 14.7432 2.44139L8.34616 8.72466C8.34616 8.72466 7.94175 8.99933 7.6844 8.99933C7.42704 8.99933 7.20646 8.89633 7.02263 8.75899L0.257927 2.47573C-0.072955 2.13238 -0.072955 1.58303 0.257927 1.23968C0.58881 0.89633 1.21381 0.930665 1.58146 1.23968L7.6844 6.90491L13.4197 1.27401C13.7873 0.930665 14.3388 0.930665 14.7065 1.27401C14.8903 1.44569 15.0006 1.68603 15.0006 1.89204V1.8577Z" :fill=comboBox.fill />
                        </svg>
                    </div>
                </div>
                <input type="text" v-model="user.id" placeholder="ID" class="input-box input" :class="{'filled' : user.id.length>0}" @keyup.enter="loginReq()" @input="hideErrorMsg()" >
                <input type="password" v-model="user.pw" placeholder="PW" class="input-box input" :class="{'filled' : user.pw.length>0}" @keyup.enter="loginReq()" @input="hideErrorMsg()" >
                <div class="error-box">
                    <p v-if="showMsg.unapp" class="ds-flex center-start">* 승인되지 않은 계정입니다. 관리자에게 문의하세요.</p>
                    <!-- <p class="ds-flex center-start">* 승인되지 않은 계정입니다. 관리자에게 문의하세요.</p> -->
                    <p v-if="showMsg.inacc" class="ds-flex center-start">* 아이디 혹은 비밀번호가 일치하지 않습니다.</p>
                </div>
            </div>
            <div class="input-box blue-btn ds-flex center-center" @click="loginReq()">로그인</div>
            <div class="join-box ds-flex center-between">
                <p class="gray">아직 회원이 아니신가요?</p>
                <p class="blue" @click="movePage('Join')">회원가입</p>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from "axios"
import {API} from "../api.js";

export default {
  name: 'LoginView',
  components: {
    
  },
  data: () => ({
    comboBox:{ 
        isopen: false,
        style: {},
        origin: ['홈페이지 관리', '계정 관리', '영업 관리'], // 기존 순서
        option: [], // 사용자가 선택한 옵션에 따라 순서가 바뀜
        selected: '', // 사용자가 선택한 옵션
        fill: '#ffffff', // 화살표 색상
    },
    user: {
        id: '',
        pw: ''
    },
    // loginRes: {}, // 로그인 요청 결과
    showMsg: {
        inacc: false, // 아이디 또는 비밀번호 불일치
        unapp: false, // 미승인
    },
  }),
  methods: {
    async loginReq() {
        let user = this.user;
        let singleton = this;

        // let user = this.user;
        // this.loginRes = {};
        
        // API.loginReq(user, function(status, res){
        //     if(res.data.result !== undefined) {
        //         if(res.data.result === "Incorrect username or password") {
        //             singleton.showMsg.inacc = true;
        //         } else if(res.data.result === "Not Approve User") {
        //             singleton.showMsg.unapp = true;
        //         }
        //     } else { 
        //         sessionStorage.setItem('access_token', res.data['access_token']);
        //         singleton.getUserInfo();
        //     }
        // })

        API.login(user, function(status, res){
            let result = res.data;
            if(result) {
                sessionStorage.setItem('access_token', 'success');
                singleton.movePage('Popup');
            } else {
                singleton.showMsg.inacc = true;
            }
        })
    },
    async getUserInfo() {
        let singleton = this;
        let token = sessionStorage.getItem('access_token');
        API.getUserInfo(token, function(status, res){
            sessionStorage.setItem('user_info', JSON.stringify(res.data));
            singleton.movePage('Popup');
        })
    },
    setOption() {
        let comb = this.comboBox;
        comb.option = [];
        
        if(comb.selected === '') {
            for(let i=0; i<comb.origin.length; i++) {
                comb.option.push(comb.origin[i]);
            }
        } else {
            comb.option.push(comb.selected);
            for(let i=0; i<comb.origin.length; i++) {
                if(comb.origin[i] !== comb.selected) {
                    comb.option.push(comb.origin[i]);
                }
            }
        }
    },
    setComBox(selected) {
        let comb = this.comboBox;
        let select = document.getElementsByClassName('select')[0];
        let option = document.getElementsByClassName('option');
        let arrow = document.getElementById('arrow');
        
        if(comb.isopen) {
            for(let i=0; i<option.length; i++) {
                option[i].classList.remove('hover', 'active');
            }
            select.classList.remove('active');
            comb.style = {'background-color': 'transparent'};
            comb.fill = '#ffffff';
            comb.selected = selected;
            arrow.style.transform = `rotate(360deg)`
            this.setOption();
        } else {
            for(let i=0; i<option.length; i++) {
                if(i===0) { option[i].classList.add('hover', 'active'); } // comboBox가 열려있을 경우에만 hover css 동작하도록
                else { option[i].classList.add('hover'); }
            }
            select.classList.add('active');
            comb.style = {'background-color': '#14141C', 'border-color': '#00ACEB'};
            comb.fill = '#00ACEB';
            arrow.style.transform = `rotate(180deg)`
        }
        
        comb.isopen = !comb.isopen;
    },
    hideErrorMsg() {
        this.showMsg.inacc = false;
        this.showMsg.unapp = false;
    },
    movePage(name) {
      this.$router.push({name: name});
    },
  },
  mounted() {
    window.scrollTo(0,0);
    this.setOption();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
  },
  watch: {

  }
}
</script>

<style scoped>
.login-wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.login-wrapper .bg {
    width: 100%;
    height: 100vh;
}

.login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
}

.input-wrapper {
    height: 170px;
    align-content: flex-end;
    margin: 70px 0 20px 0;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.input-box {
    width: 300px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    color: #fff;
    transition: .3s;
}

.input-box.select {
    border: 1px solid #fff;
    overflow: hidden;
    position: absolute;
    top: 0px;
    height: 40px;
}

.input-box.select.active {
    height: 120px;
}

.input-box.select:hover {
    border-color: #00ACEB;
}

.input-box.select:hover .option:first-child  {
    color: #00ACEB;
}

.input-box.select:hover path {
    fill: #00ACEB;
    transition: .3s;
}

.input-box.select .option {
    height: 40px;
    text-align: left;
    box-sizing: border-box;
    padding: 0 15px 2px 15px;
    color: #ffffff;
    transition: .3s;
    font-size: 15px;
}

.input-box.select .option.active {
    color: #00ACEB;
}

.input-box.select .option.hover:hover {
    background-color: #1B1B25;
}

.blue-btn {
    background-color: #00ACEB;
    align-content: center;
    cursor: pointer;
    transition: .3s;
    font-size: 15px;
}

.blue-btn:hover {
    background-color: #0088BB;
}

.blue-btn.error {
    background-color: #FF3E3E;
}

.input-box.input {
    padding: 11px 15px;
    border: 1px solid #707070;
    background-color: transparent;
    font-size: 15px;
}

.input-box.input:hover {
    border-color: #00ACEB;
}

.input-box.input:focus {
    border-color: #00ACEB !important;
}

.input-box.input.error {
    border-color: #FF3E3E !important;
}

.input-box.input.filled {
    border-color: #ffffff;
}

.input-box.input:nth-child(3) {
    margin-top: 15px;
}

.join-box {
    padding-top: 5px;
}

.join-box p {
    font-size: 12px;
}

.join-box p.gray {
    color: #B0B0B0;
}

.join-box p.blue {
    color: #00ACEB;
    cursor: pointer;
}

.error-box {
    width: 300px;
    height: 20px;
}

.error-box > p {
    font-size: 11px;
    color: #FF3E3E;
    cursor: default;
}

.logo {
    cursor: pointer;
}

@media screen and (min-width: 1201px) {
    .login-wrapper .bg {
        min-width: 1920px;
        min-height: 1080px;
    }
}

@media screen and (max-width: 1200px) {
    .login-wrapper .bg {
        min-width: 1200px;
        min-height: 675px;
    }

    .input-wrapper {
        height: 130px;
        margin: 45px 0 10px 0;
    }

    .login-box {
        width: 250px
    }

    .logo {
        width: 160px;
    }

    .input-box {
        width: 250px;
        height: 30px;
        border-radius: 4px;
    }

    .input-box.input {
        font-size: 11px;
        padding: 5px 10px;
    }

    .input-box.select {
        height: 30px;
    }

    .input-box.select.active {
        height: 90px;
    }

    .input-box.select .option {
        height: 30px;
        font-size: 11px;
        padding: 0 10px;
    }

    #arrow {
        width: 12px;
    }

    .input-box.input:nth-child(3) {
        margin-top: 10px;
    }

    .error-box > p {
        font-size: 10px;
    }
    
    .join-box p {
        font-size: 9px;
    }

    .blue-btn {
        font-size: 11px;
    }
}

@media screen and (max-width: 768px) {
    .logo {
        width: 230px;
        height: 107px;
    }

    .login-box {
        width: 360px;
    }

    .input-box {
        width: 360px;
        height: 50px;
        font-size: 16px;
    }

    .input-wrapper {
        height: 200px;
    }

    .input-box.input {
        font-size: 16px;
        padding: 0 15px;
    }

    .input-box.select.active {
        height: 150px;
    }

    .input-box.select {
        height: 50px;
    }

    .input-box.select .option {
        height: 50px;
        padding: 0 15px;
        font-size: 16px;
    }

    #arrow {
        width: 18px;
        height: 9px;
    }

    .input-box.input:nth-child(3) {
        margin-top: 15px;
    }

    .error-box > p, .join-box p {
        font-size: 13px;
    }

    .input-wrapper {
        margin: 40px 0 20px 0;
    }

}
</style>

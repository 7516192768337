<template>
    <div class="about-wrapper route-page">
      <div class="header-box">
        <img class="header-img pc" src="@/assets/about/banner_ABOUT.jpg">
        <img class="header-img tablet" src="@/assets/about/01_about_banner.png">
        <img class="header-img mobile" src="@/assets/about/01_about_banner_mobile.png">
        <div class="ds-flex center-center">
          <h1>ABOUT</h1>
          <p>{{ currTab.title }}</p>
        </div>
      </div>
      <!-- <div class="blue-box">
        <div class="large-blue-box ds-flex center-center">
          <div class="mini-blue-box"></div>
          <p>{{ currTab.info[0] }}</p>
          <h1>{{ currTab.info[1] }}</h1>
          <h3>{{ currTab.info[2] }}</h3>
        </div>
      </div> -->
      <div class="menu-tab-box pc ds-flex center-center">
        <div
          v-for="tab in tabList" :key="tab" 
          :class="[currTab.name===tab.name ? 'active':'']" 
          @click="setCurrTab(tab)"
        >
          {{tab.title}}
        </div>
      </div>

      <div class="menu-combo-wrapper mobile">
        <div class="menu-combo-box" :style="comboBox.style">
          <div class="option" v-for="option in comboBox.option" :key="option" @click="setComBox(option)">{{ option.title }}</div>
          <svg id="arrow" width="16" height="9" viewBox="0 0 16 9" fill="#1E1E1E" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.0006 1.8577C15.0006 2.06371 14.927 2.26972 14.7432 2.44139L8.34616 8.72466C8.34616 8.72466 7.94175 8.99933 7.6844 8.99933C7.42704 8.99933 7.20646 8.89633 7.02263 8.75899L0.257927 2.47573C-0.072955 2.13238 -0.072955 1.58303 0.257927 1.23968C0.58881 0.89633 1.21381 0.930665 1.58146 1.23968L7.6844 6.90491L13.4197 1.27401C13.7873 0.930665 14.3388 0.930665 14.7065 1.27401C14.8903 1.44569 15.0006 1.68603 15.0006 1.89204V1.8577Z" :fill="comboBox.fill"/>
          </svg>
        </div>
      </div>

      <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
export default {
  name: 'AboutView',
  components: {
    
  },
  data: () => ({
    tabList:[
      { 
        name: "Introduction",
        title: "CEO 인사말",
      },
      { 
        name: "Leadership",
        title: "경영진 소개",
      },
      { 
        name: "History",
        title: "연혁",
      },
      { 
        name: "TechAndPatent",
        title: "기술 / 특허",
      },
      { 
        name: "Partner",
        title: "파트너 / 구축사례",
      },
      { 
        name: 'Publications', 
        title: 'Publications'
      },
      { 
        name: "CorpIdentity",
        title: "CI",
      },
    ],
    currTab: {},
    comboBox:{ 
        isopen: false,
        style: {},
        option: [], // 사용자가 선택한 옵션에 따라 순서가 바뀜
        selected: '', // 사용자가 선택한 옵션
        fill: '#1E1E1E', // 화살표 색상
    },
  }),
  methods: {
    setCurrTab(tab) {
      this.$router.push({name: tab.name});
      this.currTab = tab;
    },
    setComBox(selected) {
      let comb = this.comboBox;
      let select = document.getElementsByClassName('menu-combo-box')[0];
      let option = document.getElementsByClassName('option');
      let arrow = document.getElementById('arrow');
      
      if(comb.isopen) {
          for(let i=0; i<option.length; i++) {
              option[i].classList.remove('hover', 'active');
          }
          select.classList.remove('active');
          comb.style = {};
          comb.fill = '#1E1E1E';
          comb.selected = selected;
          arrow.style.transform = `rotate(360deg)`;
          this.setCurrTab(selected);
          this.setOption();
      } else {
          for(let i=0; i<option.length; i++) {
              if(i===0) { option[i].classList.add('hover', 'active'); } // comboBox가 열려있을 경우에만 hover css 동작하도록
              else { option[i].classList.add('hover'); }
          }
          select.classList.add('active');
          comb.style = {'border-color': '#00ACEB'};
          comb.fill = '#00ACEB';
          arrow.style.transform = `rotate(180deg)`
      }
      comb.isopen = !comb.isopen;
    },
    setOption() {
        let comb = this.comboBox;
        let list = this.tabList;

        comb.option = [];
        comb.option.push(comb.selected);
        for(let i=0; i<list.length; i++) {
            if(list[i].title !== comb.selected.title) {
                comb.option.push(list[i]);
            }
        }
    },
  },
  mounted() {
    window.scrollTo(0,0);
    // 새로고침 시 현재 페이지가 유지되도록 함
    for(let i=0; i<this.tabList.length; i++){
      if(this.$route.name === this.tabList[i].name) {
        this.currTab = this.tabList[i];
        this.comboBox.selected = this.tabList[i];
      }
    }

    this.setOption();
  },
  created() {
    this.currTab = this.tabList[0];
  }
}
</script>

<style scoped>
/* @font-face {
  font-family: 'SUIT-Variable', sans-serif !important;
  src: url('@/assets/fonts/SUIT-Variable.ttf') format('truetype') !important;
} */

.about-wrapper {
}

.header-box {
  width: 100%;
  height: 320px;
  padding: 150px 0 80px 0;
  box-sizing: border-box;
}

.header-img {
  width: 100%;
  height: 320px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  /* min-width: 1920px; */
}

.header-box > div {
  height: 80px;
  align-content: space-between;
}

.header-box > div > h1 {
  font-size: 35px;
  font-weight: 500;
  color: #fff;
  width: 100%;
}

.header-box > div > p {
  font-size: 15px;
  color: #9EA5B6;
}

.blue-box {
  position: relative;
  height: 310px;
  padding-top: 100px;
}

.mini-blue-box {
  position: absolute;
  top: -40px;
  left: 0;
  width: 83px;
  height: 117px;
  background-color: #00ACEB;
}

.large-blue-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 555px;
  height: 264px;
  background-color: #191E2C;
  padding: 70px 100px;
  box-sizing: border-box;
  text-align: left;
  z-index: -1;
}

.large-blue-box > p {
  color: rgba(255, 255, 255, 0.65);
  font-size: 16px;
  width: 100%;
}

.large-blue-box > h1 {
  color: #fff;
  font-size: 50px;
  font-weight: bold;
  width: 100%;
}

.large-blue-box > h3 {
  color: #fff;
  font-size: 18px;
  width: 100%;
}

.menu-tab-box {
  top: 2px;
  padding-top: 80px;
  position: relative;
}

.menu-tab-box > img {
  position: absolute;
  width: 350px;
  /* right: -100px; */
  right: 0;
}

.menu-tab-box div {
  /* padding: 18px 43px;
  box-sizing: border-box; */
  width: 170px;
  height: 50px;
  color: #9EA5B6;
  font-size: 16px;
  border: 1.5px solid #DBDEE7;
  border-left: none;
  /* border-right: none; */
  border-bottom: 1.5px solid #00ACEB;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
}

.menu-tab-box div:first-child {
  border-left: 1.5px solid #DBDEE7;
}

.menu-tab-box div:last-child {
  border-right: 1.5px solid #DBDEE7;
}

.menu-tab-box div.active {
  color: #00ACEB;
  font-weight: bold;
  border-color: #00ACEB;
  border-left: 1.5px solid #00ACEB;
  border-right: 1.5px solid #00ACEB;
  border-bottom: none;
}

.tablet, .mobile {
  display: none;
}

@media screen and (max-width: 1240px) {
  .header-box {
    height: 200px;
    padding-top: 90px;
  }
  
  .header-img {
    /* min-width: 1240px; */
    height: 200px;
  }

  .header-box > div {
    height: 53px;
  }

  .header-box > div > h1 {
    font-size: 25px;
  }

  .header-box > div > p {
    font-size: 11px;
  }

  .menu-tab-box div {
    width: 150px;
    height: 40px;
    font-size: 13px;
  }
}

@media screen and (max-width: 1080px) {
  .menu-tab-box div {
    width: 100px;
  }
}

@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }

  .tablet {
    display: inline-block;
  }

  .header-img {
    height: 180px;
  }

  .header-box {
    height: 180px;
    padding: 60px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-combo-wrapper {
    display: inline-block;
    width: 100%;
    /* padding: 20px 40px; */
    position: relative;
    height: 90px;
    box-sizing: border-box;
  }

  .menu-combo-box {
    position: relative;
    width: calc(100% - 80px);
    min-width: 360px;
    margin: 25px auto;
    height: 40px;
    border: 1.5px solid #1E1E1E;
    /* position: absolute;
    top: 20px;
    left: 40px; */
    overflow: hidden;
    transition: .5s;
    z-index: 5;
    box-sizing: border-box;
  }
  
  .menu-combo-box.active {
    height: 280px;
  }

  .menu-combo-box.active > .option {
    color: #9EA5B6;
  }
  
  .menu-combo-box svg {
    position: absolute;
    top: 16px;
    right: 20px;
  }

  .option {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #1E1E1E;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DBDEE7;
    cursor: pointer;
    background-color: #fff;
    box-sizing: border-box;
  }

  .menu-combo-box.active > .option.active {
    color: #00ACEB;
  }
}

@media screen and (max-width: 480px) {
  .mobile {
    display: inline-block;
  }

  .menu-combo-wrapper {
    /* padding: 30px 40px; */
    height: 100px;
  }

  .menu-combo-box {
    height: 50px;
    /* top: 30px; */
  }

  .menu-combo-box.active {
    height: 350px;
  }

  .option {
    height: 50px;
    font-size: 16px;
    font-weight: 800;
  }

  .menu-combo-box svg {
    top: 20px;
  }

  .header-box > div > h1 {
    font-size: 27px;
  }

  .header-box > div > p {
    font-size: 12px;
  }
}

</style>

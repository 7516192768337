import { createApp } from 'vue'
import App from './App.vue'
import Vue3Storage from "vue3-storage";
import VueGtag from 'vue-gtag'
import { router } from './router';

const app = createApp(App);

app.use(router);
app.use(Vue3Storage);
app.use(VueGtag, {
    config: {
        id: 'G-ECDGVLSMRY'
    }
}, router);
app.mount('#app');
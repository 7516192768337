<template>
    <div class="smarthospital-wrapper">
      <div class="section-box">
        <div>
          <h1 class="main-text">Smart Hospital</h1>
            <img src="@/assets/product/medical/smarthospital/smart_hospital_info.png">
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'smarthospital',
  components: {
    
  },
  data: () => ({
  }),
  methods: {
    movePage(name) {
      this.$router.push({name: name});
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.smarthospital-wrapper {
  color: #191E2C;
  border-top: 1.5px solid #25B5AD;
}

.section-box {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-box:nth-child(2n) {
  background-color: #D1F0F2;
}

.section-box > div {
  width: 1240px;
}

.section-box p {
  color: #505050;
  font-size: 16px;
  padding: 40px 0;
  font-weight: 500;
}

.section-box .info-text {
  font-size: 18px;
}

.text-wrapper {
  padding: 40px 0;
}

.section-box .bold {
  font-weight: bold;
  color: #000;
  padding-left: 5px;
}

.section-box .blue {
  color: #25B5AD;
}

.title-box {
  padding-bottom: 40px;
}

.title-box h3 {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
  padding-left: 10px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #25B5AD;
}

.main-text {
  font-size: 45px;
  color: #25B5AD;
  font-weight: 800;
  padding-bottom: 80px;
}

.blue-wrapper {
  height: 60px;
  align-content: space-between;
}

.blue-box {
  width: 590px;
  height: 60px;  
  background-color: #25B5AD;
  border-radius: 60px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.section-box > div {
  min-height: 30vh;
}

@media screen and (max-width: 1240px) {
  .section-box {
    padding: 40px 0;
  }

  .section-box > div {
    width: 768px;
    margin: auto;
  }

  .title-box h3 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 5px;
    left: 2px;
  }

  .title-box {
    position: relative;
    padding-bottom: 20px;
  }

  .main-text {
    font-size: 30px;
  }

  img {
    width: 100%;
  }

  .blue-wrapper {
    height: 40px;
  }

  .blue-box {
    width: 375px;
    height: 40px;
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .smarthospital-wrapper {
    border-top: none;
  }

  .section-box > div {
    padding: 0 40px;
  }

  .section-box:first-child {
    padding-top: 10px;
  }
  
  .section-box p {
    padding: 30px 0;
  } 

  .top-logo {
    height: 57px;
  }

  img {
    width: 600px;
    height: 286px;
  }

  .blue-box {
    width: 100%;
  }

  .blue-wrapper {
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .main-text {
    font-size: 30px;
    padding-bottom: 40px;
  }

  .text-wrapper {
    padding: 25px 0 30px 0;  
  }

  img {
    width: 360px;
    /* height: 170px; */
  }

  .title-box h3 {
    font-size: 20px;
  }

  .mini-blue-box {
    width: 6px;
    height: 6px;
    top: 0;
  }

  .blue-wrapper {
    min-width: 360px;
    height: 95px;
  }

  .blue-box {
    font-size: 13px;
  }

  .section-box > div {
    padding: 0 60px;
  }

  .section-box .info-text {
    /* padding: 30px 0 40px 0; */
    font-size: 12px;
  }

  .section-box {
    min-height: 30vh;
  }
}
</style>

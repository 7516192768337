<template>
    <div class="ziosummary-wrapper">
      <div class="section-box">
        <div>
          <img class="top-logo" src="@/assets/product/social/ziosummary/ziosummary-logo.svg">
          <div class="info-wrapper">
            <p class="ds-flex center-center info-text">지오서머리(Zio-Summary)는 <span class="bold">6시간 동안 분석할 영상</span>을 <span class="bold blue">10분만에 분석</span>하여</p>
            <p class="ds-flex center-center info-text">영상 확인 시간과 노력을 획기적으로 단축할 수 있는 <span class="bold blue" style="padding-right: 5px">국내 유일</span> 비디오 요약 기술 AI 솔루션입니다.</p>
          </div>
          <div class="ds-flex center-between main-box">
            <img class="main-img" src="@/assets/product/social/ziosummary/ziosummary.png">
            <div class="ds-flex product-info-wrapper">
              <div class="blue-title-box light video">
                <p>비디오 요약(Zio-Summary)</p>
              </div>
              <div class="product-info-box ds-flex">
                <div>
                  <p>· 장시간 영상을 축약 후 객체와 배경을 분리해서 모니터링을 단축해주는 시스템</p>
                  <p style="padding-left: 10px">(6시간 → 10분)</p>
                  <p>· 비디오를 즉시 활용 가능한 지능형 데이터로 전환</p>
                  <p>· 유연성과 확장성을 기반으로 B2B와 B2C까지 확대 가능</p>
                </div>
                <p>※ 특허 제10-1991043호</p>
              </div>
            </div>
          </div>
          <div class="blue-title-box deep">
            <p>적용분야 및 조건검색</p>
          </div>
          <div class="product-img-box ds-flex center-between">
            <div>
              <img src="@/assets/product/social/ziosummary/business.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/danger.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/establishment.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/home.png">
            </div>
          </div>
          <div class="product-img-box-sec ds-flex">
            <div>
              <img src="@/assets/product/social/ziosummary/interest.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/color.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/direction.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/object.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/face.png">
            </div>
          </div>          
        </div>
      </div>
      <div class="section-box">
        <div>
          <div class="blue-title-box light">
            <p>응용분야</p>
          </div>
          <div class="product-img-box-third ds-flex center-between">
            <div>
              <img src="@/assets/product/social/ziosummary/parking.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/car.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/loitering.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/count.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/summary-service.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziosummary/statistics-service.png">
            </div>
          </div>     
        </div>
      </div>
      <div class="section-box">
        <div>
          <div class="blue-title-box deep certi">
            <p>국내 유일의 비디오 요약 분석 특허 등록</p>
          </div>
          <div class="ds-flex center-start">
            <div class="ds-flex center-between certi-box">
              <img src="@/assets/product/social/ziosummary/software-ziosummary.png">
              <img src="@/assets/product/social/ziosummary/patent_03.png">
              <img src="@/assets/product/social/ziosummary/patent_05.png">
              <img src="@/assets/product/social/ziosummary/patent_06.png">
            </div>
            <div class="certi-text">
              <p>· 특허 제 10-1991043 | 10-2211858 | 10-2252083호</p>
              <p>· 소프트웨어품질인증서 [지오서머리 v1.0] 인증번호 20-0225</p>
              <p>· 국가공인 GS인증(Good Software) 으로 조달 품목 등록</p>
              <p>· 물품식별번호 : 23917974</p>
            </div>
          </div>
        </div>
      </div>
      <img class="inno-img" src="@/assets/product/social/ziosummary/product.png">
      <div class="section-box">
        <div>
          <iframe width="800" height="450" src="https://www.youtube.com/embed/eF42k4pzwHY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
      <!-- <div class="section-box">
        <div>
          <div class="blue-title-box light">
            <p>NEWS</p>
          </div>
          <div class="ds-flex center-between">
            <div class="news-box ds-flex">
              <p>· '국내 유일', 중기부 우수기술 혁신제품 선정 (22년 12월)</p>
              <div class="news-btn more ds-flex center-between" @click="movePage('Newsroom')">
                뉴스 더보기
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 25 25">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="사각형_91" data-name="사각형 91" width="25" height="25" fill="#1e1e1e"/>
                    </clipPath>
                  </defs>
                  <g id="arrow_icon_b" transform="translate(0 0)">
                    <g id="그룹_486" data-name="그룹 486" transform="translate(0 0)" clip-path="url(#clip-path)">
                      <path id="패스_20" data-name="패스 20" d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.516,12.516,0,0,1,12.5,25m0-23.739A11.238,11.238,0,1,0,23.736,12.5,11.253,11.253,0,0,0,12.5,1.261" transform="translate(0 0)" fill="#1e1e1e"/>
                      <path id="패스_21" data-name="패스 21" d="M10.515,2.2l-.883.883,4.056,4.056H1.658V8.386H13.878l-4.233,4.05.86.9,5.7-5.446Z" transform="translate(3.568 4.732)" fill="#1e1e1e"/>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="news-box ds-flex">
              <p>· 우수조달 추진 (23년 7월)</p>
              <div class="news-btn more ds-flex center-between" @click="movePage('Newsroom')">
                뉴스 더보기
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 25 25">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="사각형_91" data-name="사각형 91" width="25" height="25" fill="#1e1e1e"/>
                    </clipPath>
                  </defs>
                  <g id="arrow_icon_b" transform="translate(0 0)">
                    <g id="그룹_486" data-name="그룹 486" transform="translate(0 0)" clip-path="url(#clip-path)">
                      <path id="패스_20" data-name="패스 20" d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.516,12.516,0,0,1,12.5,25m0-23.739A11.238,11.238,0,1,0,23.736,12.5,11.253,11.253,0,0,0,12.5,1.261" transform="translate(0 0)" fill="#1e1e1e"/>
                      <path id="패스_21" data-name="패스 21" d="M10.515,2.2l-.883.883,4.056,4.056H1.658V8.386H13.878l-4.233,4.05.86.9,5.7-5.446Z" transform="translate(3.568 4.732)" fill="#1e1e1e"/>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="news-box ds-flex">
              <p>· 국가수사본부와 대형 사업 기획 (50억)</p>
              <div class="news-btn more ds-flex center-between" @click="movePage('Newsroom')">
                뉴스 더보기
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 25 25">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="사각형_91" data-name="사각형 91" width="25" height="25" fill="#1e1e1e"/>
                    </clipPath>
                  </defs>
                  <g id="arrow_icon_b" transform="translate(0 0)">
                    <g id="그룹_486" data-name="그룹 486" transform="translate(0 0)" clip-path="url(#clip-path)">
                      <path id="패스_20" data-name="패스 20" d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.516,12.516,0,0,1,12.5,25m0-23.739A11.238,11.238,0,1,0,23.736,12.5,11.253,11.253,0,0,0,12.5,1.261" transform="translate(0 0)" fill="#1e1e1e"/>
                      <path id="패스_21" data-name="패스 21" d="M10.515,2.2l-.883.883,4.056,4.056H1.658V8.386H13.878l-4.233,4.05.86.9,5.7-5.446Z" transform="translate(3.568 4.732)" fill="#1e1e1e"/>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
</template>

<script>

export default {
  name: 'ZioSummary',
  components: {
    
  },
  data: () => ({
  }),
  methods: {
    movePage(name) {
      this.$router.push({name: name});
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.ziosummary-wrapper {
  color: #191E2C;
  border-top: 1.5px solid #00ACEB;
}

.section-box {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-box:nth-child(2n) {
  background-color: #F2F6FB;
}

.section-box > div {
  width: 1240px;
}

.section-box p {
  color: #505050;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.section-box .info-text {
  font-size: 18px;
}

.section-box .bold {
  font-weight: bold;
  color: #000;
  padding-left: 5px;
}

.section-box .blue {
  color: #00ACEB;
}

.certi-box {
  width: 660px;
  margin-right: 40px;
}

.certi-info-box {
  padding-top: 50px;
}

.certi-info-box p {
  padding: 0;
  text-align: left;
  color: #505050;
  font-size: 16px;
  font-weight: 500;
}

.certi-info-box > div {
  width: 50%;
}

.certi-info-box > div:first-child {
  padding-left: 80px;
  box-sizing: border-box;
}

.title-box {
  padding-bottom: 40px;
}

.title-box h3 {
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #00ACEB;
}

.ex-box {
  width: 575px;
  height: 855px;
  align-content: space-between;
}

.ex-box img {
  width: 100%;
}

.sole-img {
  width: 500px;
}

.ex-img {
  content: url("@/assets/product/social/ziosummary/ziosummary-ex.svg");
}

.top-logo {
  width: 300px;
  height: 75px;
}

.info-wrapper {
  padding: 40px 0 80px 0;
}

.blue-title-box {
  padding: 13px 31px 14px 32px;
  border-radius: 24px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 261px;
}

.blue-title-box.light {
  background-color: #00ACEB;
}

.blue-title-box.deep {
  background-color: #14305C;
}

.blue-title-box.video {
  width: 276px;
}

.blue-title-box.certi {
  width: 337px;
}

.blue-title-box > p {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.product-info-box {
  width: 100%;
  height: 170px;
  align-content: space-between;
}

.product-info-box div {
  width: 100%;
}

.product-info-box p {
  text-align: left;
}

.product-info-wrapper {
  width: 575px;
  height: 250px;
}

.product-img-box {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1.5px solid #14305C;
}

.product-img-box-sec, .product-img-box-third {
  height: 298px;
  display: flex;
  align-content: space-between;
}

.product-img-box-sec > div {
  width: 33%;
  display: flex;
}

.product-img-box-third > div {
  width: 30%;
  display: flex;
}

.main-box {
  padding-bottom: 80px;
}

.main-box .main-img {
  box-shadow: 7px 11px 18px #383B4087;
}

.news-btn {
  border: 1.5px solid #1E1E1E;
  font-size: 13px;
  font-weight: 600;
  color: #1E1E1E;
  width: 140px;
  padding: 0 15px;
  height: 40px;
  box-sizing: border-box;
  transition: .3s;
  cursor: pointer;
}

.news-btn:hover {
  background-color: #1E1E1E;
  color: #fff;
}

.news-btn:hover path {
  fill: #fff;
  transition: .3s;
}

.news-box {
  height: 79px;
  align-content: space-between;
  width: 30%;
}

.news-box:first-child {
  width: 40%;
}

.news-box p {
  width: 100%;
  font-weight: 800;
  font-size: 16px;
}

.inno-img {
  width: 100%;
}

@media screen and (max-width: 1240px) {
  .section-box {
    padding: 40px 0;
  }

  .section-box > div {
    width: 768px;
    margin: auto;
  }

  .top-logo {
    width: 230px;
    height: 57px;
  }

  img {
    max-width: 768px;
  }

  .sole-img {
    width: 300px;
  }

  .certi-box {
    width: 410px;
  }

  .certi-box > img {
    width: 95px;
    height: 135px;
  }

  .certi-info-box {
    padding-top: 30px;
  }

  .certi-info-box p {
    font-size: 14px;
  }

  .certi-info-box > div:first-child {
    padding-left: 10px;
  }

  .certi-info-box > div {
    width: 384px;
  }

  .title-box h3 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 5px;
    left: 2px;
  }

  .title-box {
    position: relative;
    padding-bottom: 20px;
  }

  .ex-box {
    width: 356px;
    height: 525px;
  }

  .ex-box img {
    width: 100%;
  }

  .main-box {
    justify-content: center;
  }

  .main-box .main-img {
    margin-bottom: 60px;
  }

  .product-info-wrapper {
    width: 100%;
  }

  .product-img-box > div {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .product-img-box {
    padding-bottom: 20px;
  }

  .product-img-box-third > div, .product-img-box-sec > div {
    width: 50%;
    margin-bottom: 20px;
  }

  .product-img-box-sec, .product-img-box-third {
    height: 460px;
  }

  .certi-text p {
    font-size: 12px;
  }

  .news-box {
    height: 80px;
  }

  .news-box p {
    font-size: 12px;
  }

  .blue-title-box > p {
    font-size: 16px;
  }

  iframe {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ziosummary-wrapper {
    border-top: none;
  }
  
  .pc {
    display: none;
  }

  .mobile {
    display: inline-block;
  }

  .section-box > div {
    padding: 0 40px;
  }

  .section-box:first-child {
    padding-top: 10px;
  }
  
  .section-box .info-text {
    padding: 30px 0;
  }

  .sole-img {
    width: 480px;
    height: 185px;
  }

  .certi-box {
    width: 100%;
    padding: 40px 0 30px 0;
  }

  .certi-box > img {
    width: 161px;
    height: 228px;
  }

  .certi-info-box {
    padding-top: 0;
  }
  
  .certi-info-box > div:first-child {
    padding-left: 0;
  }

  .certi-info-box > div {
    padding-left: 0;
    width: 50%;
  }

  .certi-info-box p {
    font-weight: 500;
    font-size: 13px;
  }

  .ex-box {
    width: 325px;
    height: 496px;
  }

  .ex-box img {
    width: 100%;
    /* height: 287px; */
  }

  .ex-img {
    content: url("@/assets/product/social/ziosummary/ziosummary.svg");
  }
}

@media screen and (max-width: 480px) {
  .top-logo {
    width: 200px;
    height: 50px;
  }

  .section-box .info-text {
    width: 365px;
    margin: auto;
    padding: 0;
    font-size: 12px;
  }

  .ex-img {
    content: url("@/assets/product/social/ziosummary/zio-summary.svg");
  }

  .sole-img {
    width: 360px;
    height: 150px;
  }

  .certi-box > img {
    width: 175px;
    height: 248px;
  }

  .certi-box {
    width: 360px;
    height: 506px;
    padding: 0;
    align-content: space-between;
    margin: 0;
  }

  .certi-text {
    margin-top: 20px;
  }

  .certi-text p {
    font-size: 14px;
  }

  .certi-info-box > div {
    width: 100%;
  }

  .certi-info-box p {
    font-size: 14px;
  }

  .title-box h3 {
    font-size: 20px;
  }

  .mini-blue-box {
    width: 6px;
    height: 6px;
    top: 0;
  }
  
  .ex-box {
    height: auto;
    width: 100%;
    align-content: space-between;
  }

  .ex-box:first-child {
    margin-bottom: 40px;
  }

  .section-box > div {
    padding: 0 60px;
    min-width: 360px;
  }

  .main-img {
    width: 100%;
  }

  .product-img-box > div, .product-img-box-sec > div, .product-img-box-third > div {
    width: 100%;
  }

  .product-img-box-sec, .product-img-box-third {
    height: 100%;
  }

  .main-box {
    padding-bottom: 60px;
  }

  .news-box {
    width: 100% !important;
    height: 100%;
    margin-bottom: 25px;
  }

  .news-box:last-child {
    margin-bottom: 0;
  }

  .news-box p {
    font-size: 14px;
  }

  .info-wrapper {
    padding: 30px 0 40px 0;
  }
}
</style>

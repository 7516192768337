<template>
    <div class="news-wrapper">
        <div class="title-box ds-flex">
          <h1 class="tab-title">뉴스</h1>
          <p class="count-box ds-flex center-start">총 <span class="blue-highlight">{{ newsList.length }}개</span>의 뉴스가 있습니다.</p>
        </div>
        <div class="contents-box">
          <div class="table-header ds-flex">
            <div class="table-th check-box" @click="setCheckBox(!allChecked)">
              <img v-if="allChecked" src="@/assets/admin/checked.png">
              <img v-else src="@/assets/admin/unchecked.png"> 
            </div>
            <div class="table-th title">제목</div> 
            <div class="table-th writer">작성자</div> 
            <div class="table-th date">사용자 지정 날짜</div> 
          </div>
          <div class="table-body">
            <div v-if="newsList.length === 0" class="no-data-box ds-flex center-center">업로드된 리스트가 없습니다.</div>
            <div v-else v-for="list in currList" :key="list" class="ds-flex td-box"> 
              <div class="table-td check-box" @click="setAllChecked(list, !list.checked)">
                <img v-if="list.checked" src="@/assets/admin/checked.png">
                <img v-else src="@/assets/admin/unchecked.png">
              </div>
              <div class="ds-flex table-td-box" @click="movePage('NewsAdd', list)">
                <div class="table-td title"><p>{{ list.title }}</p></div> 
                <div class="table-td writer">{{ list.writer }}</div> 
                <div class="table-td date">{{ list.selected_date }}</div> 
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-box ds-flex center-center">
          <pagenation ref="pagenation" @page="changePage"></pagenation>
          <div class="btn-box ds-flex">
            <div class="btn blue" @click="movePage('NewsAdd')">뉴스 추가</div>
            <div class="btn gray" :class="{'delete' : activeDelBtn}" @click="showDelPopup()">삭제</div>
          </div>
        </div>

        <div v-if="showPopup.delete" class="popup-wrapper">
            <div class="popup-box ds-flex center-center">
                <img src="@/assets/login/important_icon.png">
                <div class="popup-text-box ds-flex center-center">
                  <h3 class="ds-flex center-center">해당 리스트를 삭제하시겠습니까?</h3>
                  <p class=" ds-flex center-center">삭제할 경우, 내용 복원이 불가합니다.</p>
                </div>
                <div class="blue-btn red-btn" @click="deleteList()">삭제</div>
                <div class="blue-btn red-btn" @click="showPopup.delete = false;">취소</div>
            </div>
        </div>
        <div v-if="showPopup.complete" class="popup-wrapper">
            <div class="popup-box ds-flex center-center complete">
                <img src="@/assets/admin/pop_check_icon.png">
                <div class="popup-text-box ds-flex center-center">
                  <h3 class="ds-flex center-center" style="margin: 0">리스트가 삭제되었습니다.</h3>
                </div>
                <div class="blue-btn red-btn" @click="showPopup.complete = false; $router.go()">확인</div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagenation from "../Pagenation.vue";
import axios from "axios"
import {API} from "../../api.js";
import moment from "moment";

export default {
  name: 'NewsAd',
  components: {
    Pagenation
  },
  data: () => ({
    upload: {
        server: '',
        dir: 'upload',
        menu: 'news',
    },
    showPopup: {
      delete: false,
      complete: false
    },
    newsList: [], // 전체 뉴스 리스트
    currList: [], // 현재 페이지에서 보여주고 있는 리스트
    allChecked: false,
    activeDelBtn: false,
    currPage: 1,
    listSize: 18,
  }),
  methods: {
    // 전체 체크박스 클릭 시
    setCheckBox(checked) {
      this.allChecked = checked;
      this.activeDelBtn = checked;

      for(let i=0; i<this.currList.length; i++) {
        this.currList[i].checked = this.allChecked;
      }
    },
    // 리스트 개별 체크박스 클릭 시
    setAllChecked(list, checked) {
      list.checked = checked;
      
      this.allChecked = true;
      this.activeDelBtn = false;

      for(let i=0; i<this.currList.length; i++) {
        if(this.currList[i].checked) {
          this.activeDelBtn = true;
        } else {
          this.allChecked = false;
        }
      }
    },
    // 전체 뉴스 리스트를 가져온다.
    getNewsList() {
      let singleton = this;
      let param = {
          table: this.upload.menu,
          idx: '',
          option: ''
      }
      API.getList(param, function(status, res){
        if(res.data.length > 0) {
          for(let i=0; i<res.data.length; i++) {
            singleton.newsList.push(res.data[i]);
            singleton.newsList[i].date = moment(singleton.newsList[i].date).format('YYYY-MM-DD')
            singleton.newsList[i].selected_date = moment(singleton.newsList[i].selected_date).format('YYYY-MM-DD')
            singleton.newsList[i].checked = false;

            if(i === res.data.length-1) {
              singleton.$refs.pagenation.setSize(singleton.newsList.length, singleton.listSize);
              singleton.setCurrList();
            }
          }
        }
      })
    },
    // 현재 페이지에 보여질 리스트 set
    setCurrList() {
      this.currList = [];
      var range = this.currPage * this.listSize;

      for (let i = range - this.listSize; i < range; i++) {
        if (this.newsList[i] !== undefined) {
          this.currList.push(this.newsList[i]);
        }
      }
    },
    showDelPopup() {
      if(this.activeDelBtn) {
        console.log('this.activeDelBtn>> ', this.activeDelBtn);
        this.showPopup.delete = true;
      }
    },
    // 선택한 리스트를 삭제한다.
    async deleteList() {
      this.showPopup.delete = false;

      let list = this.newsList;

      for(let i=0; i<list.length; i++) {
        if(list[i].checked) {
          // this.removeFile(list[i]["upload_name"]); // 업로드 되어있는 파일 삭제

          await axios(this.upload.server + '/delete/list', {
              method: 'POST',
              data: '',
              headers : {},
              params: {'table': this.upload.menu, 'idx': list[i].idx}
          }).then(response => {
              if (response.data) {
                  console.log('success .. >> ', response.data);
              }
          }).catch( () => {
              console.log('error..');
              return false;
          });
        }
        if(i === list.length-1) {
          this.showPopup.complete = true;
        }
      }
    },
    // 업로드된 파일을 삭제한다.
    async removeFile(fileName) {
        console.log(fileName);
        return axios(this.upload.server + '/delete/file', {
            method: 'POST',
            data: '',
            headers : {},
            params: {'menu': this.upload.menu, 'fileName': fileName}
        }).then(response => {
            if (response.data) {
                console.log('success .. >> ', response.data);
            }
            // throw new Error('Server or network error');
        }).catch( () => {
            console.log('error..');
            return false;
        });
    },
    // 페이지 이동 버튼 클릭 시 동작
    changePage(page) {
      window.scrollTo(0,0);
      this.currPage = page;
      this.setCurrList();
      this.setCheckBox(false);
    },
    movePage(name, list) {
      if(list === undefined) {
        this.$router.push({name: name});
      } else {
        this.$router.push({name: name, query: {idx: list.idx}})
      }
    },
  },
  mounted() {
    window.scrollTo(0,0);
    this.getNewsList();
  },
  created() {
  }
}
</script>

<style scoped>
.news-wrapper {
  /* min-height: 1080px; */
}

.title-box {
  width: 100%;
  height: 70px;
  padding: 15px 30px;
  box-sizing: border-box;
  align-items: center;
}

.tab-title {
  color: #14141C;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.count-box {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
  margin-left: 15px;
}

.blue-highlight {
  color: #00ACEB;
  font-weight: 600;
  margin-left: 3px;
}

.contents-box {
  padding: 0 20px;  
}

.table-header {
  width: 100%;
  height: 40px;
  background-color: #555861;
}

.table-th {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-right: 1px solid #fff;
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.table-th:last-child {
  border-right: none;
}

.table-th.title {
  width: calc(100% - 400px);
}

.table-th.writer {
  width: 180px;
}

.table-th.date {
  width: 180px;
}

.check-box {
  width: 40px;
  cursor: pointer;
}

.table-body {
  padding-top: 6px;
}

.td-box {
  background-color: #fff;
}

.td-box:hover {
  background-color: #EAF0F8;
  transition: .3s;
}

.table-td-box {
  width: calc(100% - 40px);
}

.table-td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px solid #DCDEE2;
  box-sizing: border-box;
  cursor: pointer;
}

.table-td p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-td.title {
  width: calc(100% - 360px);
  justify-content: flex-start;
  padding: 0 13px;
}

.table-td.writer {
  width: 180px;
}

.table-td.date {
  width: 180px;
}

.no-data-box {
  width: 100%;
  height: 880px;
  background-color: #F2F2F5;
  border: 1px solid #B3B6C0;
  color: #707070;
  font-size: 15px;
  box-sizing: border-box;
}

.btn-box {
  position: absolute;
  right: 20px;
}

.btn {
  width: 110px;
  height: 40px;
  border-radius: 5px;
  background-color: #F2F2F5;
  border: 1px solid;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  margin-right: 10px;
  font-weight: 600;
  box-sizing: border-box;
}

.btn:last-child {
  margin-right: 0;
}

.btn.blue {
  border-color: #00ACEB;
  color: #00ACEB;
  cursor: pointer;
}

.btn.blue:hover {
  color: #fff;
  background-color: #00ACEB;
}

.btn.gray {
  border-color: #B3B6C0;
  color: #B3B6C0;
}

.btn.delete {
  cursor: pointer;
  color: #FF3E3E;
  border-color: #FF3E3E;
  cursor: pointer;
}

.btn.delete:hover {
  background-color: #FF3E3E;
  color: #fff;
}

.bottom-box {
  position: relative;
  height: 80px;
  padding: 20px;
  box-sizing: border-box;
}

.popup-wrapper {
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: rgba(14, 14, 19, 0.7);
    position: absolute;
    top: 0;
    left: 0;
}

.popup-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    height: 200px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 29px 0;
}

.popup-box h3 {
    width: 100%;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    height: 18px;
    align-content: center;
    margin-bottom: 10px;
}

.popup-box p {
    font-size: 12px;
    color: #000000;
    height: 14px;
    width: 100%;
    align-content: center;
}
 
.popup-box .blue-btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    margin: 0 5px;
}

.popup-text-box {
    width: 100%;
    height: 82px;
    align-content: center;
}

.red-btn {
    background-color: #fff;
    color: #FF3E3E;
    border: 1px solid #FF3E3E;
    font-weight: 600;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
}

.red-btn:hover {
    background-color: #FF3E3E;
    color: #fff;
}

.complete .red-btn {
  background-color: #FF3E3E;
  color: #fff;
}

.complete .red-btn:hover {
  background-color: #D02727;
}

@media screen and (max-width: 1439px) {
    .contents-wrapper {
      width: calc(100% - 270px);
    }

    .contents-box {
      padding: 0 15px;
    }

    .title-box {
      padding: 12px 20px;
      height: 40px;
      align-content: center;
    }

    .table-body {
      padding-top: 5px;
    }

    .tab-title {
      font-size: 14px;
      line-height: 17px;
    }

    .count-box {
      font-size: 11px ;
    }

    .table-header {
      height: 30px;
    }

    .check-box {
      width: 30px;
      height: 30px;
    }

    .check-box > img {
      width: 10px;
      height: 10px;
    }

    .table-th {
      height: 30px;
      font-size: 11px;
    }

    .table-td {
      height: 30px;
      font-size: 11px;
    }

    .no-data-box {
      font-size: 11px;
      height: 550px;
    }

    .btn {
      width: 80px;
      height: 25px;
      font-size: 11px;
      border-radius: 3px;
      margin-right: 5px;
    }

    .bottom-box {
      height: 50px;
      padding: 13px;
    }

    .table-th.title {
      width: calc(100% - 230px);
    }

    .table-th.writer {
      width: 100px;
    }

    .table-th.date {
      width: 100px;
    }

    .table-td.title {
      width: calc(100% - 200px);
      padding: 0 10px;
    }

    .table-td.writer {
      width: 100px;
    }

    .table-td.date {
      width: 100px;
    }

    .table-td-box {
      width: calc(100% - 30px);
    }
}

/* @media screen and (max-width: 1030px) {
  .tab-title {
    font-size: 18px;
  }

  .title-box {
    padding: 20px 25px;
    height: 60px;
  }

  .contents-wrapper {
    width: 100%;
    height: calc(100vh - 60px);
    padding-top: 60px;
  }

  .table-header {
    height: 50px;
  }

  .table-th {
    height: 50px;
    font-size: 16px;
  }
} */
</style>

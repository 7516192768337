<template>
  <div class="wrapper" :style="style">
  <!-- <div class="wrapper"> -->
    <Menu v-if="!isAdmin" class="menu-area"/>
    <router-view :key="$route.fullPath" @scrollPage="scrollPage()"></router-view>
    <BottomBar v-if="!isAdmin"></BottomBar>
    <!-- <div v-if="showTopBtn && !isAdmin" class="top-btn" @click="scrollToTop()">
        <img src="@/assets/main/top.png"/>
    </div> -->
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import BottomBar from './components/BottomBar.vue'

export default {
  name: 'App',
  components: {
    Menu,
    BottomBar,
  },
  data: () => ({
    showTopBtn: false,
    isAdmin: false,
    isHome: false,
    style: {},
  }),
  methods: {
    // setHeight() {
    //   console.log('this.isHome >> ', this.isHome);
    //   if(this.isHome) {
    //     this.style = {height: '100vh'};
    //   } else {
    //     this.style = {height: '100%'};
    //   }
    // },
    // scrollPage() {
    //   this.style = {height: '100%'}
    // },
    scrollEvt() { // 최상단으로 가기 버튼 show 설정
      let scrollY = window.scrollY;
      if(scrollY > 0) {
        this.showTopBtn = true;
      } else {
        this.showTopBtn = false;
      }
    },
    scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.showTopBtn = false;
    }
  },
  mounted() {
    window.scrollTo(0,0);
  },
  watch: {
    '$route' (to) {
      if(to.path.includes('admin') || to.path.includes('login') || to.path.includes('join')|| to.path.includes('caution')) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    }
  }
}
</script>

<style>
@font-face {
	font-family: 'Pretendard Variable';
	font-weight: 45 920;
	font-style: normal;
	font-display: swap;
	src: local('Pretendard Variable'), url('@/assets/fonts/PretendardVariable.woff2') format('woff2-variations');
}

@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-Thin.woff') format('woff');
    font-weight: 100;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('@/assets/fonts/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    font-display: swap;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, textarea {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	vertical-align: -webkit-baseline-middle;
	vertical-align: -moz-middle-with-baseline;
  /* font-family: 'SUIT-Variable' !important;
  src: url('@/assets/fonts/SUIT-Variable.ttf') format('truetype') !important; */
  font-family: 'Pretendard';
  line-height: 30px;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

.ds-flex {
  display: flex;
  flex-wrap: wrap;
}

.center-center {
  align-items: center;
  justify-content: center;
}

.center-between {
  align-items: center;
  justify-content: space-between;
}

.center-around {
  align-items: center;
  justify-content: space-around;
}

.center-start {
  align-items: center;
  justify-content: flex-start;
}

.center-end {
  align-items: center;
  justify-content: flex-end;
}

.start-around {
  align-items: baseline;
  justify-content: space-around;;
}

.start-start {
  align-items: baseline;
  justify-content: flex-start;
}

.start-center {
  align-items: baseline;
  justify-content: center;
}

.start-end {
  align-items: baseline;
  justify-content: flex-end;
}

.start-between {
  align-items: baseline;
  justify-content: space-between;
}

.end-start {
  align-items: flex-end;
  justify-content: flex-start;
}

.end-between {
  align-items: flex-end;
  justify-content: space-between;
}

.menu-area {
  position: fixed ;
  top: 0;
  left: 0;
  z-index: 30;
}

.route-page {
  /* max-width: 1920px; */
  margin: auto;
}

/* .top-btn {
  width: 48px;
  height: 48px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0px 2px 5px #8b8b8b29;
} */

</style>
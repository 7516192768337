<template>
    <div class="history-wrapper ds-flex center-center">
        <div class="history-box">
            <!-- <div class="title">연혁</div> -->
            <div class="history-list-box ds-flex" v-for="(list, i) in hisList" :key="list">
                <div class="year" :class="[i===0 ? 'strong' : '']">
                    <div v-if="i===0" class="blue-box"></div>
                    {{ list.year }}
                </div>
                <div class="contents">
                    <p v-for="hist in list.history" :key="hist" :class="[hist.bold===1?'bold':'']">{{ hist.contents }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {API} from "../../api.js";

export default {
  name: 'History',
  components: {
    
  },
  data: () => ({
    upload: {
        server: '',
        dir: 'upload',
        menu: 'history',
    },
    hisList: [], // history list
    highlight: {
      active: false,
      style: {}
    },
  }),
  methods: {
    // 연도 리스트를 가져온다.
    getYear() {
      let singleton = this;
      API.getYear(function(status, res){
        if(res.data.length > 0) {
          for(let i=0; i<res.data.length; i++) {
            singleton.hisList.push({year: res.data[i].category, history: []});

            if(i === res.data.length-1) {
              singleton.getHisList();
            }
          }
        }
      })
    },
    async getHisList() {
      let singleton = this;
      let param = {
        table: this.upload.menu,
        idx: '',
        option: ''
      }
      API.getList(param, function(status, res){
        // console.log('res >> ', res);
        let list = singleton.hisList;
        for(let j=0; j<list.length; j++) {
          for(let i=0; i<res.data.length; i++) {
            if(list[j].year === res.data[i].category) {
              list[j].history.push({contents: res.data[i].contents, bold: res.data[i].bold});
            }
          }
        }
      });
    },
  },
  mounted() {
    this.getYear();
  }
}
</script>

<style scoped>
/* @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css'); */

.history-wrapper {
  padding: 80px 0;
  color: #191E2C;
  border-top: 1.5px solid #00ACEB;
}

.history-box {
  width: 1240px;
}

.title {
    color: #1E1E1E;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 60px;
}

.blue-box {
    position: absolute;
    left: 55px;
    background-color: #00ACEB;
    width: 8px;
    height: 8px;
}

.year {
    position: relative;
    width: 200px;
    margin-right: 80px;
    font-size: 22px;
    font-weight: bold;
    line-height: 40px;
    color: #9EA5B6;
}

.year.strong {
    color: #191E2C;
}

.contents {

}

.contents > p {
    text-align: left;
    font-size: 16px;
    line-height: 45px;
}

.contents > p.bold {
    font-weight: bold;
    text-decoration: underline;
}

.history-list-box {
    padding-bottom: 80px;
}

.history-list-box:last-child {
    padding-bottom: 0;
}

@media screen and (max-width: 1240px) {
  .history-wrapper {
    padding: 40px 0;
  }
  
  .year {
    font-size: 18px;
    padding: 0 50px 0 15px;
    box-sizing: border-box;
    width: 45px;
  }

  .blue-box {
    width: 4px;
    height: 4px;
    left: 7px;
    top: 10px;
  }

  .contents > p {
    font-size: 12px;
    line-height: 35px;
  }

  .history-box {
    width: 768px;
  }

  .history-list-box {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .history-wrapper {
    border-top: 0;
    padding: 0 40px 40px 40px;
  }

  .year {
    margin-right: 30px;
  }

  .contents {
    width: 510px;
  }

  .history-list-box {
    padding-left: 25px;
  }
}

@media screen and (max-width: 480px) {
  .history-list-box {
    width: 360px;
    margin: auto;
    padding: 20px 0;
    border-bottom: 1px solid #DBDEE7;
  }

  .history-list-box:first-child {
    padding-top: 0;
  }

  .history-list-box:last-child {
    border-bottom: none;
  }

  .year {
    padding: 0;
    font-size: 20px;
  }

  .blue-box {
    left: -7px;
  }

  .contents > p {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .history-wrapper {
    padding: 0 60px;
  }
}
</style>

<template>
    <div class="tech-wrapper">
      <div class="section-box">
        <div class="certi-box">
          <div class="title-box">
            <div class="mini-blue-box"></div>
            <h3>인증서</h3>
          </div>
          <div class="ds-flex center-start list">
            <div v-for="list in certiList" :key="list" class="data-box">
              <img :src="'/'+upload.dir+'/'+upload.menu+'/'+list['upload_name']">
              <p class="title">{{ list.title }}</p>
              <p class="contents">{{ list.contents }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section-box">
        <div>
          <div class="title-box">
            <div class="mini-blue-box"></div>
            <h3>특허증 및 저작권 등록증</h3>
          </div>
          <div class="ds-flex center-start list">
            <div v-for="list in regiList" :key="list" class="data-box">
              <img :src="'/'+upload.dir+'/'+upload.menu+'/'+list['upload_name']">
              <p class="title">{{ list.title }}</p>
              <p class="contents">{{ list.contents }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section-box">
        <div>
          <div class="title-box">
            <div class="mini-blue-box"></div>
            <h3>PCT 국제출원</h3>
          </div>
          <div class="ds-flex center-start list">
            <div v-for="list in pctList" :key="list" class="data-box">
              <img :src="'/'+upload.dir+'/'+upload.menu+'/'+list['upload_name']">
              <p class="title">{{ list.title }}</p>
              <p class="contents">{{ list.contents }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section-box">
        <div>
          <div class="title-box">
            <div class="mini-blue-box"></div>
            <h3>투자선정인증서 외 기타</h3>
          </div>
          <div class="ds-flex center-start list">
            <div v-for="list in etcList" :key="list" class="data-box">
              <img :src="'/'+upload.dir+'/'+upload.menu+'/'+list['upload_name']">
              <p class="title">{{ list.title }}</p>
              <p class="contents">{{ list.contents }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {API} from "../../api.js";

export default {
  name: 'TechAndPatent',
  components: {
    
  },
  data: () => ({
    upload: {
        server: '',
        dir: 'upload',
        menu: 'certificate',
    },
    certiList: [],
    regiList: [],
    pctList: [],
    etcList: []
  }),
  methods: {
    // 전체 리스트를 가져온다.
    getList() {
      let singleton = this;
      let param = {
        table: this.upload.menu,
        idx: '',
        option: ''
      }
      this.list = [];
      
      API.getList(param, function(status, res){
        // if(res.data.length > 0) {
          for(let i=0; i<res.data.length; i++) {
              if(res.data[i].category === "인증서") {
                singleton.certiList.push(res.data[i]);
              } else if(res.data[i].category === "특허증 및 저작권 등록증") {
                singleton.regiList.push(res.data[i]);
              } else if(res.data[i].category === "PCT 국제출원") {
                singleton.pctList.push(res.data[i]);
              } else {
                singleton.etcList.push(res.data[i]);
              }
          }
      })
    },
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped>
.tech-wrapper {
  color: #191E2C;
  border-top: 1.5px solid #00ACEB;
}

.section-box {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-box:nth-child(2n) {
  background-color: #F5F7FC;
}

.section-box > div {
  width: 1240px;
}

.title-box {
  padding-bottom: 30px;
  width: 100%;
}

.title-box h3 {
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #00ACEB;
}

.data-box {
  height: 540px;
  box-sizing: border-box;
  padding-right: 20px;
  width: 310px;
  word-break: keep-all;
}

.data-box:nth-child(4n) {
  padding-right: 0;
}

.data-box > img {
  width: 295px;
  height: 420px;
  border: 1px solid #DBDEE7;
  box-sizing: border-box;
}

.data-box .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-top: 20px;
  width: 295px;
}

.data-box .contents {
  font-size: 16px;
  color: #505050;
}

@media screen and (max-width: 1240px) {
  .title-box {
    position: relative;
    padding-bottom: 20px;
  }
  
  .title-box h3 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 3px;
    left: 3px;
  }

  .data-box > img {
    width: 180px;
    height: 255px;
  }

  .data-box {
    height: 329px;
    padding-right: 0px;
    width: 192px;
  }

  .data-box .title {
    padding-top: 10px;
    font-size: 12px;
  }

  .data-box .contents {
    font-size: 12px;
    line-height: 15px;
  }

  .data-box .title {
    width: 180px;
  }

  .section-box {
    padding: 40px 0;
  }

  .section-box > div {
    width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .tech-wrapper {
    border-top: none;
  }

  .section-box {
    padding: 0 40px;
    min-width: 688px;
  }

  .section-box > div {
    padding-top: 20px;
  }

  .section-box > .certi-box {
    padding-top: 10px;
  }

  .data-box {
    width: 160px;
    height: 300px;
    /* padding-right: 10px; */
    box-sizing: content-box;
  }

  .data-box > img {
    width: 100%;
    height: 226px;
  }

  .data-box .title {
    width: 160px;
  }
}

@media screen and (max-width: 480px) {
  .data-box {
    width: 175px;
    height: 345px;
    padding-right: 0;
  }

  .data-box > img {
    height: 248px;
  }

  .list {
    justify-content: space-between;
  }

  .title-box h3 {
    font-size: 20px;
    padding-left: 0;
  }

  .mini-blue-box {
    width: 6px;
    height: 6px;
    top: 0;
    left: -7px;
  }

  .section-box > div {
    padding-top: 40px;
    max-width: 360px;
  }

  .data-box .title {
    width: 175px;
    font-size: 14px;
  }

  .data-box .contents {
    font-size: 12px;
    padding-top: 5px;
  }

  .section-box {
    /* padding: 0 60px 40px 60px; */
    min-width: 360px;
    margin: 0 auto;
    padding: 0 0 40px 0;
  }
}
</style>

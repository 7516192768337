<template>
    <div class="ziomed-wrapper">
      <div class="section-box">
        <div>
          <img class="top-logo" src="@/assets/product/medical/ziomed/ZioMED_logo.svg">
          <p class="info-text ds-flex center-center">지오메드(ZioMed)는 <span class="bold">인공지능 및 딥러닝을 기반한</span><span class="bold blue">의료 진단 시스템</span> 입니다.</p>
          <div class="medi-box">
            <div class="medi-box-header">안면골</div>
            <div class="medi-contents">
              <div class="ds-flex center-between medi-img-box">
                <img src="@/assets/product/medical/ziomed/ziomed2.png">
                <img class="img-info" src="@/assets/product/medical/ziomed/bone.png">
                <img class="img-link" src="@/assets/product/medical/ziomed/link.png" @click="moveUrl()">
                <img src="@/assets/product/medical/ziomed/bone_img.png">
              </div>
              <iframe width="800" height="450" src="https://www.youtube.com/embed/ytNn6JYxxgw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div class="medi-box">
            <div class="medi-box-header">뇌경색</div>
            <div class="medi-contents">
              <p class="ds-flex center-center">MRI 영상의 딥러닝 학습을 통한 <span class="bold blue">뇌경색 영역 검출 및 사망률 / 장애율 예측</span></p>
              <img src="@/assets/product/medical/ziomed/cerebral.png">
            </div>
          </div>
          <div class="medi-box">
            <div class="medi-box-header">폐결절</div>
            <div class="medi-contents">
              <p class="ds-flex center-center">흉부 CT 영상의 딥러닝 학습을 통한 <span class="bold blue">양성 / 악성 결절 판단</span></p>
              <img src="@/assets/product/medical/ziomed/solitary.png">
            </div>
          </div>
          <div class="medi-box">
            <div class="medi-box-header">골다공증</div>
            <div class="medi-contents">
              <p class="ds-flex center-center">CT 영상의 딥러닝 학습을 통한 <span class="bold blue">골다공증 진단</span></p>
              <img src="@/assets/product/medical/ziomed/osteoporosis.png">
            </div>
          </div>
          <div class="medi-box">
            <div class="medi-box-header">수면장애</div>
            <div class="medi-contents">
              <p class="ds-flex center-center">심전도 기반의 폐쇄성 수면 무호흡증 진단을 위한 <span class="bold blue">휴대용 의료 기기 개발</span></p>
              <img src="@/assets/product/medical/ziomed/sleep.png">
            </div>
          </div>
          <img class="arrow" src="@/assets/product/medical/ziomed/arrow.png">
          <div class="bottom-box">
            <p><strong>2023년</strong>, 식약처 2등급 이상 인증 후 <strong>병원 공급 목표</strong></p>
          </div>
        </div>
      </div>
      <!-- <div class="section-box">
        <div>
          <div class="title-box">
            <div class="mini-blue-box"></div>
            <h3>ZioMed 특장점</h3>
          </div>
          <div class="ds-flex center-between blue-wrapper">
            <div class="blue-box ds-flex center-center">의료영상(CT, MRI) 분석 : 안면골 골절,폐 결절, 골다공증, 뇌경색</div>
            <div class="blue-box ds-flex center-center">내시경 영상 분석 : 캡슐 위치 추적, 위내시경 가이드</div>
            <div class="blue-box ds-flex center-center">생체 신호(ECG, EEG) 분석 : 수면장애 진단</div>
          </div>
        </div>
      </div> -->
    </div>
</template>

<script>

export default {
  name: 'Ziomed',
  components: {
    
  },
  data: () => ({
  }),
  methods: {
    movePage(name) {
      this.$router.push({name: name});
    },
    moveUrl() {
      window.open('https://ieeexplore.ieee.org/document/9832866');
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.ziomed-wrapper {
  color: #191E2C;
  border-top: 1.5px solid #25B5AD;
}

.section-box {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-box:nth-child(2n) {
  background-color: #D1F0F2;
}

.section-box > div {
  width: 1240px;
}

.section-box p {
  color: #505050;
  font-size: 18px;
  padding: 40px 0;
  font-weight: 500;
}

.section-box .info-text {
  font-size: 18px;
  padding: 40px 0 80px 0;
}

.text-wrapper {
  padding: 40px 0;
}

.section-box .bold {
  font-weight: bold;
  color: #000;
  padding-left: 5px;
}

.section-box .blue {
  color: #25B5AD;
}

.title-box {
  padding-bottom: 40px;
}

.title-box h3 {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
  padding-left: 10px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #25B5AD;
}

.main-text {
  font-size: 40px;
  color: #5484C4;
  font-weight: bold;
}

.blue-wrapper {
  height: 150px;
  align-content: space-between;
}

.blue-box {
  width: 590px;
  height: 60px;  
  background-color: #25B5AD;
  border-radius: 60px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.medi-box {
  width: 100%;
  /* height: 520px; */
  border: 1px solid #25B5AD;
  margin-bottom: 40px;
}

/* .medi-box:first-child {
  height: 598px;
} */

.medi-box-header {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background-color: #25B5AD;
}

.medi-contents {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: calc(100% - 68px);
  padding: 40px 120px;
}

.medi-contents p {
  padding: 0 0 40px 0;
  width: 100%;
}

.medi-img-box {
  width: 100%;
  margin-bottom: 40px;
}

.img-link {
  margin: 80px 0 40px 0;
  cursor: pointer;
}

.bottom-box {
  width: 100%;
  height: 100px;
  background-color: #071C34;
  margin-top: 40px;
}

.bottom-box p {
  font-size: 25px;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100%;
  font-weight: 400;
}

.bottom-box strong {
  color: #00ACEB;
  font-weight: 700;
}

.bottom-box strong:last-child {
  margin-left: 10px;
}

@media screen and (max-width: 1240px) {
  .section-box {
    padding: 40px 0;
  }

  .section-box > div {
    width: 768px;
    margin: auto;
  }

  .top-logo {
    width: 80px;    
  }

  .title-box h3 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 5px;
    left: 2px;
  }

  .title-box {
    position: relative;
    padding-bottom: 20px;
  }

  .main-text {
    font-size: 30px;
  }

  img {
    width: 100%;
  }

  .blue-wrapper {
    height: 100px;
  }

  .blue-box {
    width: 375px;
    height: 40px;
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .ziomed-wrapper {
    border-top: none;
  }

  .section-box > div {
    padding: 0 40px;
  }

  .section-box:first-child {
    padding-top: 10px;
  }
  
  .section-box p {
    padding: 30px 0;
  } 

  .top-logo {
    height: 57px;
  }

  img {
    width: 600px;
    height: 286px;
  }

  .blue-box {
    width: 100%;
  }

  .blue-wrapper {
    height: 160px;
  }
}

@media screen and (max-width: 480px) {
  .main-text {
    font-size: 30px;
  }

  .section-box p {
    padding: 0 0 20px 0;
    font-size: 14px;
  }

  img {
    width: 360px;
    height: auto;
  }

  .title-box h3 {
    font-size: 20px;
  }

  .mini-blue-box {
    width: 6px;
    height: 6px;
    top: 0;
  }

  .blue-wrapper {
    min-width: 360px;
    height: 150px;
  }

  .blue-box {
    font-size: 13px;
  }

  .section-box > div {
    padding: 0 60px;
    min-width: 360px;
  }

  .medi-box-header {
    height: 40px;
    font-size: 14px;
  }

  .medi-contents {
    padding: 20px;
  }

  iframe {
    height: 200px;
  }

  .medi-box {
    margin-bottom: 20px;
  }

  .bottom-box {
    height: 60px;
    margin-top: 20px;
  }

  .bottom-box p {
    padding: 0;
    font-size: 16px;
  }

  .section-box .info-text {
    padding: 30px 0 40px 0;
    font-size: 12px;
  }

  .medi-img-box {
    justify-content: center;
  }

  .medi-img-box .img-info {
    width: 200px;
    margin-top: 40px;
  }

  .img-link {
    margin: 30px 0;
  }

  .arrow {
    width: 50%;
  }

  .medi-img-box img {
    width: 100%;
  }

}
</style>

<template>
    <div class="ziovis-wrapper">
      <div class="section-box">
        <div>
          <h1 class="main-text">ZioVIS</h1>
          <div class="info-wrapper info-text">
            <p class="ds-flex center-center">지오비스(ZioVIS)는 관심 객체를 즉각적으로 선별하여 <span class="bold">효율적으로 관제</span>하는 <span class="bold blue">지능형 선별 관제 시스템</span>입니다.</p>
            <p class="ds-flex center-center">주요 기능으로 실시간 검출 객체 저장, 이벤트(침입, 배회, 유기, 화재, 쓰러짐, 싸움 등) 검출 및 알람, 중대재해처벌법을 해결할 수 있는 다양한 이벤트 검출이 가능합니다.</p>
          </div>
          <div class="ds-flex center-between main-box">
            <div class="ds-flex center-between">
              <img class="main-img" src="@/assets/product/social/ziovis/ziovis.png">
              <div class="ds-flex product-info-wrapper">
                <div class="blue-title-box title deep">
                  <p>지능형 선별 관제 시스템(ZioVIS)</p>
                </div>
                <div class="product-info-box ds-flex">
                  <div>
                    <p>· 수많은 카메라의 영상 정보로 부터 객체를 동시에 탐지및 저장할 수 있는 시스템</p>
                    <p>· 이벤트성 상황이 발생하면 즉시 알람 및 확인</p>
                    <p>· 설정한 기간내에 탐지된 객체를 다양한 조건으로 조회 및 확인</p>
                    <p>· 유연성과 확장성을 기반으로 B2B와 B2C까지 확대가능</p>
                  </div>
                </div>
              </div>
            </div>
            <img src="@/assets/product/social/ziovis/table.png">
          </div>
        </div>
      </div>
      <div class="section-box">
        <div>
          <div class="blue-title-box deep">
            <p>응용분야</p>
          </div>
          <div class="product-img-box ds-flex center-between">
            <div>
              <img src="@/assets/product/social/ziovis/parking.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziovis/car.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziovis/trespass.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziovis/fire.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziovis/danger.png">
            </div>
            <div>
              <img src="@/assets/product/social/ziovis/statistics.png">
            </div>
          </div>     
        </div>
      </div>
      <div class="section-box">
        <div>
          <iframe width="800" height="450" src="https://www.youtube.com/embed/nUzPmOth1jo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
      <!-- <div class="section-box">
        <div>
          <div class="title-box">
            <div class="mini-blue-box"></div>
            <h3>ZioVIS 특장점</h3>
          </div>
          <div class="ds-flex center-between blue-wrapper">
            <div class="blue-box ds-flex center-center">관심 객체를 즉각적으로 선별하여 관제</div>
            <div class="blue-box ds-flex center-center">이벤트(침입, 배회, 유기, 화재, 쓰러짐, 싸움 등) 검출 및 알람</div>
            <div class="blue-box ds-flex center-center">실시간 검출 객체 저장</div>
            <div class="blue-box ds-flex center-center">중대재해처벌법을 해결할 수 있는 다양한 이벤트 검출 가능</div>
          </div>
        </div>
      </div> -->
    </div>
</template>

<script>

export default {
  name: 'ZioVis',
  components: {
    
  },
  data: () => ({
  }),
  methods: {
    movePage(name) {
      this.$router.push({name: name});
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.ziovis-wrapper {
  color: #191E2C;
  border-top: 1.5px solid #00ACEB;
}

.section-box {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-box:nth-child(2n) {
  background-color: #F2F6FB;
}

.section-box > div {
  width: 1240px;
}

.section-box p {
  color: #505050;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.section-box .info-text p {
  font-size: 18px;
  width: 755px;
  word-break: keep-all;
  text-align: center;
  margin: auto;
}

.info-wrapper {
  padding: 40px 0 80px 0;
}

.section-box .bold {
  font-weight: bold;
  color: #000;
  padding-left: 5px;
}

.section-box .blue {
  color: #00ACEB;
}

.title-box {
  padding-bottom: 40px;
}

.title-box h3 {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
  padding-left: 10px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #00ACEB;
}

.main-text {
  font-size: 45px;
  color: #071C34;
  font-weight: 800;
}

.blue-wrapper {
  height: 150px;
  align-content: space-between;
}

.blue-box {
  width: 590px;
  height: 60px;  
  background-color: #071C34;
  border-radius: 60px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.blue-title-box {
  padding: 13px 31px 14px 32px;
  border-radius: 24px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 261px;
}

.blue-title-box.title {
  width: 300px;
}

.blue-title-box.light {
  background-color: #00ACEB;
}

.blue-title-box.deep {
  background-color: #071C34;
}

.blue-title-box > p {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.product-info-wrapper {
  width: 575px;
  height: 200px;
}

.product-img-box {
  margin-top: 40px;
  height: 298px;
  display: flex;
  align-content: space-between;
}

.product-img-box > div {
  width: 30%;
  display: flex;
}

.main-box > div {
  padding-bottom: 80px;
  width: 100%;
}

.main-box .main-img {
  box-shadow: 7px 11px 18px #383B4087;
}

@media screen and (max-width: 1240px) {
  .section-box {
    padding: 40px 0;
  }

  .section-box > div {
    width: 768px;
    margin: auto;
  }

  .title-box h3 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 5px;
    left: 2px;
  }

  .title-box {
    position: relative;
    padding-bottom: 20px;
  }

  .main-text {
    font-size: 30px;
  }

  img {
    width: 430px;
  }

  .blue-wrapper {
    height: 100px;
  }

  .blue-box {
    width: 375px;
    height: 40px;
    font-size: 13px;
  }

  iframe {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ziovis-wrapper {
    border-top: none;
  }

  .section-box > div {
    padding: 0 40px;
  }

  .section-box:first-child {
    padding-top: 10px;
  }
  
  .section-box p {
    padding: 30px 0;
  }

  img {
    width: 335px;
    height: 286px;
  }

  .blue-box {
    width: 100%;
  }

  .blue-wrapper {
    height: 220px;
  }
}

@media screen and (max-width: 480px) {
  .main-text {
    font-size: 30px;
  }

  .section-box p {
    padding: 0;
  }

  .product-img-box img {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  .product-img-box div:last-child img {
    margin-bottom: 0;
  }

  .title-box h3 {
    font-size: 20px;
  }

  .mini-blue-box {
    width: 6px;
    height: 6px;
    top: 0;
  }

  .blue-wrapper {
    min-width: 360px;
    height: 205px;
  }

  .blue-box {
    font-size: 13px;
  }

  .section-box > div {
    padding: 0 60px;
    min-width: 360px;
  }

  .main-img {
    width: 100%;
    margin-bottom: 60px;
  }

  .main-box img {
    width: 100%;
    height: 200px;
  }

  .product-img-box {
    height: 100%;
  }

  .product-img-box > div {
    width: 100%;
  }

  .section-box .info-text p {
    font-size: 12px;
    width: 360px;
  }

  .info-wrapper {
    padding: 30px 0 40px 0;
  }
}
</style>

<template>
    <div class="history-wrapper">
        <div class="title-box ds-flex center-between">
          <h1 class="tab-title">연혁</h1>
          <div class="input-box select" :style="comboBox.style">
              <div 
                  v-for="(option, i) in comboBox.option" 
                  :key="option" 
                  class="option ds-flex center-between" 
                  @click="setComBox(option);"
              >
                  {{ option }}
                  <svg id="arrow" v-if="i===0" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.0006 1.8577C15.0006 2.06371 14.927 2.26972 14.7432 2.44139L8.34616 8.72466C8.34616 8.72466 7.94175 8.99933 7.6844 8.99933C7.42704 8.99933 7.20646 8.89633 7.02263 8.75899L0.257927 2.47573C-0.072955 2.13238 -0.072955 1.58303 0.257927 1.23968C0.58881 0.89633 1.21381 0.930665 1.58146 1.23968L7.6844 6.90491L13.4197 1.27401C13.7873 0.930665 14.3388 0.930665 14.7065 1.27401C14.8903 1.44569 15.0006 1.68603 15.0006 1.89204V1.8577Z" :fill=comboBox.fill />
                  </svg>
              </div>
          </div>
          <div class="btn-box ds-flex">
            <div class="btn gray move" :class="{'active': (hisData.idx!=='' && hisData.order!==0)}" @click="moveTop()">
              최상단으로 이동
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1C14.9706 0.999999 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C0.999999 5.02944 5.02944 1 10 1ZM10 -4.37114e-07C15.5228 -6.78525e-07 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 -1.95703e-07 15.5228 -4.37114e-07 10C-6.78525e-07 4.47715 4.47715 -1.95703e-07 10 -4.37114e-07ZM9.64644 4.64645L6.46447 7.82843C6.2692 8.02369 6.2692 8.34027 6.46447 8.53553C6.65973 8.7308 6.97631 8.7308 7.17157 8.53553L9.5 6.20711L9.5 15L10.5 15L10.5 6.20711L12.8284 8.53553C13.0237 8.7308 13.3403 8.7308 13.5355 8.53553C13.7308 8.34027 13.7308 8.02369 13.5355 7.82843L10.3536 4.64645C10.1583 4.45118 9.84171 4.45118 9.64644 4.64645Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (hisData.idx!=='' && hisData.order!==0)}" @click="moveUp()">
              위로 이동
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1C14.9706 0.999999 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C0.999999 5.02944 5.02944 1 10 1ZM10 -4.37114e-07C15.5228 -6.78525e-07 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 -1.95703e-07 15.5228 -4.37114e-07 10C-6.78525e-07 4.47715 4.47715 -1.95703e-07 10 -4.37114e-07ZM9.64644 4.64645L6.46447 7.82843C6.2692 8.02369 6.2692 8.34027 6.46447 8.53553C6.65973 8.7308 6.97631 8.7308 7.17157 8.53553L9.5 6.20711L9.5 15L10.5 15L10.5 6.20711L12.8284 8.53553C13.0237 8.7308 13.3403 8.7308 13.5355 8.53553C13.7308 8.34027 13.7308 8.02369 13.5355 7.82843L10.3536 4.64645C10.1583 4.45118 9.84171 4.45118 9.64644 4.64645Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (hisData.idx!=='' && hisData.order!==hisList.length-1)}" @click="moveDown()">
              아래로 이동
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C0.999999 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 6.78525e-07 10 4.37114e-07C4.47715 1.95703e-07 -1.95703e-07 4.47715 -4.37114e-07 10C-6.78525e-07 15.5228 4.47715 20 10 20ZM9.64644 15.3536L6.46447 12.1716C6.2692 11.9763 6.2692 11.6597 6.46447 11.4645C6.65973 11.2692 6.97631 11.2692 7.17157 11.4645L9.5 13.7929L9.5 5L10.5 5L10.5 13.7929L12.8284 11.4645C13.0237 11.2692 13.3403 11.2692 13.5355 11.4645C13.7308 11.6597 13.7308 11.9763 13.5355 12.1716L10.3536 15.3536C10.1583 15.5488 9.84171 15.5488 9.64644 15.3536Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (hisData.idx!=='' && hisData.order!==hisList.length-1)}" @click="moveBottom()">
              최하단으로 이동
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C0.999999 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 6.78525e-07 10 4.37114e-07C4.47715 1.95703e-07 -1.95703e-07 4.47715 -4.37114e-07 10C-6.78525e-07 15.5228 4.47715 20 10 20ZM9.64644 15.3536L6.46447 12.1716C6.2692 11.9763 6.2692 11.6597 6.46447 11.4645C6.65973 11.2692 6.97631 11.2692 7.17157 11.4645L9.5 13.7929L9.5 5L10.5 5L10.5 13.7929L12.8284 11.4645C13.0237 11.2692 13.3403 11.2692 13.5355 11.4645C13.7308 11.6597 13.7308 11.9763 13.5355 12.1716L10.3536 15.3536C10.1583 15.5488 9.84171 15.5488 9.64644 15.3536Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray" :class="{'delete': hisData.idx!==''}" @click="showDelPopup()">삭제</div>
          </div>
          <!-- <div class="mobile-btn-box">
            <p class="ds-flex center-center">순서 변경</p>
            <div class="btn gray move" :class="{'active': (hisData.idx!=='' && hisData.order!==0)}" @click="moveTop()">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 7.63765C0.5 7.43176 0.573574 7.22586 0.75751 7.05429L7.15849 0.774525C7.15849 0.774525 7.56315 0.5 7.82066 0.5C8.07817 0.5 8.2989 0.602946 8.48283 0.740209L15.2517 7.01997C15.5828 7.36313 15.5828 7.91218 15.2517 8.25533C14.9206 8.59849 14.2952 8.56417 13.9273 8.25533L7.82066 2.59325L2.08185 8.22102C1.71398 8.56417 1.16217 8.56417 0.794298 8.22102C0.610362 8.04944 0.5 7.80923 0.5 7.60334V7.63765Z" fill="#B3B6C0"/>
                <path d="M0.5 14.6377C0.5 14.4318 0.573574 14.2259 0.75751 14.0543L7.15849 7.77452C7.15849 7.77452 7.56315 7.5 7.82066 7.5C8.07817 7.5 8.2989 7.60295 8.48283 7.74021L15.2517 14.02C15.5828 14.3631 15.5828 14.9122 15.2517 15.2553C14.9206 15.5985 14.2952 15.5642 13.9273 15.2553L7.82066 9.59325L2.08185 15.221C1.71398 15.5642 1.16217 15.5642 0.794298 15.221C0.610362 15.0494 0.5 14.8092 0.5 14.6033V14.6377Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (hisData.idx!=='' && hisData.order!==0)}" @click="moveUp()">
              <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M0.5 7.13765C0.5 6.93176 0.573574 6.72586 0.75751 6.55429L7.15849 0.274525C7.15849 0.274525 7.56315 0 7.82066 0C8.07817 0 8.2989 0.102946 8.48283 0.240209L15.2517 6.51997C15.5828 6.86313 15.5828 7.41218 15.2517 7.75533C14.9206 8.09849 14.2952 8.06417 13.9273 7.75533L7.82066 2.09325L2.08185 7.72102C1.71398 8.06417 1.16217 8.06417 0.794298 7.72102C0.610362 7.54944 0.5 7.30923 0.5 7.10334V7.13765Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (hisData.idx!=='' && hisData.order!==hisList.length-1)}" @click="moveDown()">
              <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0.862349C0.5 1.06824 0.573574 1.27414 0.75751 1.44571L7.15849 7.72548C7.15849 7.72548 7.56315 8 7.82066 8C8.07817 8 8.2989 7.89705 8.48283 7.75979L15.2517 1.48003C15.5828 1.13687 15.5828 0.587824 15.2517 0.244668C14.9206 -0.0984883 14.2952 -0.0641727 13.9273 0.244668L7.82066 5.90675L2.08185 0.278984C1.71398 -0.0641727 1.16217 -0.0641727 0.794298 0.278984C0.610362 0.450562 0.5 0.690771 0.5 0.896665V0.862349Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (hisData.idx!=='' && hisData.order!==hisList.length-1)}" @click="moveBottom()">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 8.36235C0.5 8.56824 0.573574 8.77414 0.75751 8.94571L7.15849 15.2255C7.15849 15.2255 7.56315 15.5 7.82066 15.5C8.07817 15.5 8.2989 15.3971 8.48283 15.2598L15.2517 8.98003C15.5828 8.63687 15.5828 8.08782 15.2517 7.74467C14.9206 7.40151 14.2952 7.43583 13.9273 7.74467L7.82066 13.4067L2.08185 7.77898C1.71398 7.43583 1.16217 7.43583 0.794298 7.77898C0.610362 7.95056 0.5 8.19077 0.5 8.39666V8.36235Z" fill="#B3B6C0"/>
                <path d="M0.5 1.36235C0.5 1.56824 0.573574 1.77414 0.75751 1.94571L7.15849 8.22548C7.15849 8.22548 7.56315 8.5 7.82066 8.5C8.07817 8.5 8.2989 8.39705 8.48283 8.25979L15.2517 1.98003C15.5828 1.63687 15.5828 1.08782 15.2517 0.744668C14.9206 0.401512 14.2952 0.435827 13.9273 0.744668L7.82066 6.40675L2.08185 0.778984C1.71398 0.435827 1.16217 0.435827 0.794298 0.778984C0.610362 0.950562 0.5 1.19077 0.5 1.39666V1.36235Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray" :class="{'delete': hisData.idx!==''}" @click="showDelPopup()">
              <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_109_383)">
                <path d="M11.5298 2.41394H9.2163V0.893347C9.2163 0.399155 8.82132 0 8.33229 0H3.66771C3.17868 0 2.7837 0.399155 2.7837 0.893347V2.41394H0.451411C0.206897 2.41394 0 2.62302 0 2.87012V3.72545C0 3.97254 0.206897 4.18163 0.451411 4.18163H11.5298C11.7743 4.18163 11.9812 3.97254 11.9812 3.72545V2.87012C11.9812 2.62302 11.7743 2.41394 11.5298 2.41394ZM4.0815 1.33052H7.89969V2.28089H4.0815V1.33052Z" fill="#B3B6C0"/>
                <path d="M11.3229 4.99902H0.67712C0.319754 4.99902 0.0376219 5.24612 0.0564307 5.55024L1.279 17.5249C1.29781 17.791 1.56113 18.0001 1.89969 18.0001H10.1191C10.4389 18.0001 10.7022 17.791 10.7398 17.5249L11.9436 5.55024C11.9624 5.26513 11.6803 4.99902 11.3229 4.99902ZM3.46082 16.0043H3.44201C3.27273 16.0043 3.12226 15.8713 3.12226 15.7002L2.59562 6.89976C2.59562 6.70969 2.72728 6.55763 2.89656 6.53862C3.10345 6.53862 3.23511 6.67167 3.25392 6.84274L3.78057 15.6432C3.78057 15.8332 3.64891 15.9853 3.47963 16.0043H3.46082ZM6.33856 15.6812C6.33856 15.8713 6.18809 16.0043 6.01881 16.0043C5.84953 16.0043 5.69906 15.8522 5.69906 15.6812V6.88075C5.69906 6.69068 5.84953 6.55763 6.01881 6.55763C6.18809 6.55763 6.33856 6.70969 6.33856 6.88075V15.6812ZM9.10345 15.6812C9.10345 15.8522 8.95298 16.0043 8.7837 16.0043C8.59562 16.0043 8.46395 15.8332 8.46395 15.6622L8.85894 6.86175C8.85894 6.67167 9.00941 6.51961 9.1975 6.53862C9.38558 6.53862 9.51725 6.70969 9.51725 6.88075L9.12226 15.6812H9.10345Z" fill="#B3B6C0"/>
                </g>
                <defs>
                <clipPath id="clip0_109_383">
                <rect width="12" height="18" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
          </div> -->
        </div>
        <div class="contents-box">
          <div class="table-header ds-flex">
            <div class="table-th" style="width: 100%">연혁 내용</div> 
          </div>
          <div class="table-body">
            <div v-for="list in hisList" :key="list" class="ds-flex td-box" @click="editHisData(list); setRadioBtn(list, !list.checked)"> 
              <div class="table-td radio-box">
                <img v-if="list.checked" src="@/assets/admin/radio_btn.png">
                <img v-else src="@/assets/admin/radio_default.svg">
              </div>
              <div class="table-td contents" :class="{'bold' : list.bold}">{{ list.contents }}</div> 
            </div>
          </div>
        </div>
        <div class="edit-his-box ds-flex center-between">
          <h3 class="item-title">연혁 추가</h3>
          <input v-model="hisData.category" type="text" class="input-box input year" :class="{'filled' : hisData.category.length>0}" placeholder="연도 입력" @input="checkInput()">
          <input v-model="hisData.contents" type="text" class="input-box input contents" :class="[{'filled' : hisData.contents.length>0}, {'bold' : hisData.bold}]" placeholder="연혁 내용 입력" @input="checkInput()">
          <div class="btn bold" :class="{'active' : hisData.bold}" @click="hisData.bold = !hisData.bold">
            <svg v-if="hisData.bold" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.970001" y="0.964233" width="12" height="12" rx="2" fill="#00ACEB"/>
              <path d="M6.33314 9.46423C6.17093 9.46423 6.00873 9.39493 5.9114 9.27613L4.08387 7.12778C3.90003 6.90998 3.94329 6.60307 4.18119 6.43477C4.41909 6.26646 4.75432 6.30607 4.93816 6.52387L6.31151 8.1376L8.98253 4.67253C9.15555 4.45473 9.49078 4.39532 9.7395 4.55373C9.98821 4.71213 10.0423 5.01904 9.86926 5.24674L6.76569 9.25633C6.66837 9.38503 6.50616 9.46423 6.33314 9.46423H6.32233H6.33314Z" fill="white"/>
            </svg>
            <img v-else src="@/assets/admin/unchecked.png">
            강조(굵게 + 밑줄)
          </div>
          <div class="btn gray add" :class="{'active' : hisData.isFilled}" @click="addList()">{{ msg }}</div>
        </div>

        <div v-if="showPopup.delete" class="popup-wrapper">
            <div class="popup-box ds-flex center-center">
                <img src="@/assets/login/important_icon.png">
                <div class="popup-text-box ds-flex center-center">
                  <h3 class="ds-flex center-center">해당 연혁 내용을 삭제하시겠습니까?</h3>
                  <p class=" ds-flex center-center">삭제할 경우, 연혁 내용 복원이 불가합니다.</p>
                </div>
                <div class="blue-btn red-btn" @click="deleteList();">삭제</div>
                <div class="blue-btn red-btn" @click="showPopup.delete = false;">취소</div>
            </div>
        </div>
        <div v-if="showPopup.complete" class="popup-wrapper">
            <div class="popup-box ds-flex center-center complete">
                <img src="@/assets/admin/pop_check_icon.png">
                <div class="popup-text-box ds-flex center-center">
                  <h3 class="ds-flex center-center" style="margin: 0">리스트가 삭제되었습니다.</h3>
                </div>
                <div class="blue-btn red-btn" @click="showPopup.complete = false; this.$router.go();">확인</div>
            </div>
        </div>
    </div>
</template>

<script>
// import Pagenation from "../Pagenation.vue";
import axios from "axios"
import {API} from "../../api.js";

export default {
  name: 'HistoryAd',
  components: {
    // Pagenation
  },
  data: () => ({
    showPopup: {
      delete: false,
      complete: false
    },
    upload: {
        server: '',
        dir: 'upload',
        menu: 'history',
    },
    comboBox:{ 
        isopen: false,
        style: {},
        origin: [], // 기존 순서
        option: [], // 사용자가 선택한 옵션에 따라 순서가 바뀜
        selected: '', // 사용자가 선택한 옵션
        fill: '#14141C', // 화살표 색상
    },
    hisList: [
      {
        year: '2022',
        checked: false,
        contents: 'asd'
      },
      {
        year: '2022',
        checked: false,
        contents: 'asd'
      }
    ],
    hisData: {
      idx: '',
      category: '',
      contents: '',
      bold: false,
      isFilled: false,
      order: '', // 정렬 순서
    },
    msg: '추가',
    selectedYear: ''
  }),
  methods: {
    setRadioBtn(target, bool) {
      if(bool) {
        let list = this.hisList;
        for(let i=0; i<list.length; i++) {
          list[i].checked = false;
        }
      } else {
        this.initData();
      }
      target.checked = bool;        
    },
    editHisData(list) {
      let data = this.hisData;
      data.idx = list.idx;
      data.category = list.category;
      data.contents = list.contents;
      data.bold = list.bold;
      data.isFilled = true;
      data.order = list.order;
      this.msg = '수정';
    },
    initComb() {
      let comb = this.comboBox;
      let width = window.innerWidth;
      if(width >= 1440) {
        comb.style = {height: '40px'};
      } else {
        comb.style = {height: '25px'};
      }
      comb.fill = '#14141C';
      comb.isopen = false;

      let arrow = document.getElementById('arrow');
      arrow.style.transform = `rotate(360deg)`;

      this.getYear();
    },
    initData() {
      let data = this.hisData;
      data.idx = '';
      data.category = '';
      data.contents = '';
      data.bold = false;
      data.isFilled = false;

      this.msg = '추가';
    },
    // 연도 리스트를 가져온다.
    getYear() {
      let singleton = this;
      API.getYear(function(status, res){
        if(res.data.length > 0) {
          let comb = singleton.comboBox;
          for(let i=0; i<res.data.length; i++) {
            comb.origin[i] = res.data[i].category;
            if(i === res.data.length-1) {
              if(singleton.selectedYear !== '') {
                comb.selected = singleton.selectedYear;
              } else {
                comb.selected = comb.origin[0];
              }
              singleton.setOption();
            }
          }
        }
      })
    },
    setOption() {
      let comb = this.comboBox;
      comb.option = [];
      comb.option.push(comb.selected);
      for(let i=0; i<comb.origin.length; i++) {
        if(comb.origin[i] !== comb.selected) {
          comb.option.push(comb.origin[i]);
          }
      }
      this.getHisList();
    },
    // 해당 연도의 전체 리스트를 가져온다.
    async getHisList() {
      this.hisList = [];
      let singleton = this;
      let param = {
        table: this.upload.menu,
        idx: '',
        option: this.comboBox.selected
      }
      API.getList(param, function(status, res){
        let list = singleton.hisList;
        for(let i=0; i<res.data.length; i++) {
            list.push(res.data[i]);
            if(list[i].bold === 0) {
              list[i].bold = false;
            } else {
              list[i].bold = true;
            }
            list[i].checked = false;
            list[i].order = i;
        }
      });
    },
    async getSeq() { // 정렬 순서를 얻어온다.
      return axios(this.upload.server + '/get/seq', {
            method: 'GET',
            data: '',
            headers : {},
            params: {'table': this.upload.menu, 'option': this.hisData.category}
        }).then(response => {
            if (response.data) {
                return response.data[0].seq;
            }
            // throw new Error('Server or network error');
        }).catch( () => {
            console.log('error..');
            return false;
        });
    },
    async addList() {
      // let singleton = this;
      let data = this.hisData;
      if(data.bold) { data.bold = 1 }
      else { data.bold = 0 }
      if(data.isFilled) {
        this.selectedYear = data.category;
        if(this.msg === '추가') {
          data.order = await this.getSeq();
          API.insertHisList(data, function(status, res){
              if(status==='error'){
                  console.log('error', res);
              }
          })
        } else {
          API.updateHisList(data, function(status, res){
              if(status==='error'){
                  console.log('error', res);
              }
          })
        }
        this.initData();
        this.initComb();
      }
    },
    showDelPopup() {
      if(this.hisData.isFilled) {
        this.showPopup.delete = true;
      }
    },
    // 선택한 리스트를 삭제한다.
    async deleteList() {
      let data = this.hisData;

      if(data.idx !== '') {
        this.showPopup.delete=true;
        await axios(this.upload.server + '/delete/list', {
          method: 'POST',
          data: '',
          headers : {},
          params: {'table': this.upload.menu, 'idx': data.idx}
        }).then(response => {
          this.showPopup.delete = false;
          this.showPopup.complete = true;
          if (response.data) {
              console.log('success', response.data);
          }
        }).catch( () => {
          console.log('error..');
          return false;
        });
      }
    },
    setComBox(selected) {
        let comb = this.comboBox;
        let option = document.getElementsByClassName('option');
        let arrow = document.getElementById('arrow');
        let width = window.innerWidth;
        
        if(comb.isopen) {
            for(let i=0; i<option.length; i++) {
                option[i].classList.remove('hover', 'active');
            }

            // if(width >= 1920) {
            //   comb.style = {height: '40px'};
            // } else if(width >= 1200 ){
            //   comb.style = {height: '25px'};
            // } else if(width >= 768 ){
            //   comb.style = {height: '40px'};
            // }
            if(width >= 1440) {
              comb.style = {height: '40px'};
            } else {
              comb.style = {height: '25px'};
            }
            comb.fill = '#14141C';
            comb.selected = selected;
            arrow.style.transform = `rotate(360deg)`
            this.setOption();
            this.initData();
        } else {
            for(let i=0; i<option.length; i++) {
                if(i===0) { option[i].classList.add('hover', 'active'); } // comboBox가 열려있을 경우에만 hover css 동작하도록
                else { option[i].classList.add('hover'); }
            }
            // if(width >= 1920) {
            //   comb.style = {height: (comb.origin.length*40)+'px', 'border-color': '#00ACEB'};
            // } else if(width >= 1200 ){
            //   comb.style = {height: (comb.origin.length*25)+'px', 'border-color': '#00ACEB'};
            // } else if(width >= 768 ){
            //   comb.style = {height: (comb.origin.length*40)+'px', 'border-color': '#00ACEB'};
            // }
            if(width >= 1440) {
              comb.style = {height: (comb.origin.length*40)+'px', 'border-color': '#00ACEB'};
            } else {
              comb.style = {height: (comb.origin.length*25)+'px', 'border-color': '#00ACEB'};
            }
            comb.fill = '#00ACEB';
            arrow.style.transform = `rotate(180deg)`
        }
        comb.isopen = !comb.isopen;
    },
    // 모든 값이 입력되었는지 확인한다.
    checkInput() {
        for(let key in this.hisData){
          if(key !== 'idx' && key !== 'order' && this.hisData[key] === '') {
              this.hisData.isFilled = false;
              break;
          } else {
              this.hisData.isFilled = true;
          }
        }
    },
    moveTop(){ // 최상단으로 이동
      let data = this.hisData;
      let list = this.hisList;
      if(data.order !== 0) {
        for(let i=0; i<data.order; i++) {
          list[i].order++;
        }
        list[data.order].order = 0;
        this.updateList();
      }
    },
    moveUp() { // 위로 이동
      let data = this.hisData;
      let list = this.hisList;
      if(data.order !== 0) {
        list[data.order].order--;
        list[data.order-1].order++;
        this.updateList();
      }
    },
    moveDown() { // 아래로 이동
      let data = this.hisData;
      let list = this.hisList;
      if(data.order !== list.length-1) {
        list[data.order].order++;
        list[data.order+1].order--;
        this.updateList();
      }
    },
    moveBottom() { // 최하단으로 이동
      let data = this.hisData;
      let list = this.hisList;
      if(data.order !== list.length-1) {
         for(let i=data.order+1; i<list.length; i++) {
          list[i].order--;
        }
        list[data.order].order = list.length-1;
        this.updateList();
      }
    },
    async updateList() {
      let list = this.hisList;
      for(let i=0; i<list.length; i++) {
        if(list[i].bold) { list[i].bold = 1 }
        else { list[i].bold = 0 }
        await axios(this.upload.server + '/update/history', {
            method: 'POST',
            params: {
              'idx': list[i].idx,
              'category': list[i].category,
              'contents': list[i].contents,
              'bold': list[i].bold,
              'seq': list[i].order,
            },
            headers : {'Content-type' : 'multipart/form-data' }
        }).then(res => {
          if(res.data) {
            //
          }
        }).catch( () => {
            console.log('error..');
            return false;
        });
      }
      this.selectedYear = this.hisData.category;
      this.initData();
      this.getYear();
    },
    movePage(name) {
      this.$router.push({name: name});
    },
    handleResize() {
      let width = window.innerWidth;
      let comb = this.comboBox;
      if(!comb.isopen) {
        if(width >= 1440) { // 1400px 이상
          comb.style = {height: '40px'};
        } else { // 1200px 이상
          comb.style = {height: '25px'};
        }
      } else {
        if(width >= 1440) { // 1400px 이상
          comb.style = {height: (comb.origin.length*40)+'px', 'border-color': '#00ACEB'};
        } else { // 1200px 이상
          comb.style = {height: (comb.origin.length*25)+'px', 'border-color': '#00ACEB'};
        }
      }
    }
  },
  mounted() {
    window.scrollTo(0,0);
    this.getYear();

    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
  }
}
</script>

<style scoped>
.history-wrapper {
  /* min-height: 1080px; */
}

.title-box {
  position: relative;
  width: 100%;
  height: 70px;
  padding: 15px 20px 15px 30px;
  box-sizing: border-box;
  align-items: center;
}

.tab-title {
  color: #14141C;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.count-box {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
  margin-left: 15px;
}

.blue-highlight {
  color: #00ACEB;
  font-weight: 600;
  margin-left: 3px;
}

.mobile-btn-box {
  display: none;
}

.contents-box {
  padding: 0 20px;  
}

.table-header {
  width: 100%;
  height: 40px;
  background-color: #555861;
}

.table-th {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-right: 1px solid #fff;
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.table-th:last-child {
  border-right: none;
}

.table-th.title {
  width: calc(100% - 400px);
}

.table-td {
  cursor: pointer;
}

.table-td.radio-box {
  width: 40px;
  justify-content: center;
}

.radio-box > img {
  margin: 0;
}

.table-td.contents {
  width: calc(100% - 40px);
}

.table-td.bold {
  font-weight: 600;
  text-decoration: underline;
}

.table-body {
  padding-top: 10px;
}

.td-box {
   border: 1px solid #B3B6C0;
   margin-bottom: 10px;
}

.td-box:last-child {
  margin-bottom: 0;
}

.table-td {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  background-color: #fff;
  box-sizing: border-box;
}

.table-td.title {
  width: calc(100% - 400px);
  justify-content: flex-start;
  padding-left: 13px;
}

.no-data-box {
  width: 100%;
  height: 880px;
  background-color: #F2F2F5;
  border: 1px solid #B3B6C0;
  color: #707070;
  font-size: 15px;
  box-sizing: border-box;
}

.btn {
  width: 110px;
  height: 40px;
  border-radius: 5px;
  background-color: #F2F2F5;
  border: 1px solid;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  margin-right: 5px;
  font-weight: 600;
  box-sizing: border-box;
}

.btn:last-child {
  margin-right: 0;
}

.btn.blue {
  border-color: #00ACEB;
  color: #00ACEB;
  cursor: pointer;
}

.btn.blue:hover {
  color: #fff;
  background-color: #00ACEB;
}

.btn.gray {
  border-color: #B3B6C0;
  color: #B3B6C0;
}

.btn.move {
  width: 175px;
  padding: 11px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn.bold {
  border-color: #B3B6C0;
  color: #B3B6C0;
  cursor: pointer;
  width: 175px;
  margin: 0;
}

.btn.bold > svg, img {
  margin-right: 10px;
}

.btn.bold:hover path {
  transition: .3s;
  fill: #00ACEB;
}

.btn.bold:hover rect {
  fill: #fff;
}

.btn.delete {
  cursor: pointer;
  color: #FF3E3E;
  border-color: #FF3E3E;
  cursor: pointer;
}

.btn.delete:hover {
  background-color: #FF3E3E;
  color: #fff;
}

.btn.active {
    border-color: #00ACEB;
    color: #00ACEB;
    cursor: pointer;
}

.btn.active:hover {
    background-color: #00ACEB;
    color: #fff;
}

.btn.gray.active path {
  transition: .3s;
  fill: #00ACEB;
}

.btn.gray.active:hover path {
  transition: .3s;
  fill: #fff;
}

.btn.gray.active:hover {
  background-color: #00ACEB;
  color: #fff;
}

.bottom-box {
  position: relative;
  height: 80px;
  padding: 20px;
  box-sizing: border-box;
}

.input-box {
    /* width: 100%; */
    height: 40px;
    border: 1px solid #B3B6C0;
    border-radius: 5px;
    background-color: #F2F2F5;
    box-sizing: border-box;
    transition: .3s;
    color: #14141c;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* font-weight: 600; */
}

.input-box:hover {
    border-color: #00ACEB;
}

.input-box:focus {
    border-color: #00ACEB !important;
}

.input-box.input {
    padding: 11px 15px;
}

.input-box.text {
    border: none;
    border-bottom: 1px solid #14141C;
    border-radius: 0px;
    background-color: transparent;
}

.input-box.select {
    width: 140px;
    position: absolute;
    transition: .3s;
    overflow: hidden;
    cursor: pointer;
    z-index: 5;
    left: 80px;
    top: 15px;
    border: 1.5px solid #14141C;
}

.input-box.select.active {
    height: 80px;
}

.input-box.select:hover path {
    fill: #00ACEB;
    transition: .3s;
}

.input-box.select .option {
    height: 40px;
    padding: 0 15px;
    transition: .3s;
    font-weight: 500;
}

.input-box.select .option:hover {
    color: #00ACEB;
    background-color: #E3E4EA;
}

.input-box.filled {
    border-color: #14141C;
}

.input-box.bold {
  font-weight: 600;
  text-decoration: underline;
}

.input-box.year {
  width: 115px;
  height: 40px;
}

.input-box.contents {
  width: calc(100% - 500px);
  height: 40px;
}

.popup-wrapper {
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: rgba(14, 14, 19, 0.7);
  position: absolute;
  top: 0;
  left: 0;
}

.popup-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  height: 200px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 29px 0;
}

.popup-box h3 {
  width: 100%;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  height: 18px;
  align-content: center;
  margin-bottom: 10px;
}

.popup-box p {
    font-size: 12px;
    color: #000000;
    height: 14px;
    width: 100%;
    align-content: center;
}
 
.popup-box .blue-btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    margin: 0 5px;
}

.popup-text-box {
    width: 100%;
    height: 82px;
    align-content: center;
}

.red-btn {
    background-color: #fff;
    color: #FF3E3E;
    border: 1px solid #FF3E3E;
    font-weight: 600;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
}

.red-btn:hover {
    background-color: #FF3E3E;
    color: #fff;
}

.complete .red-btn {
  background-color: #FF3E3E;
  color: #fff;
}

.complete .red-btn:hover {
  background-color: #D02727;
}

.edit-his-box {
  position: absolute;
  width: calc(100% - 360px);
  height: 85px;
  background-color: #E3E4EA;
  bottom: 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.item-title {
    color: #14141C;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: left;
}

@media screen and (max-width: 1439px) {
    .contents-wrapper {
        width: calc(100% - 270px);
    }

    .contents-box {
      padding: 0 15px;
    }

    .title-box {
      padding: 7px 15px 7px 20px;
      height: 40px;
      align-content: center;
    }

    .table-body {
      padding-top: 5px;
    }

    .tab-title {
      font-size: 14px;
      line-height: 17px;
    }

    .count-box {
      font-size: 11px ;
    }

    .table-header {
      height: 30px;
    }

    .check-box {
      width: 30px;
      height: 30px;
    }

    .check-box > img {
      width: 10px;
      height: 10px;
    }

    .table-th {
      height: 30px;
      font-size: 11px;
    }

    .table-td {
      height: 30px;
      font-size: 11px;
    }

    .no-data-box {
      font-size: 11px;
      height: 550px;
    }

    .btn {
      width: 80px;
      height: 25px;
      font-size: 11px;
      border-radius: 3px;
      margin-right: 5px;
    }

    .bottom-box {
      height: 50px;
      padding: 13px;
    }

    .table-td-box {
      width: calc(100% - 30px);
    }

    .table-td.contents {
      width: calc(100% - 30px);
    }

    .btn.move {
      width: 120px;
      padding: 5px 9px;
    }

    .btn.move > svg {
      width: 15px;
      height: 15px;
    }

    .btn.bold {
      width: 110px;
      padding: 0 7px;
      box-sizing: border-box;
    }

    .btn.bold > svg, img {
      margin-right: 5px;
      /* width: 12px; */
    }

    .input-box.input {
      padding: 5px 10px;
      font-size: 11px; 
      border-radius: 3px;
    }

    .input-box.select {
      top: 7px;
      left: 55px;
      width: 85px;
      height: 25px;
      border-radius: 3px;
    }

    .input-box.select .option {
      height: 25px;
      font-size: 11px;
      padding: 0 10px;
      line-height: 13px;
    }

    .input-box.year {
      width: 80px;
      height: 25px;
    }

    .input-box.contents {
      width: calc(100% - 340px);
      height: 25px;
    }

    #arrow {
      width: 10px;
    }

    .table-td.radio-box {
      width: 30px;
    }

    .radio-box > img {
      width: 10px;
      height: 10px;
      margin: 0;
    }

    .edit-his-box {
      width: calc(100% - 270px);
      height: 50px;
    }

    .item-title {
      font-size: 13px;
    }
}

/* @media screen and (max-width: 1030px) {
  .tab-title {
    font-size: 18px;
  }

  .title-box {
    padding: 20px 15px 20px 25px;
    height: 60px;
  }

  .contents-wrapper {
    width: 100%;
    height: calc(100vh - 60px);
    padding-top: 60px;
  }

  .table-header {
    height: 50px;
  }

  .table-th {
    height: 50px;
    font-size: 16px;
  }

  .edit-his-box {
    width: 100%;
  }

  .input-box.select {
    width: 120px;
    height: 40px;
    left: 67px;
    top: 10px;
  }

  #arrow {
    width: 15px;
    height: 8px;
  }

  .table-td.radio-box {
    width: 50px;
    height: 50px;
  }
  
  .radio-box > img {
    width: 16px;
    height: 16px;
  }

  .table-body {
    padding-top: 10px;
  }

  .table-td.contents {
    width: calc(100% - 50px);
  }

  .table-td {
    height: 50px;
    font-size: 16px;
  }

  .btn-box {
    display: none;
  }

  .btn.gray {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1.5px solid #B3B6C0;
  }

  .btn.gray:last-child {
    margin: 0;
  }

  .mobile-btn-box {
    width: 294px;
    display: flex;
    justify-content: space-between;
  }

  .mobile-btn-box > p {
    font-size: 14px;
    font-weight: 700;
    color: #14141C;
    margin-right: 2px;
  }

  .edit-his-box {
    height: 60px;
  }

  .input-box.input {
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }

  .input-box.select .option {
    height: 40px;
    font-size: 14px;
  }

  .input-box.year {
    width: 110px;
  }

  .btn.bold {
    width: 145px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }

  .item-title {
    font-size: 17px;
  }

  .btn.add {
    width: 80px;
    height: 40px;
    border: 1px solid #B3B6C0;
    font-size: 14px;
    font-weight: 600;
  }

  .btn.add.active {
    border-color: #00ACEB;
  }

  .input-box.contents {
    width: calc(100% - 430px);
  }

  .edit-his-box {
    padding: 0 15px;
  }
} */
</style>

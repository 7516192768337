<template>
    <div class="newsroom-wrapper">
        <div class="newsroom-list-box">
            <div class="ds-flex center-between" style="padding-bottom: 20px">
                <p class="count-box ds-flex center-start">총 <span class="blue-highlight">{{ corrList.length }}개</span>의 뉴스가 있습니다.</p>
                <div class="search-box ds-flex center-between">
                    <input type="text" placeholder="검색어를 입력해주세요" v-model="searchText" @keyup.enter="searchList()">
                    <img src="@/assets/news/search.png" @click="searchList()" />
                </div>
            </div>
            <div class="newsroom-list ds-flex start-start">
                <div v-for="news in currPageList" :key="news" class="newsroom-box" @click="openDetailPage(news)">
                    <div class="newsroom-img-box">
                        <img :src="server+news.thumbnail">
                    </div>
                    <div class="newsroom-title-box ds-flex start-">
                        <p class="title">{{news.title}}</p>
                        <p class="date">{{news.selected_date}}</p>
                    </div>
                </div>
            </div>
            <div class="bottom-box ds-flex center-center">
                <pagenation ref="pagenation" @page="changePage"></pagenation>
                <!-- <button @click="openWritePage()">글 작성</button> -->
                
            </div>
        </div>
    </div>
</template>

<script>
import Pagenation from "../Pagenation.vue";
import {API} from "../../api.js";
import moment from "moment";

export default {
  name: 'Newsroom',
  components: {
    Pagenation
  },
  data: () => ({
    server: '',
    newsList: [], // 전체 뉴스 리스트
    corrList: [], // 선택한 연도에 해당되는 리스트
    currPageList: [], // 현재 페이지에서 보여주고 있는 리스트
    currPage: 1,
    currTab: 'All',
    searchText: ''
  }),
  methods: {
    // 검색 결과에 맞는 리스트를 설정한다.
    searchList() {
        let list = this.newsList;
        let txt = (this.searchText).toUpperCase();

        this.currPage = 1;
        this.corrList = [];

         for(let i=0; i<list.length; i++) {
            let title = (list[i].title).toUpperCase();
            if(title.includes(txt)) {
                this.corrList.push(list[i]);
            }
            if(i===list.length-1) {
                this.$refs.pagenation.setSize(this.corrList.length, 12);
                this.setCurrList();
            }
        }
    },
    // 선택한 연도에 맞게 corrList를 설정한다.
    setList(year) {
        let list = this.newsList;
        this.currTab = year;
        this.currPage = 1;
        this.corrList = [];

        if(year !== 'All')
         for(let i=0; i<list.length; i++) {
            if(list[i].year === year) {
                this.corrList.push(list[i]);
            }

            if(i===list.length-1) {
                this.$refs.pagenation.setSize(this.corrList.length, 12);
                this.setCurrList();
            }
        } else {
            this.corrList = this.newsList;
            this.$refs.pagenation.setSize(this.corrList.length, 12);
            this.setCurrList();
        }
    },
    // 전체 뉴스 리스트를 가져온다.
    getNewsList() {
        let singleton = this;
        API.getNewsList('News', function(status, res){
            if(res.data.length > 0) {
                // console.log('res >> ', res.data);
                for(let i=0; i<res.data.length; i++) {
                    singleton.newsList.push(res.data[i]);
                    singleton.newsList[i].date = moment(singleton.newsList[i].date).format('YYYY-MM-DD')
                    singleton.newsList[i].selected_date = moment(singleton.newsList[i].selected_date).format('YYYY-MM-DD')

                    if(i === res.data.length-1) {
                        singleton.$refs.pagenation.setSize(singleton.newsList.length, 12);
                        singleton.corrList = singleton.newsList;
                        singleton.setCurrList();
                    }
                }
            }
        })
    },
    openWritePage() {
        this.$router.push({
            name:'NewsWriteView',
        });
    },
    openDetailPage(news) {
        if(news.exlink===1 && news.url !== '') {
            window.open(news.url);
        } else if(news.exlink===0) {
            if(news.contents.length > 11) { // 내용이 없는 뉴스는 기본 길이가 11임
                this.$router.push({
                    name:'NewsDetailView',
                    query: {idx: news.idx}
                });
            }
        }
    },
    // 현재 페이지에 보여질 리스트 set
    setCurrList() {
      this.currPageList = [];
      var range = this.currPage * 12;

      for (let i = range - 12; i < range; i++) {
        if (this.corrList[i] !== undefined) {
          this.currPageList.push(this.corrList[i]);
        }
      }
    },
    // 페이지 이동 버튼 클릭 시 동작
    changePage(page) {
      window.scrollTo(0,0);
      this.currPage = page;
      this.setCurrList();
    },
  },
  mounted() {
    this.getNewsList();
  },
  created() {
  }
}
</script>

<style scoped>
.newsroom-wrapper {
    color: #191E2C;
    border-top: 1.5px solid #00ACEB;
    padding: 80px 0;
}

.newsroom-title{
    padding-left: 120px;
}

.newsroom-title h1 {
  font-size: 45px;
  font-weight: bold;
  padding-right: 24px;
  padding-left: 50px;
}

.newsroom-title p {
  font-size: 20px;
  font-weight: bold;
}

.newsroom-wrapper .newsroom-title h1 {
    width: 100%;
    text-align: left;
    padding-left: 0;
}

.newsroom-title p {
    color: #505050;
}

.top-box {
    position: relative;
    padding-top: 250px;
    border-bottom: 1.5px solid #DDDDDD;
    overflow: hidden;
    height: 445px;
    box-sizing: border-box;
}

.top-box-bg {
    position: absolute;
    bottom: -130px;
    right: 120px;
}

.tab-box {
    margin: 70px 0 0 70px;
    box-sizing: border-box;
}

.year {
    font-weight: bold;
    color: #A3A3A3;
    font-size: 20px;
    width: 170px;
    border-right: 2px solid #A3A3A3;
}

.year.last {
    border-right: none;
}

.tab {
    width: 170px;
    cursor: pointer;
}

.tab > .blue-box {
    width: 74px;
    height: 4px;
    background-color: #191E2C;
    margin-top: 20px;
    transition: .5s;
    opacity: 0;
}

.tab > .blue-box.active {
    opacity: 1;
}

.newsroom-wrapper .newsroom-list-box {
    width: 1240px;
    margin: auto;
    box-sizing: border-box;
}

.news-count {
    padding: 45px 0;
    text-align: left;
    color: #505050;
    font-size: 18px;
    font-weight: 500;
}

.newsroom-list-box .newsroom-box {
  width: 400px;
  height: 370px;
  padding: 0;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px #8b8b8b29;
  margin: 0 20px 20px 0;
}

.newsroom-list-box .newsroom-box:nth-child(3n) {
  margin-right: 0;
}

.newsroom-list-box .newsroom-box:hover {
    transition: .3s;
    box-shadow: 10px 10px 5px #d3d9e3;
}

.newsroom-list-box .newsroom-img-box {
  width: 100%;
  background-color: #DDDDDD;
  height: 250px;
}

.newsroom-list-box .newsroom-img-box > img {
    width: 100%;
    height: 100%;
}

.newsroom-list-box .newsroom-title-box {
    width: 100%;
    height: 120px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 35px 30px;
    align-content: space-between;
    border-top: 1px solid #ddd;
} 

.newsroom-list-box .newsroom-title-box .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #1E1E1E;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.newsroom-list-box .newsroom-title-box .date {
    font-size: 14px;
    line-height: 14px;
    color: #979CA8;
}

.search-box {
    width: 400px;
    height: 40px;
    border: 1px solid #DDDDDD;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px 15px;
}

.search-box > input {
    border: none;
    height: 100%;
    width: 87%
}

.search-box > img {
    width: 17.5px;
    height: 17.5px;
    cursor: pointer;
}

.bottom-box {
    /* padding: 55px 0 80px 0; */
    max-width: 1365px;
    padding-top: 40px;
}

.count-box {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
}

.blue-highlight {
  color: #00ACEB;
  font-weight: 600;
  margin-left: 3px;
}

.newsroom-list {
    min-height: calc(100vh - 1049px);
}

@media screen and (max-width: 1240px) {
    .newsroom-wrapper {
        padding: 40px 0;
    }

    .newsroom-wrapper .newsroom-list-box {
        width: 768px;
    }

    .newsroom-list-box .newsroom-box {
        width: 246px;
        height: 227px;
        margin: 0 15px 15px 0;
    }

    .newsroom-list-box .newsroom-title-box {
        padding: 0 20px;
        height: 74px;
        align-content: center;
    }

    .newsroom-list-box .newsroom-img-box {
        height: 153px;
    }

    .newsroom-list-box .newsroom-img-box > img {
        width: 246px;
    }

    .newsroom-list-box .newsroom-title-box .title {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .newsroom-list-box .newsroom-title-box .date {
        font-size: 10px;
    }

    .search-box {
        width: 246px;
        height: 30px;
        font-size: 11px;
        padding: 0 15px ;
    }

    .search-box > img {
        width: 12px;
        height: 12px;
    }

    .count-box {
        font-size: 12px;
    }

    .newsroom-list {
        min-height: calc(100vh - 751.5px);
    }
}

@media screen and (max-width: 768px) {
  .newsroom-wrapper {
    border-top: none;
    padding: 0 40px;
  }

  .newsroom-wrapper .newsroom-list-box {
    width: 100%;
  }

  .newsroom-list-box .newsroom-box {
    width: 220px;
    height: 203px;
    margin: 0 14px 14px 0;
  }

  .newsroom-list-box .newsroom-img-box > img {
    width: 100%;
  }

  .newsroom-list-box .newsroom-img-box {
    height: 137px;
  }

  .newsroom-list-box .newsroom-title-box {
    height: 66px;
    padding: 0 15px;
  }

  .newsroom-list-box .newsroom-title-box .title {
    font-size: 11px;
    margin-bottom: 0px;
  }

  .bottom-box {
    padding: 30px 0 40px 0;
  }

  .newsroom-list {
    min-height: calc(100vh - 660px);
  }
}

@media screen and (max-width: 480px) {
    .newsroom-wrapper {
        max-width: 360px;
        padding: 0;
        margin: auto;
    }

    .newsroom-list-box .newsroom-box {
        min-width: 360px;
        height: 305px;
        margin: 0 0 30px 0;
    }

    .newsroom-list-box .newsroom-box:last-child {
        margin-bottom: 0;
    }

    .newsroom-list-box .newsroom-img-box {
        height: 225px;
    }

    .newsroom-list-box .newsroom-title-box {
        height: 80px;
        padding: 0 20px;
    }

    .newsroom-list-box .newsroom-title-box .title {
        font-size: 16px;
        padding-bottom: 10px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .newsroom-list-box .newsroom-title-box .date {
        font-size: 14px;
    }

    .search-box > input {
        font-size: 12px;
    }

    .search-box {
        width: 100%;
        padding: 0 10px;
    }

    .count-box {
        font-size: 13px;
    }
}
</style>

<template>
  <div class="flex-center wd100">
    <div class="paging-wrap flex-center">
        <div @click="goStart()">
            <img src="@/assets/pagenation/pre.svg">
        </div>
        <div @click="goPrev()">
            <img src="@/assets/pagenation/pre_left.svg">
        </div>
        <div class="page-num" :class="[currPage === idx ? 'curr' : '']" v-for="idx in pageCount" :key="idx" @click="changePage(idx)">
            <p>{{idx}}</p>
        </div>
        <div @click="goNext()">
            <img src="@/assets/pagenation/next_right.svg">
        </div>
        <div @click="goEnd()">
            <img src="@/assets/pagenation/next.svg">
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
//   props: ['size'],
  data: () => ({
      size: 0, // 총 list 개수
      pageCount: 0, // 페이지 넘버 수
      listCount: 1, // 한 페이지에 보여질 리스트 개수
      currPage: 1, // 현재 위치하고 있는 페이지
  }),
  methods: {
      setSize(listSize, listCount) {
        // console.log(listSize, listCount)
        this.size = listSize;
        this.listCount = listCount;
        this.currPage = 1;
        this.calcPageCount();
      },
      calcPageCount() {
        this.pageCount = parseInt((this.size)/this.listCount);
        let remainder = this.size%this.listCount;

        if(remainder > 0) {
            this.pageCount++;
        }
      },
      changePage(idx) {
          this.$emit("page", idx);
          this.currPage = idx;
      },
      goStart() {
        this.changePage(1);
      },
      goPrev() {
        if(this.currPage > 1) {
            this.currPage--;
        }
        this.changePage(this.currPage);
      },
      goNext() {
        if(this.currPage < this.pageCount) {
            this.currPage++;
        }
        this.changePage(this.currPage);
      },
      goEnd() {
          this.changePage(this.pageCount);
      }
  },
  mounted() {
  }
};
</script>

<style scoped>
/* renewal */
div {
    font-size: 0;
}

p {
    color: #979CA8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.flex-center {
    display: flex;
}

.paging-wrap > div {
    font-size: 1.6rem;
    width: 30px;
    height: 30px;
    border: 1.5px solid #DCDCDC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 4px;
    box-sizing: border-box;
    padding-left: 1px;
    background-color: #fff;
}

.paging-wrap .curr {
    /* border: 1.5px solid #0C2E6A;
    background-color: #0C2E6A; */
    border: 1.5px solid #14141C;
    background-color: #14141C;
}

.paging-wrap .curr p {
    color: #fff;
}

.paging-wrap img {
    width: 10px;
    height: 10px;
}

@media screen and (max-width: 1439px) {
    .paging-wrap > div {
        width: 25px;
        height: 25px;
    }

    p {
        font-size: 13px;
    }

    .paging-wrap img {
        width: 8px;
        height: 8px;
    }
}

@media screen and (max-width: 1240px) {
    .paging-wrap > div {
        width: 30px;
        height: 30px;
    }

    .paging-wrap img {
        width: 8px;
        height: 8px;
    }
}

/* @media screen and (max-width: 480px) {
    .paging-wrap > div {
        width: 40px;
        height: 40px;
        margin: 0 5px;
    }

    p {
        font-size: 16px;
    }

    .paging-wrap img {
        width: 10px;
        height: 10px;
    }
} */

/* renewal */
</style>

<template>
    <div class="aicdss-wrapper">
      <div class="section-box">
        <div>
          <h1 class="main-text">AI CDSS</h1>
          <div class="info-wrapper">
            <p class="info-text ds-flex center-center">중환자 진료는<span class="bold blue">국내 의료비의 약 25%</span>를 차지합니다.</p>
            <p class="info-text ds-flex center-center">중환자실이 수도권 등 특정 지역에 편중돼 진료 서비스 격차가 큽니다.</p>
            <p class="info-text ds-flex center-center">중환자실 20% 전담 전문의 부족 / 신생아 중환자실 80% 전담 전문의가 부족합니다.</p>
          </div>
          <img src="@/assets/product/medical/aicdss/emr.png">
          <img class="arrow" src="@/assets/product/medical/ziomed/arrow.png">
          <img src="@/assets/product/medical/aicdss/critical_text.png">
          <img class="arrow" src="@/assets/product/medical/ziomed/arrow.png">
          <img src="@/assets/product/medical/aicdss/CDSS.png">
          <img class="arrow" src="@/assets/product/medical/ziomed/arrow.png">
          <div class="box-wrapper">
            <img src="@/assets/product/medical/aicdss/decision.png">
            <img src="@/assets/product/medical/aicdss/medical.png">
            <div class="info-text-box">
              <p>※ CDSS(Clinical Decision Support System : 임상 의사 결정 지원시스템)</p>
              <p>※ EMR (Electronic Medical Record, 전자의무기록)</p>
              <p>※ CRRT (Continuous Renal Replacement Therapy : 신장투석치료기)</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="section-box">
        <div>
          <div class="title-box">
            <div class="mini-blue-box"></div>
            <h3>AI CDSS 특장점</h3>
          </div>
          <div class="ds-flex center-between blue-wrapper">
            <div class="blue-box ds-flex center-center">EMR, CDW 연동 기반 의료 의사 결정 AI 시스템</div>
            <div class="blue-box ds-flex center-center">중환자 중증도 및 욕창 예측 솔루션</div>
            <div class="blue-box ds-flex center-center">항생제 약제 및 혈당 농도 관리 및 추천 솔루션</div>
          </div>
        </div>
      </div> -->
    </div>
</template>

<script>

export default {
  name: 'AiCdss',
  components: {
    
  },
  data: () => ({
  }),
  methods: {
    movePage(name) {
      this.$router.push({name: name});
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.aicdss-wrapper {
  color: #191E2C;
  border-top: 1.5px solid #25B5AD;
}

.section-box {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-box:nth-child(2n) {
  background-color: #D1F0F2;
}

.section-box > div {
  width: 1240px;
}

.section-box p {
  color: #505050;
  font-size: 16px;
  font-weight: 500;
}

.section-box .info-text {
  font-size: 18px;
}

.text-wrapper {
  padding: 40px 0;
}

.section-box .bold {
  font-weight: bold;
  color: #000;
  padding-left: 5px;
}

.section-box .blue {
  color: #25B5AD;
}

.title-box {
  padding-bottom: 40px;
}

.title-box h3 {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
  padding-left: 10px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #25B5AD;
}

.main-text {
  font-size: 45px;
  color: #25B5AD;
  font-weight: 800;
}

.blue-wrapper {
  height: 150px;
  align-content: space-between;
}

.blue-box {
  width: 590px;
  height: 60px;  
  background-color: #25B5AD;
  border-radius: 60px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.info-wrapper {
  padding: 40px 0 80px 0;
}

.section-box .info-text-box {
  text-align: left;
  color: #505050;
  margin-top: 40px;
}

.section-box .info-text-box p {
  font-size: 18px;
}

.arrow {
  padding: 40px 0;
  height: auto;
}

.box-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1240px) {
  .section-box {
    padding: 40px 0;
  }

  .section-box > div {
    width: 768px;
    margin: auto;
  }

  .title-box h3 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 5px;
    left: 2px;
  }

  .title-box {
    position: relative;
    padding-bottom: 20px;
  }

  .main-text {
    font-size: 30px;
  }

  img {
    width: 100%;
  }

  .blue-wrapper {
    height: 100px;
  }

  .blue-box {
    width: 375px;
    height: 40px;
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .aicdss-wrapper {
    border-top: none;
  }

  .section-box > div {
    padding: 0 40px;
  }

  .section-box:first-child {
    padding-top: 10px;
  }
  
  .section-box p {
    padding: 30px 0;
  } 

  .top-logo {
    height: 57px;
  }

  img {
    width: 600px;
    height: 286px;
  }

  .blue-box {
    width: 100%;
  }

  .blue-wrapper {
    height: 160px;
  }
}

@media screen and (max-width: 480px) {
  .main-text {
    font-size: 30px;
  }

  .section-box p {
    padding: 0;
  }

  .section-box .info-text {
    font-size: 12px;
    word-break: keep-all;
  }

  img {
    width: 100%;
    height: auto;
  }

  .title-box h3 {
    font-size: 20px;
  }

  .mini-blue-box {
    width: 6px;
    height: 6px;
    top: 0;
  }

  .blue-wrapper {
    min-width: 360px;
    height: 150px;
  }

  .blue-box {
    font-size: 13px;
  }

  .section-box > div {
    padding: 0 60px;
    min-width: 360px;
  }

  .section-box .info-text-box p {
    font-size: 14px;
  }

  .arrow {
    width: 50%;
    padding: 20px 0;
  }

  .info-wrapper {
    padding: 30px 0 40px 0;
  }

  .box-wrapper img:first-child {
    margin-bottom: 20px;
  }
}
</style>

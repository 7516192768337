<template>
  <div id="container" class="main-wrapper">
    <div class="popup-wrapper">
      <div v-for="(popup, i) in popupList" :key="popup">
        <div class="popup-box" v-if="popup.show" :style='"top: 70px; left:"+(50+(370*i))+"px; z-index:"+(100+popup.seq)'>
          <img :src="'/upload/popup/'+popup['upload_name']" :class="{'active' : popup.exlink===1}" @click="openExlink(popup)">
          <div class="ds-flex">
            <div class="popup-btn" @click="hideToday(popup, i)">오늘 하루 보지 않기</div>
            <div class="popup-btn" @click="popup.show=false">닫기</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-box pc">
      <section id="outer-slider" class="splide" aria-label="Splide Basic HTML Example">
        <div class="splide__track">
          <ul class="splide__list" style="height: 100vh;">
            <li class="splide__slide" style="height: 100vh; width: 100vw; position: relative;">
              <img :src="social" style="width: 100%; height: 100%; position: absolute; top:0; left: 0;">
              <div class="product-left">
                <div class="product-menu ds-flex">
                  <div v-for="(array, i) in slideList.social" :key="array" class="ds-flex center-start product" :class="{'emphasis' : i===0}">
                    <div class="circle-box">
                      <img>
                    </div>
                    <p :class="{'active' : socialIdx===i}">{{ array.title }}</p>
                  </div>
                </div>
              </div>
              <section id="social-slider" class="splide" aria-label="Splide Basic HTML Example">
                <div class="splide__track">
                  <ul class="splide__list" style="height: 100vh;">
                    <li v-for="(array, i) in slideList.social" :key="array" class="splide__slide ds-flex center-end" style="height: 100vh; width: 100vw; position: relative;">
                      <img :src="array.src" style="width: 100%; height: 100%; position: absolute; top:0; left: 0;">
                      <div class="product-right">
                        <h1>{{ array.title }}</h1>
                        <p>{{ array.contents }}</p>
                        <div v-if="i!==0" class="detail-btn" @click="movePage(array.name)">
                          자세히 보기
                          <img src="@/assets/main/r_arrow_w_icon.svg">
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
            </li>
            <li class="splide__slide" style="height: 100vh; width: 100vw; position: relative;">
              <img :src="medical" style="width: 100%; height: 100%; position: absolute; top:0; left: 0;">
              <div class="product-left">
                <div class="product-menu ds-flex">
                  <div v-for="(array, i) in slideList.medical" :key="array" class="ds-flex center-start product" :class="{'emphasis' : i===0}">
                    <div class="circle-box">
                      <img>
                    </div>
                    <p :class="{'active' : medicalIdx===i}">{{ array.title }}</p>
                  </div>
                </div>
              </div>
              <section id="medical-slider" class="splide" aria-label="Splide Basic HTML Example" style="height: 100vh;">
                  <div class="splide__track" style="height: 100vh;">
                    <ul class="splide__list" style="height: 100vh;" :style="medical">
                      <li v-for="(array, i) in slideList.medical" :key="array" class="splide__slide ds-flex center-end" style="height: 100vh; width: 100vw; position: relative;">
                        <img :src="array.src" style="width: 100%; height: 100%; position: absolute; top:0; left: 0;">
                        <div class="product-right">
                          <h1>{{ array.title }}</h1>
                          <p>{{ array.contents }}</p>
                          <div v-if="i!==0" class="detail-btn" @click="movePage(array.name)">
                            자세히 보기
                            <img src="@/assets/main/r_arrow_w_icon.svg">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </section>
            </li>
          </ul>
        </div>
        <div class="splide__progress">
          <div class="splide__progress__bar"></div>
        </div>
      </section>
      <div class="scroll-btn ds-flex center-center" @click="scroll()">
        <img src="@/assets/main/scroll_down_arrow.svg">
        Scroll Down
      </div>
    </div>
    <!-- 태블릿 슬라이더 -->
    <div class="main-box tablet">
      <section id="tablet-slider" class="splide" aria-label="Splide Basic HTML Example">
        <div class="splide__track">
          <ul class="splide__list" style="height: 100vh;">
            <li v-for="slide in tabletSildeList" :key="slide" class="splide__slide">
              <img :src="slide.src" style="width: 100%; height: 100%; position: absolute; top:0; left: 0;">
              <div class="mobile-product-info">
                <div class="ds-flex center-start top">
                  <div v-if="slide.type==='social'">
                    <div v-for="(array, i) in slideList.social" :key="array" class="ds-flex center-start product" :class="{'emphasis' : i===0}">
                      <div class="circle-box">
                        <img>
                      </div>
                      <p :class="{'active' : array.title === slide.title}">{{ array.title }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(array, i) in slideList.medical" :key="array" class="ds-flex center-start product" :class="{'emphasis' : i===0}">
                      <div class="circle-box">
                        <img>
                      </div>
                      <p :class="{'active' : array.title === slide.title}">{{ array.title }}</p>
                    </div>
                  </div>
                </div>
                <div class="ds-flex center-start bottom">
                  <div>
                    <h1 class="title">{{ slide.title }}</h1>
                    <p>{{ slide.contents }}</p>
                    <div v-if="!slide.category" class="detail-btn" @click="movePage(slide.name)">
                      자세히 보기
                      <img src="@/assets/main/r_arrow_w_icon.svg">
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="splide__progress">
          <div class="splide__progress__bar"></div>
        </div>
      </section>
      <div class="scroll-btn ds-flex center-center" @click="scroll()">
        <img src="@/assets/main/scroll_down_arrow.svg">
        Scroll Down
      </div>
    </div>
    <!-- 태블릿 슬라이더 -->
    <!-- 모바일 슬라이더 -->
    <div class="main-box mobile">
      <section id="mobile-slider" class="splide" aria-label="Splide Basic HTML Example">
        <div class="splide__track">
          <ul class="splide__list" style="height: 100vh;">
            <li v-for="slide in mobileSildeList" :key="slide" class="splide__slide">
              <img :src="slide.src" style="width: 100%; height: 100%; position: absolute; top:0; left: 0;">
              <div class="mobile-product-info">
                <div class="ds-flex center-start top">
                  <div v-if="slide.type==='social'">
                    <div v-for="(array, i) in slideList.social" :key="array" class="ds-flex center-start product" :class="{'emphasis' : i===0}">
                      <div class="circle-box">
                        <img>
                      </div>
                      <p :class="{'active' : array.title === slide.title}">{{ array.title }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(array, i) in slideList.medical" :key="array" class="ds-flex center-start product" :class="{'emphasis' : i===0}">
                      <div class="circle-box">
                        <img>
                      </div>
                      <p :class="{'active' : array.title === slide.title}">{{ array.title }}</p>
                    </div>
                  </div>
                </div>
                <div class="ds-flex center-start bottom">
                  <div>
                    <h1 class="title">{{ slide.title }}</h1>
                    <p>{{ slide.contents }}</p>
                    <div v-if="!slide.category" class="detail-btn" @click="movePage(slide.name)">
                      자세히 보기
                      <img src="@/assets/main/r_arrow_w_icon.svg">
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="splide__progress">
          <div class="splide__progress__bar"></div>
        </div>
      </section>
      <div class="scroll-btn ds-flex center-center" @click="scroll()">
        <img src="@/assets/main/scroll_down_arrow.svg">
        Scroll Down
      </div>
    </div>
    <!-- 모바일 슬라이더 -->
    <div class="news-box">
      <div class="mobile-news">
        <h1 class="title">NEWS</h1>
        <p>지오비전의 다양하고 새로운 소식을 알려드립니다.</p>
      </div>
      <div class="ds-flex">
        <div class="news-left-box ds-flex">
          <div class="pc-news">
            <h1 class="title">NEWS</h1>
            <p>지오비전의 다양하고 새로운 소식을<br>알려드립니다.</p>
          </div>
          <div class="news-btn-box ds-flex center-between">
            <div class="news-btn more ds-flex center-between" @click="movePage('Newsroom')">
              뉴스 더보기
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="사각형_91" data-name="사각형 91" width="25" height="25" fill="#1e1e1e"/>
                  </clipPath>
                </defs>
                <g id="arrow_icon_b" transform="translate(0 0)">
                  <g id="그룹_486" data-name="그룹 486" transform="translate(0 0)" clip-path="url(#clip-path)">
                    <path id="패스_20" data-name="패스 20" d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.516,12.516,0,0,1,12.5,25m0-23.739A11.238,11.238,0,1,0,23.736,12.5,11.253,11.253,0,0,0,12.5,1.261" transform="translate(0 0)" fill="#1e1e1e"/>
                    <path id="패스_21" data-name="패스 21" d="M10.515,2.2l-.883.883,4.056,4.056H1.658V8.386H13.878l-4.233,4.05.86.9,5.7-5.446Z" transform="translate(3.568 4.732)" fill="#1e1e1e"/>
                  </g>
                </g>
              </svg>
            </div>
            <div class="news-btn ds-flex center-center" @click="slideNewsList('prev')">
              <svg id="L_arrow_icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="사각형_91" data-name="사각형 91" width="25" height="25" fill="#1e1e1e"/>
                  </clipPath>
                </defs>
                <g id="그룹_486" data-name="그룹 486" clip-path="url(#clip-path)">
                  <path id="패스_21" data-name="패스 21" d="M7.347,2.2l.883.883L4.173,7.138H16.2V8.386H3.984l4.233,4.05-.86.9-5.7-5.446Z" transform="translate(3.57 4.732)" fill="#1e1e1e"/>
                </g>
              </svg>
            </div>
            <div class="news-btn ds-flex center-center" @click="slideNewsList('next')">
              <svg id="R_arrow_icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="사각형_91" data-name="사각형 91" width="25" height="25" fill="#1e1e1e"/>
                  </clipPath>
                </defs>
                <g id="그룹_486" data-name="그룹 486" clip-path="url(#clip-path)">
                  <path id="패스_21" data-name="패스 21" d="M10.515,2.2l-.883.883,4.056,4.056H1.658V8.386H13.878l-4.233,4.05.86.9,5.7-5.446Z" transform="translate(3.568 4.732)" fill="#1e1e1e"/>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="news-list-box ds-flex" :style="newsListStyle" >
          <div 
            class="news" 
            :class="{'active' : i===focusIdx}" 
            v-for="(list, i) in newsList" 
            :key="list" 
            :style="list.style"
            @click="openNews(list)"
          >
            <img :src="list.thumbnail">
            <div class="news-info-box ds-flex center-start">
              <h3>{{ list.title }}</h3>
              <p>{{ list.selected_date }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Partner class="home" />
    <!-- <div class="cooperation-box">
      <h1 class="title">파트너</h1>
      <div class="list ds-flex center-start">
        <img v-for="idx in 12" :key="idx" :src="require(`@/assets/main/partnerships/${idx}.svg`)" />
      </div>
    </div>
    <div class="cooperation-box">
      <h1 class="title">구축 사례</h1>
      <div class="list ds-flex center-start">
        <img v-for="idx in 21" :key="idx" :src="require(`@/assets/main/case/${idx}.svg`)" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import {API} from "../api.js";
import moment from "moment";
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import Partner from "./about/Partner";

export default {
  name: 'Home',
  props: {
  },
  components: {
    Partner
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data: () => ({
    outerSlider: null,
    social: require("@/assets/main/visual/01_Social_Safety_AI.jpg"),
    medical: require("@/assets/main/visual/06_Medical_AI.jpg"),
    socialIdx: 0,
    medicalIdx: 0,
    slideList: { // pc ver
      social: [
        {
          title: "Social Safety AI",
          contents: "사회안전 AI(Social Safety AI)는 특정 사건 발생 시 CCTV 영상을 일일이 확인해야하는 불편함을 줄이고자 개발된 기술입니다.",
          src: require("@/assets/main/visual/01_Social_Safety_AI.jpg"),
        },
        { 
          name: "ZioSummary",
          title: "Zio-Summary",
          contents: "지오서머리(Zio-Summary)는 영상 시간이 10시간인 영상을 20분으로 요약해주는 스마트 고속 검색 시스템, 국내 유일의 동영상 요약 시스템 입니다.",
          src: require("@/assets/main/visual/02_Zio-Summary.jpg"),
        },
        { 
          name: "ZioVIS",
          title: "ZioVIS",
          contents: "지오비스(ZioVIS)는 관심 객체를 즉각적으로 선별하여 관제하는 지능형 선별 관제 시스템입니다.",
          src: require("@/assets/main/visual/03_ZioVIS.jpg"),
        },
        { 
          name: "ZioKeeper",
          title: "ZioKeeper",
          contents: "지오지킴이(ZioKeeper)는 감정 및 행동 인식으로 아동학대 의심 상황을 검출하는 시스템입니다.",
          src: require("@/assets/main/visual/04_ZioKeeper.jpg"),
        },
        { 
          name: "ZioEdge",
          title: "ZioEdge",
          contents: "지오엣지(Zio Edge)는 엣지 디바이스를 활용한 AI 개인 • 소규모 관제 시스템입니다.",
          src: require("@/assets/main/visual/05_ZioEdge.jpg"),
        },
      ],
      medical: [
        {
          title: "Medical AI",
          contents: "의료 AI(Medical AI)는 CT 영상에서 폐 질환을 검출하는 시스템, 초음파 영상을 통해 폐암의 진행도를 예측하는 AI기술, AI를 이용한 위내시경 촬영 가이드 시스템입니다.",
          src: require("@/assets/main/visual/06_Medical_AI.jpg"),
        },
        { 
          name: "ZioMed",
          title: "ZioMed",
          contents: "지오메드(ZioMed)는 인공지능 및 딥러닝을 기반한 의료 진단 시스템입니다.",
          src: require("@/assets/main/visual/07_ZioMed.jpg"),
        },
        { 
          name: "AiCDSS",
          title: "AI CDSS",
          contents: "AI CDSS는 인공지능을 기반한 중환자실 의사 결정 시스템입니다.",
          src: require("@/assets/main/visual/08_AI_CDSS.jpg"),
        },
        { 
          name: "SmartHospital",
          title: "Smart Hospital",
          contents: "스마트 병원(Smart Hospital)은 환자의 안전을 관리하는 시스템입니다.",
          src: require("@/assets/main/visual/09_Smart_Hospital.jpg"),
        },
      ]
    },
    tabletSildeList: [
      {
        title: "Social Safety AI",
        contents: "사회안전 AI(Social Safety AI)는 특정 사건 발생 시 CCTV 영상을 일일이 확인해야하는 불편함을 줄이고자 개발된 기술입니다.",
        src: require("@/assets/main/mobile/00_Social Safety AI.jpg"),
        category: true,
        type: 'social'
      },
      { 
        name: "ZioSummary",
        title: "Zio-Summary",
        contents: "지오서머리(Zio-Summary)는 영상 시간이 10시간인 영상을 20분으로 요약해주는 스마트 고속 검색 시스템, 국내 유일의 동영상 요약 시스템 입니다.",
        src: require("@/assets/main/mobile/01_Zio-Summary.jpg"),
        category: false,
        type: 'social'
      },
      { 
        name: "ZioVIS",
        title: "ZioVIS",
        contents: "지오비스(ZioVIS)는 관심 객체를 즉각적으로 선별하여 관제하는 지능형 선별 관제 시스템입니다.",
        src: require("@/assets/main/mobile/02_ZioVIS.jpg"),
        category: false,
        type: 'social'
      },
      { 
        name: "ZioKeeper",
        title: "ZioKeeper",
        contents: "지오지킴이(ZioKeeper)는 감정 및 행동 인식으로 아동학대 의심 상황을 검출하는 시스템입니다.",
        src: require("@/assets/main/mobile/03_ZioKeeper.jpg"),
        category: false,
        type: 'social'
      },
      { 
        name: "ZioEdge",
        title: "ZioEdge",
        contents: "지오엣지(Zio Edge)는 엣지 디바이스를 활용한 AI 개인 • 소규모 관제 시스템입니다.",
        src: require("@/assets/main/mobile/04_ZioEdge.jpg"),
        category: false,
        type: 'social'
      },
      {
        title: "Medical AI",
        contents: "의료 AI(Medical AI)는 CT 영상에서 폐 질환을 검출하는 시스템, 초음파 영상을 통해 폐암의 진행도를 예측하는 AI기술, AI를 이용한 위내시경 촬영 가이드 시스템입니다.",
        src: require("@/assets/main/mobile/05_Medical_AI.jpg"),
        category: true,
        type: 'medical'
      },
      { 
        name: "ZioMed",
        title: "ZioMed",
        contents: "지오메드(ZioMed)는 인공지능 및 딥러닝을 기반한 의료 진단 시스템입니다.",
        src: require("@/assets/main/mobile/06_ZioMed.jpg"),
        category: false,
        type: 'medical'
      },
      { 
        name: "AiCDSS",
        title: "AI CDSS",
        contents: "AI CDSS는 인공지능을 기반한 중환자실 의사 결정 시스템입니다.",
        src: require("@/assets/main/mobile/07_AI_CDSS.jpg"),
        category: false,
        type: 'medical'
      },
      { 
        name: "SmartHospital",
        title: "Smart Hospital",
        contents: "스마트 병원(Smart Hospital)은 환자의 안전을 관리하는 시스템입니다.",
        src: require("@/assets/main/mobile/08_Smart Hospital.jpg"),
        category: false,
        type: 'medical'
      },
    ],
    mobileSildeList: [
      {
        title: "Social Safety AI",
        contents: "사회안전 AI(Social Safety AI)는 특정 사건 발생 시 CCTV 영상을 일일이 확인해야하는 불편함을 줄이고자 개발된 기술입니다.",
        src: require("@/assets/main/mobile/00_social_safety_ai.png"),
        category: true,
        type: 'social'
      },
      { 
        name: "ZioSummary",
        title: "Zio-Summary",
        contents: "지오서머리(Zio-Summary)는 영상 시간이 10시간인 영상을 20분으로 요약해주는 스마트 고속 검색 시스템, 국내 유일의 동영상 요약 시스템 입니다.",
        src: require("@/assets/main/mobile/01_zio-summary.png"),
        category: false,
        type: 'social'
      },
      { 
        name: "ZioVIS",
        title: "ZioVIS",
        contents: "지오비스(ZioVIS)는 관심 객체를 즉각적으로 선별하여 관제하는 지능형 선별 관제 시스템입니다.",
        src: require("@/assets/main/mobile/02_ziovis.png"),
        category: false,
        type: 'social'
      },
      { 
        name: "ZioKeeper",
        title: "ZioKeeper",
        contents: "지오지킴이(ZioKeeper)는 감정 및 행동 인식으로 아동학대 의심 상황을 검출하는 시스템입니다.",
        src: require("@/assets/main/mobile/03_ziokeeper.png"),
        category: false,
        type: 'social'
      },
      { 
        name: "ZioEdge",
        title: "ZioEdge",
        contents: "지오엣지(Zio Edge)는 엣지 디바이스를 활용한 AI 개인 • 소규모 관제 시스템입니다.",
        src: require("@/assets/main/mobile/04_zioedge.png"),
        category: false,
        type: 'social'
      },
      {
        title: "Medical AI",
        contents: "의료 AI(Medical AI)는 CT 영상에서 폐 질환을 검출하는 시스템, 초음파 영상을 통해 폐암의 진행도를 예측하는 AI기술, AI를 이용한 위내시경 촬영 가이드 시스템입니다.",
        src: require("@/assets/main/mobile/05_medical_ai.png"),
        category: true,
        type: 'medical'
      },
      { 
        name: "ZioMed",
        title: "ZioMed",
        contents: "지오메드(ZioMed)는 인공지능 및 딥러닝을 기반한 의료 진단 시스템입니다.",
        src: require("@/assets/main/mobile/06_ziomed.png"),
        category: false,
        type: 'medical'
      },
      { 
        name: "AiCDSS",
        title: "AI CDSS",
        contents: "AI CDSS는 인공지능을 기반한 중환자실 의사 결정 시스템입니다.",
        src: require("@/assets/main/mobile/07_ai_cdss.png"),
        category: false,
        type: 'medical'
      },
      { 
        name: "SmartHospital",
        title: "Smart Hospital",
        contents: "스마트 병원(Smart Hospital)은 환자의 안전을 관리하는 시스템입니다.",
        src: require("@/assets/main/mobile/08_smart_hospital.png"),
        category: false,
        type: 'medical'
      },
    ],
    newsList: [],
    newsLeft: 0,
    popupList: [],
    newsListStyle: {},
    focusIdx: 0,
  }),
  methods: {
    openNews(news) {
      if(news.exlink===1 && news.url !== '') {
          window.open(news.url);
      } else if(news.exlink===0) {
          if(news.contents.length > 11) { // 내용이 없는 뉴스는 기본 길이가 11임
              this.$router.push({
                  name:'NewsDetailView',
                  query: {idx: news.idx}
              });
          }
      }
    },
    scroll() {
      setTimeout(function() {
        window.scrollTo({ top: window.innerHeight-70, left: 0, behavior: 'smooth' });
      }, 100)
    },
    movePage(name) {
      this.$router.push({name: name});
    },
    // 전체 팝업 리스트를 가져온다.
    getPopupList() {
      let singleton = this;
      let param = {
        table: 'popup',
        idx: '',
        option: ''
      }
      API.getList(param, function(status, res){
          if(res.data.length > 0) {
            // console.log('res.data >> ', res.data);
            for(let i=0; i<res.data.length; i++) {
              let list = singleton.popupList;
              list.push(res.data[i]);
              list[i].show = true;

              if(i == res.data.length-1) {
                singleton.setPopupShow(); 
              }
            }
          }
      })
    },
    setPopupShow() {
      for(let i=0; i<this.popupList.length; i++) {
        if(this.cookies.get('close_popup_'+i) === "no"){
          this.popupList[i].show = false;
        }
      }
    },
    // 전체 뉴스 리스트를 가져온다.
    getNewsList() {
      let singleton = this;
      let param = {
          table: 'news',
          idx: '',
          option: ''
      }
      API.getList(param, function(status, res){
        if(res.data.length > 0) {
          singleton.newsList = [];
          singleton.focusIdx = 0;
          for(let i=0; i<res.data.length; i++) {
            singleton.newsList.push(res.data[i]);
            singleton.newsList[i].date = moment(singleton.newsList[i].date).format('YYYY-MM-DD');
            singleton.newsList[i].selected_date = moment(singleton.newsList[i].selected_date).format('YYYY-MM-DD');
            singleton.newsList[i].style = {left: (singleton.newsLeft*i)+'px'};
          }
          singleton.setNewsListStyle();
        }
      })
    },
    setNewsListStyle() {
      this.newsListStyle = {width: (this.newsList.length*this.newsLeft)+'px'};
    },
    slideNewsList(direc) { // 뉴스 슬라이드
      let list = this.newsList;
      if(direc === 'prev') { // 이전
        if(this.focusIdx > 0) {
          this.focusIdx--;
        }
      } else { // 다음
        if(this.focusIdx < list.length-1) {
          this.focusIdx++;
        }
      }
      for(let i=0; i<list.length; i++) {
        list[i].style = {left: (this.newsLeft*(i-this.focusIdx))+'px'};
      }
    },
    openDetailPage(news) {
        if(news.exlink===1 && news.url !== '') {
            window.open(news.url);
        } else if(news.exlink===0) {
            if(news.contents.length > 11) { // 내용이 없는 뉴스는 기본 길이가 11임
                this.$router.push({
                    name:'NewsDetailView',
                    query: {idx: news.idx}
                });
            }
        }
    },
    openExlink(popup) { // 팝업 클릭 시 외부 링크로 이동
      if(popup.exlink === 1) {
         window.open(popup.url);
      }
    },
    hideToday(popup, idx){ // 오늘은 더이상 보지 않기
      this.cookies.set("close_popup_"+idx, "no","1d");
      popup.show = false;
    },
    setPaused(idx) {
      this.outerSlider.destroy();

      this.outerSlider = new Splide( '#outer-slider', {
        type    : 'loop',
        perPage : 1,
        autoplay: true,
        // autoplay: false,
        interval: 5000,
        height: '100vh',
        pauseOnHover: false,
        start: idx
      });
      this.outerSlider.mount();
    },
    handleResize() {
      let width = window.innerWidth;
      if(width > 1240) { // 1400px 이상
        this.newsLeft = 420;
      } else if(width > 480) {
        this.newsLeft = 259;
      } else {
        this.newsLeft = 315;
      }

      this.getNewsList();
    }
  },
  mounted() {
    /* pc 슬라이드 */
    this.outerSlider = new Splide('#outer-slider', {
      type    : 'loop',
      perPage : 1,
      // autoplay: false,
      autoplay: true,
      interval: 5000,
      height: '100vh',
      pauseOnHover: false,
    } );

    var socialSlider = new Splide('#social-slider', {
      direction: 'ttb',
      wheel    : true,
      height : '100vh',
      perPage: 1,
      gap    : '1rem',
      drag   : false,
      rewind: false,
      speed: 500,
      rewindSpeed: 500,
      pagination: false,
      arrows: false,
      start: 0,
      releaseWheel: true,
    } );

    var medicalSlider = new Splide('#medical-slider', {
      direction: 'ttb',
      wheel    : true,
      height : '100vh',
      perPage: 1,
      gap    : '1rem',
      drag   : false,
      rewind: false,
      speed: 500,
      rewindSpeed: 500,
      pagination: false,
      arrows: false,
      start: 0,
      releaseWheel: true,
    } );

    this.outerSlider.mount();
    socialSlider.mount();
    medicalSlider.mount();

    let singleton = this;

    socialSlider.on('move', function() {
      singleton.socialIdx = socialSlider.index;
      singleton.setPaused(0);
    });
    medicalSlider.on('move', function() {
      singleton.medicalIdx = medicalSlider.index;
      singleton.setPaused(1);
    });
    /* pc 슬라이드 */

    /* tablet 슬라이드 */
    var tabletSplide = new Splide( '#tablet-slider', {
      type    : 'loop',
      perPage : 1,
      autoplay: true,
      interval: 5000,
      speed: 500,
      pauseOnFocus: false,
      pauseOnHover: false
    } );

    tabletSplide.mount();
    /* tablet 슬라이드 */

    /* mobile 슬라이드 */
    var mobileSplide = new Splide( '#mobile-slider', {
      type    : 'loop',
      perPage : 1,
      autoplay: true,
      interval: 5000,
      speed: 500,
      pauseOnFocus: false,
      pauseOnHover: false
    } );

    mobileSplide.mount();
    /* mobile 슬라이드 */
  
    this.handleResize();
    this.getPopupList();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
  }
}
</script>

<style scoped>
.main-wrapper {
  /* min-width: 1920px; */
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.main-box {
  width: 100%;
  position: relative;
  color: #fff;
  height: 100vh;
  text-align: left;
  box-sizing: border-box;
}

.visual-box {
  height: 100vh;
  overflow: hidden;
}

.visual-box > img {
  width: 100%;
  height: 100%;
}

.main-box .info-text h1 {
  font-size: 55px;
  font-weight: bold;
  margin-bottom: 15px;
}

.main-box .info-text h3 {
  font-size: 24px;
}

.main-box > .info-text {
  position: absolute;
  top: 30%;
  left: 5%;
}

.all-title h1 {
  font-size: 50px;
  font-weight: bold;
  padding-right: 24px;
}

.all-title p {
  font-size: 20px;
  font-weight: bold;
}

.title {
  width: 100%;
  font-size: 36px;
  font-weight: 800;
  color: #1E1E1E;
  padding-bottom: 25px;
  text-align: left;
}

.mobile-news {
  display: none;
}

.news-box {
  padding: 120px 0;
  background-color: #F5F7FC;
}

.news-box > div {
  position: relative;
  width: 1240px;
  margin: auto;
}

.news-left-box {
  width: 350px;
  height: 370px;
  align-content: space-between;
}

.news-left-box p {
  color: #505050;
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  text-align: left;
}

.news-btn {
  border: 1.5px solid #1E1E1E;
  font-size: 16px;
  font-weight: 600;
  color: #1E1E1E;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  margin-right: 10px;
  transition: .3s;
  cursor: pointer;
}

.news-btn.more {
  width: 210px;
  padding: 0 20px;
}

.news-btn:last-child {
  margin-right: 0;
}

@media (hover: hover) and (pointer: fine) {
  .news-btn:hover {
    background-color: #1E1E1E;
    color: #fff;
  }

  .news-btn:hover path {
    fill: #fff;
    transition: .3s;
  }
}

.news-list-box {
  position: absolute;
  left: 420px;
  /* width: 2400px; */
  height: 385px;
  overflow: hidden;
}

.news {
  position: absolute;
  opacity: 0.5;
  width: 400px;
  height: 370px;
  border: 1px solid #BFC8D5;
  margin-right: 20px;
  box-sizing: border-box;
  transition: .5s;
  /* box-shadow: 10px 10px 5px #D3D9E3; */
  overflow: hidden;
}

.news.active, .news:hover {
  opacity: 1;
  box-shadow: 10px 10px 5px #D3D9E3;
  cursor: pointer;
}

.news > img {
  width: 100%;
  height: 250px;
}

.news-info-box {
  width: 100%;
  height: 120px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 35px 30px;
  align-content: space-between;
}

.news-info-box h3 {
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: #1E1E1E;
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-info-box p {
  font-size: 14px;
  line-height: 14px;
  color: #979CA8;
}

.scroll-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  cursor: pointer;
  /* background-color: orange; */
  font-size: 18px;
  font-weight: 500;
}

.scroll-btn > img {
  margin-right: 10px;
}

.product-box {
  width: 1240px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-menu {
  width: 250px;
  height: 175px;
}

.product-menu .product {
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #fff;
  width: 100%;
  transition: .3s;
  padding: 5px 0 5px 20px;
}

.product p.emphasis {
  font-weight: bold;
}

.circle-box > img {
  content: url("@/assets/main/blue_circle.svg");
  transition: .3s;
}

.product.emphasis {
  padding: 0;
  padding-bottom: 10px;
}

.product.emphasis .circle-box > img {
  content: url("@/assets/main/d_circle.svg");
}

.product.emphasis p {
  font-size: 26px;
  /* font-weight: bold; */
}

.product.emphasis p.active {
  font-size: 26px;
}

.product p.active {
  font-weight: bold;
  font-size: 21px;
  transition: .3s;
}

.circle-box {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.product-left {
  width: 250px;
  height: 200px;
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
}

.product-right {
  position: absolute;
  width: 50%;
  z-index: 5;
  padding: 0 10% 0 7%;
  box-sizing: border-box;
}

.product-right h1 {
  line-height: 78px;
  font-size: 55px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0px 3px 6px #00000029;
  padding-bottom: 20px;
}

.product-right p {
  font-size: 20px;
  color: #fff;
  text-shadow: 0px 3px 6px #00000029;
  word-break: keep-all;
  line-height: 35px;
}

.detail-btn {
  border: 1px solid #fff;
  font-size: 18px;
  width: 210px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  font-weight: 500;
  transition: .3s;
  cursor: pointer;
  margin-top: 70px;
  z-index: 5;
}

.detail-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.detail-btn img {
  width: 25px;
  height: 25px;
}

.popup-wrapper {
  width: 1240px;
  position: relative;
  margin: auto;
}

.popup-box {
  width: 350px;
  height: 535px;
  background-color: #fff;
  position: fixed;
  z-index: 5;
}

.popup-box img {
  width: 350px;
  height: 495px;
}

.popup-box img.active {
  cursor: pointer;
}

.popup-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #D2D7E3;
  font-size: 13px;
  color: #1E1E1E;
  font-weight: 500;
  cursor: pointer;
  transition: .3s;
}

.popup-btn:hover {
  color: #00ACEB;
}

.slide-window {
  position: relative;
}

.slide {
  position: absolute;
  display: flex;
}

.slide-array {
  width: 100vw; 
  height: 100vh;
  position: relative;
}

.slide-array > img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.tablet {
  display: none;
}

@media screen and (max-width: 1240px) {
  .product.emphasis p {
    font-size: 18px;
  }

  .product.emphasis p.active {
    font-size: 20px;
  }

  .product p.active {
    font-size: 18px;
  }

  .product p {
    font-size: 16px;
  }

  .product-right h1 {
    font-size: 35px;
    line-height: 40px;
    padding-bottom: 10px;
  }

  .product-right p {
    font-size: 15px;
  }

  .scroll-btn {
    font-size: 16px;
    height: 58px;
  }

  .scroll-btn > img {
    width: 16px;
    height: 16px;
  }

  .news-box > div {
    width: 768px;
  }

  .title {
    font-size: 30px;
  }

  .news-left-box p {
    font-size: 15px;
  }

  .news-btn {
    width: 40px;
    height: 40px;
    font-size: 13px;
  }

  .news-btn > svg {
    width: 19px;
    height: 19px;
  }

  .news-btn.more {
    width: 140px;
    padding: 0 15px;
  }

  .news-btn.more > svg {
    width: 20px;
    height: 20px;
  }

  .news {
    width: 246px;
    height: 227px;
  }

  .news > img {
    height: 153px;
  }

  .news-info-box {
    height: 74px;
    padding: 0 19px;
    align-content: center;
  }

  .news-info-box h3 {
    font-size: 12px;
  }

  .news-info-box p {
    font-size: 10px;
  }

  .news-list-box {
    height: 240px;
  }

  .news-left-box {
    height: 227px;
    width: 270px;
  }

  .news-list-box {
    left: 270px;
  }

  .detail-btn {
    width: 140px;
    height: 40px;
    font-size: 13px;
    padding: 13px 15px;
  }

  .detail-btn img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 768px) {
  .main-box.pc {
    display: none;
  }

  .main-box.mobile {
    display: none;
  }

  .main-box.tablet {
    display: inline-block;
  }

  .news-box {
    padding: 60px 40px;
  }

  .pc-news {
    display: none;
  }

  .mobile-news {
    display: inline-block;
  }

  .title {
    padding-bottom: 15px;
  }

  .mobile-news > p {
    color: #505050;
    font-size: 15px;
    line-height: 25px;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
  }

  .news-list-box {
    left: 0;
  }

  .news-left-box {
    height: 295px;
    width: 240px;
  }

  .news-btn-box {
    position: absolute;
    bottom: 0;
  }

  #tablet-slider {
    height: 100%;
  }

  .scroll-btn {
    height: 80px;
  }

  .mobile-product-info {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  }

  .mobile-product-info > div {
    width: 100%;
    position: relative;
    padding: 0 15%;
    box-sizing: border-box;
  }

  .mobile-product-info .top {
    height: calc(50vh - 50px);
    margin-top: 50px;
  }

  .mobile-product-info .bottom {
    height: calc(50vh - 80px);
  }

  .bottom .title {
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    padding-bottom: 25px;
  }

  .bottom p {
    font-size: 15px;
    font-weight: 300;
    word-break: keep-all;
  }

  .detail-btn {
    margin-top: 50px;
  }

  .product.emphasis p {
    font-size: 20px;
    padding-left: 0;
  }

  .product.emphasis p.active {
    font-size: 25px;
    font-weight: 600;
  }

  .product p {
    font-size: 15px;
    font-weight: 300;
    line-height: 44px;
  }

  .product p.active {
    font-size: 22px;
  }

  .product {
    padding-left: 20px;
  }

  .product.emphasis {
    padding-left: 0;
  }
 
  .popup-box {
    left: 50px !important;
  }
}

@media screen and (max-width: 480px) {
  .main-box.pc {
    display: none;
  }

  .main-box.tablet {
    display: none;
  }

  .main-box.mobile {
    display: inline-block;
  }

  .mobile-product-info > div {
    padding: 0 60px;
  }

  .product.emphasis p.active {
    font-size: 22px;
  }

  .product p {
    font-size: 14px;
    font-weight: 200;
    line-height: 35px;
  }

  .product p.active {
    font-size: 16px;
    font-weight: 600;
  }

  .mobile-product-info .bottom {
    box-sizing: border-box;
    height: calc(50vh - 55px);
  }

  .detail-btn {
    width: 170px;
    height: 50px;
    font-size: 14px;
    margin-top: 30px;
  }

  .title {
    font-size: 25px;
    padding-bottom: 10px;
  }

  .mobile-news > p {
    font-size: 14px;
    padding-bottom: 25px;
  }

  .news-btn.more {
    width: 150px;
    height: 40px;
  }

  .news-btn {
    font-size: 14px;
  }

  .news-btn.more > svg, .news-btn > svg {
    width: 20px;
    height: 20px;
  }

  .news {
    width: 305px;
    height: 261px;
  }

  .news > img {
    height: 191px;
  }

  .news-info-box {
    height: 70px;
  }

  .news-list-box {
    height: 275px;
  }

  .news-left-box {
    height: 325px;
  }

  .news-info-box h3 {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .news-info-box p {
    font-size: 11px;
  }

  .news-box {
    padding: 50px 60px;
  }

  .bottom .title {
    font-size: 24px;
    padding-bottom: 15px;
  }

  .bottom p {
    line-height: 25px;
    font-size: 14px;
  }

  .circle-box {
    margin-right: 8px;
  }

  .emphasis .circle-box > img {
    width: 16px;
    height: 16px;
  }
  
  .circle-box > img {
    width: 6px;
    height: 6px;
  }

  .scroll-btn {
    height: 65px;
  }

  .scroll-btn > img {
    width: 11px;
    height: 11px;
  }

  .popup-box {
    left: 15px !important;
  }

}
</style>

<template>
    <div class="certificate-wrapper">
        <div class="title-box ds-flex center-between">
          <h1 class="tab-title">기술 / 특허</h1>
          <div class="input-box select" :style="comboBox.style">
              <div 
                  v-for="(option, i) in comboBox.option" 
                  :key="option" 
                  class="option ds-flex center-between" 
                  @click="setComBox(option);"
              >
                  {{ option }}
                  <svg id="arrow" v-if="i===0" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.0006 1.8577C15.0006 2.06371 14.927 2.26972 14.7432 2.44139L8.34616 8.72466C8.34616 8.72466 7.94175 8.99933 7.6844 8.99933C7.42704 8.99933 7.20646 8.89633 7.02263 8.75899L0.257927 2.47573C-0.072955 2.13238 -0.072955 1.58303 0.257927 1.23968C0.58881 0.89633 1.21381 0.930665 1.58146 1.23968L7.6844 6.90491L13.4197 1.27401C13.7873 0.930665 14.3388 0.930665 14.7065 1.27401C14.8903 1.44569 15.0006 1.68603 15.0006 1.89204V1.8577Z" :fill=comboBox.fill />
                  </svg>
              </div>
          </div>
          <div class="btn-box ds-flex">
            <div class="btn gray move" :class="{'active': (certiData!=='' && certiData.order!==0)}" @click="moveTop()">
              최상단으로 이동
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1C14.9706 0.999999 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C0.999999 5.02944 5.02944 1 10 1ZM10 -4.37114e-07C15.5228 -6.78525e-07 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 -1.95703e-07 15.5228 -4.37114e-07 10C-6.78525e-07 4.47715 4.47715 -1.95703e-07 10 -4.37114e-07ZM9.64644 4.64645L6.46447 7.82843C6.2692 8.02369 6.2692 8.34027 6.46447 8.53553C6.65973 8.7308 6.97631 8.7308 7.17157 8.53553L9.5 6.20711L9.5 15L10.5 15L10.5 6.20711L12.8284 8.53553C13.0237 8.7308 13.3403 8.7308 13.5355 8.53553C13.7308 8.34027 13.7308 8.02369 13.5355 7.82843L10.3536 4.64645C10.1583 4.45118 9.84171 4.45118 9.64644 4.64645Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (certiData!=='' && certiData.order!==0)}" @click="moveUp()">
              위로 이동
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1C14.9706 0.999999 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C0.999999 5.02944 5.02944 1 10 1ZM10 -4.37114e-07C15.5228 -6.78525e-07 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 -1.95703e-07 15.5228 -4.37114e-07 10C-6.78525e-07 4.47715 4.47715 -1.95703e-07 10 -4.37114e-07ZM9.64644 4.64645L6.46447 7.82843C6.2692 8.02369 6.2692 8.34027 6.46447 8.53553C6.65973 8.7308 6.97631 8.7308 7.17157 8.53553L9.5 6.20711L9.5 15L10.5 15L10.5 6.20711L12.8284 8.53553C13.0237 8.7308 13.3403 8.7308 13.5355 8.53553C13.7308 8.34027 13.7308 8.02369 13.5355 7.82843L10.3536 4.64645C10.1583 4.45118 9.84171 4.45118 9.64644 4.64645Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (certiData!=='' && certiData.order!==certiList.length-1)}" @click="moveDown()">
              아래로 이동
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C0.999999 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 6.78525e-07 10 4.37114e-07C4.47715 1.95703e-07 -1.95703e-07 4.47715 -4.37114e-07 10C-6.78525e-07 15.5228 4.47715 20 10 20ZM9.64644 15.3536L6.46447 12.1716C6.2692 11.9763 6.2692 11.6597 6.46447 11.4645C6.65973 11.2692 6.97631 11.2692 7.17157 11.4645L9.5 13.7929L9.5 5L10.5 5L10.5 13.7929L12.8284 11.4645C13.0237 11.2692 13.3403 11.2692 13.5355 11.4645C13.7308 11.6597 13.7308 11.9763 13.5355 12.1716L10.3536 15.3536C10.1583 15.5488 9.84171 15.5488 9.64644 15.3536Z" fill="#B3B6C0"/>
              </svg>
            </div>
            <div class="btn gray move" :class="{'active': (certiData!=='' && certiData.order!==certiList.length-1)}" @click="moveBottom()">
              최하단으로 이동
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C0.999999 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 6.78525e-07 10 4.37114e-07C4.47715 1.95703e-07 -1.95703e-07 4.47715 -4.37114e-07 10C-6.78525e-07 15.5228 4.47715 20 10 20ZM9.64644 15.3536L6.46447 12.1716C6.2692 11.9763 6.2692 11.6597 6.46447 11.4645C6.65973 11.2692 6.97631 11.2692 7.17157 11.4645L9.5 13.7929L9.5 5L10.5 5L10.5 13.7929L12.8284 11.4645C13.0237 11.2692 13.3403 11.2692 13.5355 11.4645C13.7308 11.6597 13.7308 11.9763 13.5355 12.1716L10.3536 15.3536C10.1583 15.5488 9.84171 15.5488 9.64644 15.3536Z" fill="#B3B6C0"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="contents-box">
          <div class="table-header ds-flex">
            <div class="table-th title">제목</div> 
            <div class="table-th contents">내용</div> 
            <div class="table-th writer">작성자</div> 
            <div class="table-th date">업로드 날짜</div> 
          </div>
          <div class="table-body">
            <div v-for="list in certiList" :key="list" class="ds-flex td-box"> 
              <div class="radio-box ds-flex center-center" @click="setRadioBtn(list, !list.checked)">
                  <img v-if="list.checked" src="@/assets/admin/radio_btn.png">
                  <img v-else src="@/assets/admin/radio_default.png">
              </div>
              <div class="ds-flex table-td-box" @click="movePage('CertificateAdd', list)">
                <div class="table-td title"><p>{{ list.title }}</p></div>
                <div class="table-td contents"><p>{{ list.contents }}</p></div> 
                <div class="table-td writer">{{ list.writer }}</div> 
                <div class="table-td date">{{ list.date }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-box ds-flex center-center">
          <div class="btn-box ds-flex">
            <div class="btn blue" @click="movePage('CertificateAdd')">추가</div>
            <div class="btn gray" :class="{'delete' : certiData !== ''}" @click="showDelPopup()">삭제</div>
          </div>
        </div>

        <div v-if="showPopup.delete" class="popup-wrapper">
            <div class="popup-box ds-flex center-center">
                <img src="@/assets/login/important_icon.png">
                <div class="popup-text-box ds-flex center-center">
                  <h3 class="ds-flex center-center">해당 리스트를 삭제하시겠습니까?</h3>
                  <p class=" ds-flex center-center">삭제할 경우, 내용 복원이 불가합니다.</p>
                </div>
                <div class="blue-btn red-btn" @click="deleteList()">삭제</div>
                <div class="blue-btn red-btn" @click="showPopup.delete = false;">취소</div>
            </div>
        </div>
        <div v-if="showPopup.complete" class="popup-wrapper">
            <div class="popup-box ds-flex center-center complete">
                <img src="@/assets/admin/pop_check_icon.png">
                <div class="popup-text-box ds-flex center-center">
                  <h3 class="ds-flex center-center" style="margin: 0">리스트가 삭제되었습니다.</h3>
                </div>
                <div class="blue-btn red-btn" @click="showPopup.complete = false; $router.go()">확인</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import {API} from "../../api.js";
import moment from "moment";

export default {
  name: 'Certificate',
  components: {
  },
  data: () => ({
    showPopup: {
      delete: false,
      complete: false
    },
    upload: {
        server: '',
        dir: 'upload',
        menu: 'certificate',
    },
    comboBox:{ 
        isopen: false,
        style: {},
        origin: ['인증서', '특허증 및 저작권 등록증', 'PCT 국제출원', '투자선정인증서 외 기타'], // 기존 순서
        option: [], // 사용자가 선택한 옵션에 따라 순서가 바뀜
        selected: '', // 사용자가 선택한 옵션
        fill: '#14141C', // 화살표 색상
    },
    certiList: [],// 전체 리스트
    // currPageList: [], // 현재 페이지에서 보여주고 있는 리스트
    activeDelBtn: false,
    currPage: 1,
    listSize: 20,
    certiData: ''
  }),
  methods: {
    // 전체 리스트를 가져온다.
    getCertiList() {
      let singleton = this;
      let param = {
        table: this.upload.menu,
        idx: '',
        option: this.comboBox.selected
      }
      this.certiList = [];
      // this.currPageList = [];
      
      API.getList(param, function(status, res){
        if(res.data.length > 0) {
          for(let i=0; i<res.data.length; i++) {
              let list = singleton.certiList;
              list.push(res.data[i]);
              list[i].date = moment(list[i].date).format('YYYY-MM-DD');
              list[i].checked = false;
              list[i].order = i;

              // if(i === res.data.length-1) {
              //     singleton.setCurrList();
              // }
          }
        }
      })
    },
    // 현재 페이지에 보여질 리스트 set
    // setCurrList() {
    //   this.currPageList = [];
    //   var range = this.currPage * this.listSize;

    //   for (let i = range - this.listSize; i < range; i++) {
    //     if (this.certiList[i] !== undefined) {
    //       this.currPageList.push(this.certiList[i]);
    //     }
    //   }
    // },
    // 선택한 리스트를 삭제한다.
    async deleteList() {
      this.showPopup.delete = false;

      let list = this.certiList;

      for(let i=0; i<list.length; i++) {
        if(list[i].checked) {
          this.removeFile(list[i]["upload_name"]); // 업로드 되어있는 파일 삭제

          await axios(this.upload.server + '/delete/list', {
              method: 'POST',
              data: '',
              headers : {},
              params: {'table': this.upload.menu, 'idx': list[i].idx}
          }).then(response => {
              if (response.data) {
                  console.log('success .. >> ', response.data);
              }
          }).catch( () => {
              console.log('error..');
              return false;
          });
        }
        if(i === list.length-1) {
          this.showPopup.complete = true;
        }
      }
    },
    // 업로드된 파일을 삭제한다.
    async removeFile(fileName) {
        console.log(fileName);
        return axios(this.upload.server + '/delete/file', {
            method: 'POST',
            data: '',
            headers : {},
            params: {'menu': this.upload.menu, 'fileName': fileName}
        }).then(response => {
            if (response.data) {
                console.log('success .. >> ', response.data);
            }
            // throw new Error('Server or network error');
        }).catch( () => {
            console.log('error..');
            return false;
        });
    },
    showDelPopup() {
      if(this.certiData !== '') {
        this.showPopup.delete = true;
      }
    },
    setOption() {
        let comb = this.comboBox;
        comb.option = [];
        comb.option.push(comb.selected);
        for(let i=0; i<comb.origin.length; i++) {
            if(comb.origin[i] !== comb.selected) {
                comb.option.push(comb.origin[i]);
            }
        }
        this.getCertiList();
    },
    setComBox(selected) {
        let comb = this.comboBox;
        let option = document.getElementsByClassName('option');
        let arrow = document.getElementById('arrow');
        let width = window.innerWidth;
        
        if(comb.isopen) {
            for(let i=0; i<option.length; i++) {
                option[i].classList.remove('hover', 'active');
            }
            if(width >= 1440) {
              comb.style = {height: '40px'};
            } else {
              comb.style = {height: '25px'};
            }
            comb.fill = '#14141C';
            comb.selected = selected;
            arrow.style.transform = `rotate(360deg)`
            this.setOption();
        } else {
            for(let i=0; i<option.length; i++) {
                if(i===0) { option[i].classList.add('hover', 'active'); } // comboBox가 열려있을 경우에만 hover css 동작하도록
                else { option[i].classList.add('hover'); }
            }
            if(width >= 1440) {
              comb.style = {height: (comb.origin.length*40)+'px', 'border-color': '#00ACEB'};
            } else{
              comb.style = {height: (comb.origin.length*25)+'px', 'border-color': '#00ACEB'};
            }
            comb.fill = '#00ACEB';
            arrow.style.transform = `rotate(180deg)`
        }
        comb.isopen = !comb.isopen;
    },
    setRadioBtn(target, bool) {
      if(bool) {
        let list = this.certiList;
        for(let i=0; i<list.length; i++) {
          list[i].checked = false;
        }
        this.certiData = target;
      } else {
        this.certiData = '';
      }
      target.checked = bool;        
    },
    moveTop(){ // 최상단으로 이동
      let order = this.certiData.order;
      let list = this.certiList;
      if(order !== 0) {
        for(let i=0; i<order; i++) {
          list[i].order++;
        }
        list[order].order = 0;
        this.updateList();
      }
    },
    moveUp() { // 위로 이동
      let order = this.certiData.order;
      let list = this.certiList;
      if(order !== 0) {
        list[order].order--;
        list[order-1].order++;
        this.updateList();
      }
    },
    moveDown() { // 아래로 이동
      let order = this.certiData.order;
      let list = this.certiList;
      if(order !== list.length-1) {
        list[order].order++;
        list[order+1].order--;
        this.updateList();
      }
    },
    moveBottom() { // 최하단으로 이동
      let order = this.certiData.order;
      let list = this.certiList;
      if(order !== list.length-1) {
         for(let i=order+1; i<list.length; i++) {
          list[i].order--;
        }
        list[order].order = list.length-1;
        this.updateList();
      }
    },
    async updateList() {
      let list = this.certiList;
      for(let i=0; i<list.length; i++) {
        list[i].checked = false;
        await axios(this.upload.server + '/update/certificate/seq', {
            method: 'POST',
            params: {
              'idx': list[i].idx,
              'seq': list[i].order,
            },
            headers : {'Content-type' : 'multipart/form-data' }
        }).then(res => {
          if(res.data) {
            //
          }
        }).catch( () => {
            console.log('error..');
            return false;
        });
      }
      this.certiData = '';
      this.setOption();
    },
    movePage(name, list) {
      if(list === undefined) {
        this.$router.push({name: name});
      } else {
        this.$router.push({name: name, query: {idx: list.idx}})
      }
    },
    // 페이지 이동 버튼 클릭 시 동작
    changePage(page) {
      window.scrollTo(0,0);
      this.currPage = page;
      this.setCurrList();
      this.setCheckBox(false);
    },
    handleResize() {
      let width = window.innerWidth;
      let comb = this.comboBox;
      if(!comb.isopen) {
        if(width >= 1440) {
          comb.style = {height: '40px'};
        } else {
          comb.style = {height: '25px'};
        }
      } else {
        if(width >= 1440) {
          comb.style = {height: (comb.origin.length*40)+'px', 'border-color': '#00ACEB'};
        } else {
          comb.style = {height: (comb.origin.length*25)+'px', 'border-color': '#00ACEB'};
        }
      }
    }
  },
  mounted() {
    window.scrollTo(0,0);
    this.comboBox.selected = this.comboBox.origin[0];
    this.setOption();
    // this.getCertiList();

    // this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
  }
}
</script>

<style scoped>
.certificate-wrapper {
  /* min-height: 1080px; */
}

.title-box {
  position: relative;
  width: 100%;
  height: 70px;
  padding: 15px 20px 15px 30px;
  box-sizing: border-box;
  align-items: center;
}

.tab-title {
  color: #14141C;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.count-box {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
  margin-left: 15px;
}

.blue-highlight {
  color: #00ACEB;
  font-weight: 600;
  margin-left: 3px;
}

.contents-box {
  padding: 0 20px;  
}

.table-header {
  width: 100%;
  height: 40px;
  background-color: #555861;
}

.table-th {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-right: 1px solid #fff;
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.table-th:last-child {
  border-right: none;
}

.table-th.title {
  width: calc(50% - 180px);
}

.table-th.contents {
  width: calc(50% - 180px);
}

.table-th.writer {
  width: 180px;
}

.table-th.date {
  width: 180px;
}

.table-td.title {
  width: calc(50% - 200px);
  justify-content: flex-start;
}

.table-td.contents {
  width: calc(50% - 160px);
  justify-content: flex-start;
  padding: 0 15px;
}

.table-td p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-td.writer {
  width: 180px;
}

.table-td.date {
  width: 180px;
}

.radio-box {
  width: 40px;
  height: 40px;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
}

.table-body {
  padding-top: 10px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}

.td-box {
   border: 1px solid #B3B6C0;
   margin-bottom: 10px;
}

.td-box:last-child {
  margin-bottom: 0;
}

.table-td-box {
  width: calc(100% - 40px);
}

.table-td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #fff;
  box-sizing: border-box;
  border-right: 1px solid #B3B6C0;
  cursor: pointer;
}

.table-td:last-child {
    border-right: none;
}

.no-data-box {
  width: 100%;
  height: 880px;
  background-color: #F2F2F5;
  border: 1px solid #B3B6C0;
  color: #707070;
  font-size: 15px;
  box-sizing: border-box;
}

.btn-box {
  position: absolute;
  right: 20px;
}

.btn {
  width: 110px;
  height: 40px;
  border-radius: 5px;
  background-color: #F2F2F5;
  border: 1px solid;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  margin-right: 5px;
  font-weight: 600;
  box-sizing: border-box;
}

input::-webkit-input-placeholder {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
}

.btn:last-child {
  margin-right: 0;
}

.btn.blue {
  border-color: #00ACEB;
  color: #00ACEB;
  cursor: pointer;
}

.btn.blue:hover {
  color: #fff;
  background-color: #00ACEB;
}

.btn.gray {
  border-color: #B3B6C0;
  color: #B3B6C0;
}

.btn.delete {
  cursor: pointer;
  color: #FF3E3E;
  border-color: #FF3E3E;
  cursor: pointer;
}

.btn.delete:hover {
  background-color: #FF3E3E;
  color: #fff;
}

.btn.active {
    border-color: #00ACEB;
    color: #00ACEB;
    cursor: pointer;
}

.btn.active:hover {
    background-color: #00ACEB;
    color: #fff;
}

.btn.gray.active path {
  transition: .3s;
  fill: #00ACEB;
}

.btn.gray.active:hover path {
  transition: .3s;
  fill: #fff;
}

.btn.gray.active:hover {
  background-color: #00ACEB;
  color: #fff;
}

.btn.move {
  width: 175px;
  padding: 11px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom-box {
  position: relative;
  height: 80px;
  padding: 20px;
  box-sizing: border-box;
}

.input-box {
    /* width: 100%; */
    height: 40px;
    border: 1.5px solid #B3B6C0;
    border-radius: 5px;
    background-color: #F2F2F5;
    box-sizing: border-box;
    transition: .3s;
    color: #14141c;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.input-box:hover {
    border-color: #00ACEB;
}

.input-box:focus {
    border-color: #00ACEB;
}

.input-box.input {
    padding: 11px 15px;
}

.input-box.text {
    border: none;
    border-bottom: 1px solid #14141C;
    border-radius: 0px;
    background-color: transparent;
}

.input-box.select {
    width: 200px;
    position: absolute;
    transition: .3s;
    overflow: hidden;
    border-color: #14141C;
    cursor: pointer;
    z-index: 5;
    left: 141px;
    top: 15px;
}

.input-box.select:hover {
    border-color: #00ACEB;
}

.input-box.select:hover path {
    fill: #00ACEB;
    transition: .3s;
}

.input-box.select .option {
    height: 40px;
    padding: 0 15px;
    transition: .3s;
}

.input-box.select .option:hover {
    color: #00ACEB;
    background-color: #E3E4EA;
}

.item-title {
    color: #14141C;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    padding-left: 10px ;
    text-align: left;
}

.popup-wrapper {
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: rgba(14, 14, 19, 0.7);
    position: absolute;
    top: 0;
    left: 0;
}

.popup-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    height: 200px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 29px 0;
}

.popup-box h3 {
    width: 100%;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    height: 18px;
    align-content: center;
    margin-bottom: 10px;
}

.popup-box p {
    font-size: 12px;
    color: #000000;
    height: 14px;
    width: 100%;
    align-content: center;
}
 
.popup-box .blue-btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    margin: 0 5px;
}

.popup-text-box {
    width: 100%;
    height: 82px;
    align-content: center;
}

.red-btn {
    background-color: #fff;
    color: #FF3E3E;
    border: 1px solid #FF3E3E;
    font-weight: 600;
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;
}

.red-btn:hover {
    background-color: #FF3E3E;
    color: #fff;
}

.complete .red-btn {
  background-color: #FF3E3E;
  color: #fff;
}

.complete .red-btn:hover {
  background-color: #D02727;
}

@media screen and (max-width: 1439px) {
    .contents-wrapper {
        width: calc(100% - 270px);
    }

    .contents-box {
      padding: 0 15px;
    }

    .title-box {
      padding: 7px 20px;
      height: 40px;
      align-content: center;
    }

    .table-body {
      padding-top: 5px;
    }

    .tab-title {
      font-size: 14px;
      line-height: 17px;
    }

    .count-box {
      font-size: 11px ;
    }

    .table-header {
      height: 30px;
    }

    .check-box {
      width: 30px;
      height: 30px;
    }

    .check-box > img {
      width: 10px;
      height: 10px;
    }

    .table-th {
      height: 30px;
      font-size: 11px;
    }

    .table-td-box {
      width: calc(100% - 30px);
    }

    .table-td {
      height: 30px;
      font-size: 11px;
    }

    .no-data-box {
      font-size: 11px;
      height: 550px;
    }

    .btn {
      width: 80px;
      height: 25px;
      font-size: 11px;
      border-radius: 3px;
      margin-right: 5px;
    }

    .bottom-box {
      height: 50px;
      padding: 13px;
    }

    .table-th.title {
      width: calc(50% - 105px);
    }

    .table-th.contents {
      width: calc(50% - 95px);
    }

    .table-th.writer {
      width: 100px;
    }

    .table-th.date {
      width: 100px;
    }

    .table-td-box {
      width: calc(100% - 30px);
    }

    .table-td.title {
      width: calc(50% - 120px);
    }

    .table-td.contents {
      width: calc(50% - 80px);
      padding: 0 10px;
    }

    .table-td.writer {
      width: 100px;
    }

    .table-td.date {
      width: 100px;
    }

    .btn-box {
      right: 15px;
    }

    .btn.move {
      width: 120px;
      padding: 5px 9px;
    }

    .btn.move > svg {
      width: 15px;
      height: 15px;
    }

    .btn.bold {
      width: 110px;
      padding: 0 7px;
      box-sizing: border-box;
    }

    .input-box.input {
      padding: 5px 10px;
      font-size: 11px; 
      border-radius: 3px;
    }

    .input-box.select {
      top: 7px;
      left: 97px;
      width: 145px;
      height: 25px;
      border-radius: 3px;
    }

    .input-box.select .option {
      height: 24px;
      font-size: 11px;
      padding: 0 10px;
      line-height: 13px;
    }

    .input-box.year {
      width: 80px;
      height: 25px;
    }

    .input-box.contents {
      width: calc(100% - 340px);
      height: 25px;
    }

    #arrow {
      width: 10px;
    }

    .radio-box {
      width: 30px;
      height: 30px;
    }

    .radio-box > img {
      width: 10px;
      height: 10px;
    }

    .edit-his-box {
      width: calc(100% - 270px);
      height: 50px;
    }

    .item-title {
      font-size: 13px;
    }
}

/* @media screen and (max-width: 1030px) {
  .tab-title {
    font-size: 18px;
  }

  .title-box {
    padding: 20px 25px;
    height: 60px;
  }

  .contents-wrapper {
    width: 100%;
    height: calc(100vh - 60px);
    padding-top: 60px;
  }

  .table-header {
    height: 50px;
  }

  .table-th {
    height: 50px;
    font-size: 16px;
  }
} */
</style>

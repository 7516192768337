<template>
    <div class="inquiry-wrapper">
      <div class="inquiry-box">
        <div class="title-box">
          <div class="mini-blue-box"></div>
          <h1>제품 문의</h1>
          <p class="to-text">sales@ziovision.co.kr</p>
        </div>
        <div class="contents-box ds-flex center-between">
          <div class="data-box long">
            <h3 class="item-title">업체명</h3>
            <input 
                type="text" 
                class="input-box input" 
                placeholder="업체명"
                v-model="data.comp"
                :class="{'filled' : data.comp!==''}" 
                @input="checkInput()"
            >
          </div>
          <div class="data-box short">
            <h3 class="item-title short">상담 분야</h3>
            <div class="input-box select field" :style="comboBox.style">
                <div 
                    v-for="(option, i) in comboBox.option" 
                    :key="option" 
                    class="option ds-flex center-between" 
                    @click="setComBox(option);"
                >
                    {{ option }}
                    <svg id="fieldArrow" v-if="i===0" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0006 1.8577C15.0006 2.06371 14.927 2.26972 14.7432 2.44139L8.34616 8.72466C8.34616 8.72466 7.94175 8.99933 7.6844 8.99933C7.42704 8.99933 7.20646 8.89633 7.02263 8.75899L0.257927 2.47573C-0.072955 2.13238 -0.072955 1.58303 0.257927 1.23968C0.58881 0.89633 1.21381 0.930665 1.58146 1.23968L7.6844 6.90491L13.4197 1.27401C13.7873 0.930665 14.3388 0.930665 14.7065 1.27401C14.8903 1.44569 15.0006 1.68603 15.0006 1.89204V1.8577Z" :fill=comboBox.fill />
                    </svg>
                </div>
            </div>
          </div>
          <div class="data-box short">
            <h3 class="item-title">상담 제품</h3>
            <div class="input-box select" :style="comboBox.subStyle">
                <div 
                    v-for="(option, i) in comboBox.subOption" 
                    :key="option" 
                    class="pro-option ds-flex center-between" 
                    @click="setProComBox(option);"
                >
                    {{ option }}
                    <svg id="proArrow" v-if="i===0" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0006 1.8577C15.0006 2.06371 14.927 2.26972 14.7432 2.44139L8.34616 8.72466C8.34616 8.72466 7.94175 8.99933 7.6844 8.99933C7.42704 8.99933 7.20646 8.89633 7.02263 8.75899L0.257927 2.47573C-0.072955 2.13238 -0.072955 1.58303 0.257927 1.23968C0.58881 0.89633 1.21381 0.930665 1.58146 1.23968L7.6844 6.90491L13.4197 1.27401C13.7873 0.930665 14.3388 0.930665 14.7065 1.27401C14.8903 1.44569 15.0006 1.68603 15.0006 1.89204V1.8577Z" :fill=comboBox.proFill />
                    </svg>
                </div>
            </div>
          </div>
          <div class="data-box short">
            <h3 class="item-title">이름</h3>
            <input 
                type="text" 
                class="input-box input" 
                placeholder="홍길동"
                v-model="data.name"
                :class="{'filled' : data.name!==''}" 
                @input="checkInput()"
            >
          </div>
          <div class="data-box short">
            <h3 class="item-title">이메일</h3>
            <input 
                type="text" 
                class="input-box input" 
                placeholder="example@ziovision.co.kr"
                v-model="data.email"
                :class="{'filled' : data.email!==''}" 
                @input="checkInput()"
            >
          </div>
          <div class="data-box long">
            <h3 class="item-title">제목</h3>
            <input 
                type="text" 
                class="input-box input" 
                placeholder="제목을 입력하세요"
                v-model="data.title"
                :class="{'filled' : data.title!==''}" 
                @input="checkInput()"
            >
          </div>
          <div class="data-box long text">
            <h3 class="item-title">문의 내용</h3>
            <textarea 
              class="input-box input" 
              :class="{'filled' : data.message!==''}" 
              placeholder="내용을 입력하세요" 
              spellcheck="false" 
              v-model="data.message"
              @input="checkInput()"
            ></textarea>
          </div>
        </div>
        <div 
          class="submit-btn ds-flex center-center"
          :class="{'active' : data.isFilled}"
          @click="sendMail()"
        >문의하기</div>
      </div>
      <!-- 이메일 전송 form -->
      <form class="gform" @submit.prevent="submitForm" style="display: none;">
          <input type="title" name="title" v-model="data.title">
          <input type="company" name="company" v-model="data.comp">
          <input type="name" name="name" v-model="data.name">
          <input type="text" name="email" v-model="data.email">
          <input type="category" name="category" v-model="comboBox.selected">
          <input type="product" name="product" v-model="comboBox.subSelected">
          <textarea type="message" name="message" v-model="data.message"></textarea>
          <input type="submit" ref="send" value="전송">
      </form>
    </div>
</template>

<script>
// import axios from "axios"

export default {
  name: 'Inquiry',
  components: {
    
  },
  data: () => ({
    comboBox:{ 
        isopen: false,
        isProOpen: false,
        style: {},
        subStyle: {},
        origin: ['사회안전 AI', '의료 AI', '기타'], // 기존 순서
        option: [], // 사용자가 선택한 옵션에 따라 순서가 바뀜
        subOrigin: [],
        subOption: [],
        product: {
          social: ['Ziosummary', 'ZioVIS', 'ZioKeeper', 'ZioEdge'],
          medical: ['ZioMed', 'AI CDSS', 'Smart Hospital'],
          etc: ['-']
        },
        selected: '', // 사용자가 선택한 옵션
        subSelected: '',
        fill: '#14141C', // 화살표 색상
        proFill: '#14141C', // 화살표 색상
    },
    data: {
      comp: '',
      // category: '',
      // product: '',
      name: '',
      email: '',
      title: '',
      message: '',
      isFilled: false
    },
    height: 0, // combo box height
  }),
  methods: {
    sendMail() {
      if(this.data.isFilled) {
        this.$refs.send.click();
      }
    },
    submitForm() {
      const formData = new FormData();
      formData.append('title', this.data.title);
      formData.append('company', this.data.comp);
      formData.append('name', this.data.name);
      formData.append('email', this.data.email);
      formData.append('category', this.comboBox.selected);
      formData.append('product', this.comboBox.subSelected);
      formData.append('message', this.data.message);

      fetch('https://script.google.com/macros/s/AKfycbxvXYKhX-RHXFhwovjyV9RkToJuOygJVe2sfZtLoQ_za3kTAwPrKkEzZR9NszCmEgNhlQ/exec', {
        method: 'POST',
        body: formData,
      })
      .then(response => {
        alert('이메일이 전송되었습니다.', response);
      })
      .catch(error => {
        console.error('전송 실패:', error);
      });
    },
    setOption() { // 상담 분야
      let comb = this.comboBox;
      comb.option = [];
      
      if(comb.selected === '') {
          for(let i=0; i<comb.origin.length; i++) {
              comb.option.push(comb.origin[i]);
          }
      } else {
          comb.option.push(comb.selected);
          for(let i=0; i<comb.origin.length; i++) {
              if(comb.origin[i] !== comb.selected) {
                  comb.option.push(comb.origin[i]);
              }
          }
          this.setProOption();
      }
    },
    setProOption(selected) { // 상담 제품
      let comb = this.comboBox;
      comb.subOption = [];

      if(comb.selected === '' || comb.selected === '사회안전 AI') {
          comb.subOrigin = comb.product.social;
        } else if(comb.selected === '의료 AI') {
          comb.subOrigin = comb.product.medical;
        } else { // 기타
          comb.subOrigin = comb.product.etc;
        }
      
      if(selected === undefined) {
        for(let i=0; i<comb.subOrigin.length; i++) {
          comb.subOption.push(comb.subOrigin[i]);
        }
        comb.selected = comb.option[0];
        comb.subSelected = comb.subOption[0];
      } else {
        comb.subOption.push(selected);

        for(let i=0; i<comb.subOrigin.length; i++) {
            if(comb.subOrigin[i] !== selected) {
                comb.subOption.push(comb.subOrigin[i]);
            }
        }
      }
    },
    setComBox(selected) {
      let comb = this.comboBox;
      let option = document.getElementsByClassName('option');
      let arrow = document.getElementById('fieldArrow');
      
      if(comb.isopen) {
          for(let i=0; i<option.length; i++) {
              option[i].classList.remove('hover', 'active');
          }
          comb.style = {height: this.height+'px'};
          comb.fill = '#14141C';
          comb.selected = selected;

          // product combobox
          comb.subStyle = {height: this.height+'px'};
          comb.isProOpen = false;
          comb.proFill = '#14141C';

          arrow.style.transform = `rotate(360deg)`
          this.setOption();
      } else {
          for(let i=0; i<option.length; i++) {
              if(i===0) { option[i].classList.add('hover', 'active'); } // comboBox가 열려있을 경우에만 hover css 동작하도록
              else { option[i].classList.add('hover'); }
          }
          comb.style = {height: (this.height * 3)+'px', 'border-color': '#00ACEB'};
          comb.fill = '#00ACEB';
          arrow.style.transform = `rotate(180deg)`
      }
      comb.isopen = !comb.isopen;
    },
    setProComBox(selected) {
      let comb = this.comboBox;
      let option = document.getElementsByClassName('pro-option');
      let arrow = document.getElementById('proArrow');
      
      if(comb.isProOpen) {
          for(let i=0; i<option.length; i++) {
              option[i].classList.remove('hover', 'active');
          }
          comb.subStyle = {height: this.height+'px'};
          comb.proFill = '#14141C';
          comb.subSelected = selected;
          arrow.style.transform = `rotate(360deg)`
          this.setProOption(selected);
      } else {
          for(let i=0; i<option.length; i++) {
              if(i===0) { option[i].classList.add('hover', 'active'); } // comboBox가 열려있을 경우에만 hover css 동작하도록
              else { option[i].classList.add('hover'); }
          }
          comb.subStyle = {height: (comb.subOrigin.length*this.height)+'px', 'border-color': '#00ACEB'};
          comb.proFill = '#00ACEB';
          arrow.style.transform = `rotate(180deg)`
      }
      comb.isProOpen = !comb.isProOpen;
    },
    // 모든 값이 입력되었는지 확인한다.
    checkInput() {
        for(let key in this.data){
            if(this.data[key] === '') {
                this.data.isFilled = false;
                break;
            } else {
                this.data.isFilled = true;
            }
        }
    },
    movePage(name) {
      this.$router.push({name: name});
    },
    handleResize() {
      let width = window.innerWidth;
      if(width > 1240) {
        this.height = 40;
      } else if(width > 768) {
        this.height = 30;
      } else {
        this.height = 40;
      }

      let comb = this.comboBox;
      if(!comb.isopen) {
          comb.style = {height: this.height+'px'};
          comb.subStyle = {height: this.height+'px'};
      } else {
          comb.style = {height: (this.height * 3)+'px', 'border-color': '#00ACEB'};
      }
      if(!comb.isProOpen) {
          comb.subStyle = {height: this.height+'px'};
      } else {
          comb.subStyle = {height: (comb.subOrigin.length*this.height)+'px', 'border-color': '#00ACEB'};
      }
    }
  },
  mounted() {
    this.handleResize();
    this.setOption();
    this.setProOption();

    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
  }
}
</script>

<style scoped>
.inquiry-wrapper {
  color: #191E2C;
  font-size: 16px;
  border-top: 1.5px solid #00ACEB;
  padding: 80px 0;
}

.inquiry-box {
  width: 1240px;
  margin: auto;
}

.contents-box {
  padding: 35px 0 40px 0;
  height: 900px;
  align-content: space-between;
}

.title-box{
  display: flex;
  align-items: baseline;
}

.title-box h1 {
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #00ACEB;
}

.item-title {
  color: #14141C;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding-left: 10px ;
  text-align: left;
}

.data-box {
  height: 70px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.data-box.long {
  width: 100%;
}

.data-box.short {
  width: 610px;
}

.data-box.text {
  height: 500px;
}

textarea.input-box {
  resize: none;
  height: 470px;
  line-height: 25px;
}

.input-box {
    width: 100%;
    height: 40px;
    border: 1.5px solid #DBDEE7;
    background-color: #fff;
    box-sizing: border-box;
    transition: .3s;
    color: #14141c;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}

.input-box:hover {
    border-color: #00ACEB;
}

.input-box:focus {
    border-color: #00ACEB !important;
}

.input-box.input {
    padding: 11px 15px;
}

.input-box.text {
    border: none;
    border-bottom: 1px solid #14141C;
    border-radius: 0px;
    background-color: transparent;
    padding: 11px 15px;
}

.input-box.text:focus {
    border-color: #14141C !important;
}

.input-box.select {
    position: absolute;
    top: 30px;
    transition: .3s;
    overflow: hidden;
    border-color: #14141C;
    cursor: pointer;
    z-index: 10;
}

.input-box.field {
  z-index: 15;
}

.input-box.select:hover {
    border-color: #00ACEB;
}

.input-box.select:hover path {
    fill: #00ACEB;
    transition: .3s;
}

.input-box.select .option, .pro-option {
    height: 40px;
    padding: 0 15px;
    transition: .3s;
}

.input-box.select .option:hover, .pro-option:hover {
    background-color: #F2F2F5;
}

.input-box.filled {
    border-color: #14141C;
}

.submit-btn {
  width: 140px;
  height: 50px;
  color: #9EA5B6;
  font-size: 16px;
  font-weight: 500;
  border: 1.5px solid #DBDEE7;
  transition: .3s;
  margin: auto;
}

.submit-btn.active {
  border-color: #1E1E1E;
  color: #1E1E1E;
  cursor: pointer;
}

.submit-btn.active:hover {
  background-color: #1E1E1E;
  color: #fff;
}

.to-text {
  font-size: 14px;
  color: #9ea5b6;
  padding-left: 10px;
}

@media screen and (max-width: 1240px) {
  .inquiry-box {
    width: 768px;
  }

  .inquiry-wrapper {
    padding: 40px 0;
  }

  .title-box {
    position: relative;
  }
  
  .title-box h1 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 3px;
    left: 3px;
  }

  .contents-box {
    padding: 30px 0;
    height: 650px;
  }

  .data-box.text {
    height: 326px;
  }

  textarea.input-box {
    height: 300px;
  }

  .item-title {
    font-size: 14px;
    padding-left: 5px;
  }

  .input-box {
    height: 30px;
    font-size: 12px;
  }

  .data-box {
    height: 56px;
  }

  .data-box.short {
    width: 374px;
  }.inquiry-wrapper

  .input-box.select .option, .pro-option {
    height: 30px;
    padding: 0 13px;
  }

  .input-box.input {
    padding: 5px 13px;
  }

  .submit-btn {
    width: 120px;
    height: 40px;
    font-size: 14px;
  }

  #fieldArrow, #proArrow {
    width: 12px;
    height: 8px;
  }
}

@media screen and (max-width: 768px) {
  .inquiry-wrapper {
      border-top: none;
      padding: 0 40px;
      padding-bottom: 40px;
  }

  .inquiry-box {
    width: 100%;
  }

  .contents-box {
    padding: 20px 0;
    height: 670px;
  }

  .data-box.short {
    width: 49%;
  }

  .input-box {
    height: 40px;
    font-size: 14px;
  }

  .input-box.select {
    height: 40px;
  }

  .data-box {
    height: 66px;
  }

  .inquiry-wrapper .input-box.select .option, .pro-option {
    height: 39px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .data-box.short {
    width: 100%;
  }

  .contents-box {
    padding: 20px 0 40px 0;
    height: 900px;
  }

  .input-box.input {
    border-radius: 0;
  }
}

</style>

<template>
    <div class="iradd-wrapper">
        <div class="title-box ds-flex">
          <h1 class="tab-title">Notice 추가</h1>
        </div>
        <div class="contents-box ds-flex center-start">
            <div class="left ds-flex">
                <div class="data-box short">
                    <h3 class="item-title">제목</h3>
                    <input 
                      type="text" 
                      v-model="irData.title" 
                      class="input-box input"
                      :class="{'filled' : irData.title!==''}"
                      placeholder="제목을 입력해주세요."
                      @input="checkInput()"
                    >
                </div>
            </div>
            
            <div class="right ds-flex">
                <div class="data-box short">
                    <h3 class="item-title">사용자 지정 날짜</h3>
                    <div v-if="datepicker.show" class="datepicker-box">
                      <DatePicker
                        v-model="datepicker.selectedDate"
                        class="datepicker"
                        mode="date"
                        :minute-increment="60"
                      />
                      <div class="datepicker-btn-box ds-flex center-between">
                        <div class="datepicker-btn" @click="setDatePicker()">확인</div>
                        <div class="datepicker-btn" @click="setDatePicker()">취소</div>
                      </div>
                    </div>
                    <input type="text" v-model="irData.selectedDate" class="input-box input calendar" :class="{'filled' : irData.selectedDate!==''}" @click="datepicker.show = true" placeholder="날짜를 선택해주세요." readonly>
                </div>
                <div class="data-box short">
                    <h3 class="item-title">작성자</h3>
                    <input type="text" v-model="irData.writer" class="input-box text" readonly>
                </div>
            </div>

            <div class="editor-box">
                <h3 class="item-title">내용</h3>
                <div id="editor" ref="editor" @input="checkInput()"></div>
            </div>
        </div>
        <div class="bottom-box ds-flex center-center">
          <div class="btn-box ds-flex">
            <div class="btn gray wide" :class="{'active' : irData.isFilled}" @click="saveList()">Notice {{ msg }}</div>
            <div class="btn blue" @click="movePage('InvestorRelations')">취소</div>
          </div>
        </div>

        <div v-if="showPopup" class="popup-wrapper">
        <!-- <div class="popup-wrapper"> -->
            <div class="popup-box ds-flex center-center">
                <img src="@/assets/login/check_icon.png">
                <div class="popup-text-box ds-flex center-center">
                    <h3 class="ds-flex center-center">Notice {{ msg }} 완료</h3>
                    <p class=" ds-flex center-center">작성하신 내용으로 업로드가 완료되었습니다.</p>
                </div>
                <div class="blue-btn" @click="showPopup=false; movePage('InvestorRelations')">확인</div>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css'
import {API} from "../../api.js";
import axios from "axios";
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import moment from "moment";

export default {
  name: 'IrAdd',
  components: {
    DatePicker
  },
  data: () => ({
    datepicker: {
      selectedDate: '', // 선택값
      show: false,
    },
    user: {},
    upload: {
        server: '',
        dir: 'upload',
        menu: 'ir',
    },
    // server: '',
    editor: null,
    editorHight: 0,
    exlink: false,
    file: {
        title: '',
        contents: '',
        thumbnail: '',
        exlink: false,
        url: ''
    },
    showPopup: false,
    irData:{
      idx: '',
      title: '',
      contents: '',
      writer: 'admin',
      selectedDate: '',
      isFilled: false
    },
    msg: '추가'
  }),
  methods: {
    setDatePicker() {
      let picker = this.datepicker;
      let news = this.irData;

      picker.show = false;
      if(picker.selectedDate !== '' && picker.selectedDate !== null) { // 선택한 값이 있을 경우에만
        news.selectedDate = moment(picker.selectedDate).format('YYYY-MM-DD');
      }
    },
    // idx가 존재하면 해당되는 리스트를 가져온다.
    getList() {
      let singleton = this;
      let param = {
          table: this.upload.menu,
          idx: this.irData.idx,
          option: ''
      }
      API.getList(param, function(status, res){
        let data = singleton.irData;

        for(let key in data){
          if(key === 'selectedDate') {
            data[key] = moment(res.data[0]['date']).format('YYYY-MM-DD');
          } else {
            data[key] = res.data[0][key];
          }
        }
        data.isFilled = true;
        singleton.setEditor();
      });
    },
    /* 선택한 파일을 저장하고, 미리보기 경로를 받아온다. */
    async setFileList() {
      this.file.thumbnail = await this.uploadFile(this.$refs.upload.files[0]);
    },
    setEditor() {
      let contents= (this.irData.contents);
      if(contents === '') {
        contents = '　';
      }

      this.editor = new Editor({
        el: this.$refs.editor,
        height: this.editorHight+'px',
        placeholder: '내용을 입력해주세요.',
        initialValue: contents,
        initialEditType: "wysiwyg",
        language: "ko",
        hooks: {
          addImageBlobHook: async (blob, callback) => {
            const uploadedImageURL = await this.uploadFile(blob);
            if(!uploadedImageURL) {
                alert('파일 업로드 실패\n서버 상태를 확인하세요.');
            } else {
                callback(uploadedImageURL, 'alt text');
            }
          }
        },
      });
    },
    async uploadFile(blob) {
      let upload = this.upload;
      const formData = new FormData();
      formData.append('image', blob);

      return axios(upload.server + '/upload/file', {
          method: 'POST',
          data: formData,
          headers : {'Content-type' : 'multipart/form-data' },
          params: {'menu': this.upload.menu}
      }).then(response => {
          if (response.data) {
            let res = response.data;
            let src = upload.server+'/'+upload.dir+'/'+upload.menu+'/'+res.uploadName;
            // this.fileList.push(res.uploadName);
            return src;
          }
          throw new Error('Server or network error');
      }).catch( () => {
          console.log('error..');
          return false;
      });
    },
    saveList() {
      let data = this.irData;
      data.contents = this.editor.getHTML();
      if(data.isFilled) {
          this.showPopup = true;

          if(data.idx !== undefined) { // UPDATE 
            API.updateIrList(data, function(status, res){
              console.log(status, res);
            })
          } else { // INSERT
            API.insertIrList(data, function(status, res){
              console.log(status, res);
            })
          }
        }
    },
    // 모든 값이 입력되었는지 확인한다.
    checkInput() {
      let data = this.irData;
      data.contents = this.editor.getHTML();
      for(let key in data){
        if(key !== "selectedDate" && data[key] === '') {
          console.log(key + ' >> ', data[key]);
          data.isFilled = false;
          break;
        } else {
          data.isFilled = true;
        }
      }
    },
    movePage(name) {
      this.$router.push({name: name});
    },
    handleResize() {
      let width = window.innerWidth;
      if(width > 1439) { // 1400px 이상
        this.editorHight = 800;
      } else if(width > 1199) { // 1200px 이상
        this.editorHight = 490;
      } else {
        this.editorHight = 300;
      }

      this.setEditor();
    }
  },
  mounted() {
    window.scrollTo(0,0);
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    let data = this.irData;
    // this.user = JSON.parse(sessionStorage.getItem('user_info'));
    // data.writer = this.user.id;
    data.idx = this.$route.query.idx;
    if(data.idx) {
      this.getList();
      this.msg = '수정';
    } 
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
  }
}
</script>

<style scoped>
.iradd-wrapper {
    /* min-height: 1080px; */
    overflow: auto;
}

.toastui-editor-defaultUI {
    z-index: 0;
}

#editor {
  text-align: left;
}

.datepicker {
  border: none;
}

.datepicker-box {
  position: absolute;
  top: 85px;
  z-index: 100;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #00ACEB;
}

.input-box.calendar, .input-box.file {
  cursor: pointer;
}

.datepicker-btn-box {
  width: 170px;
  margin: auto auto 10px auto;
}

.datepicker-btn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #00ACEB;
  color: #00ACEB;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  transition: .3s;
}

.datepicker-btn:hover {
  color: #fff;
  background-color: #00ACEB;
}

.title-box {
  width: 100%;
  height: 70px;
  padding: 15px 20px;
  box-sizing: border-box;
  align-items: center;
}

.tab-title {
  color: #14141C;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.count-box {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
  margin-left: 15px;
}

.blue-highlight {
  color: #00ACEB;
  font-weight: 600;
  margin-left: 3px;
}

.contents-box {
  padding: 0 20px;
}

.table-header {
  width: 100%;
  height: 40px;
  background-color: #555861;
}

.table-th {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-right: 1px solid #fff;
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.table-th:last-child {
  border-right: none;
}

.table-th.title {
  width: calc(100% - 400px);
}

.check-box {
  width: 40px;
}

.check-box img {
  cursor: pointer;
}

.table-body {
  padding-top: 6px;
}

.table-td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #fff;
  border: 1px solid #DCDEE2;
  box-sizing: border-box;
}

.table-td.title {
  width: calc(100% - 400px);
  justify-content: flex-start;
  padding-left: 13px;
}

.no-data-box {
  width: 100%;
  height: 880px;
  background-color: #F2F2F5;
  border: 1px solid #B3B6C0;
  color: #707070;
  font-size: 15px;
  box-sizing: border-box;
}

.btn-box {
  position: absolute;
  right: 20px;
}

.btn {
  width: 110px;
  height: 40px;
  border-radius: 5px;
  background-color: #F2F2F5;
  border: 1px solid;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  margin-right: 10px;
  font-weight: 600;
  box-sizing: border-box;
}

.btn:last-child {
  margin-right: 0;
}

.btn.blue {
  cursor: pointer;
  border-color: #00ACEB;
  color: #00ACEB;
}

.btn.blue:hover {
  color: #fff;
  background-color: #00ACEB;
}

.btn.gray {
  border-color: #B3B6C0;
  color: #B3B6C0;
}

.btn.delete:hover {
  border-color: #FF3E3E;
  color: #FF3E3E;
}

.btn.wide {
    width: 200px;
}

.btn.active {
  cursor: pointer;
  border-color: #00ACEB;
  color: #00ACEB;
  cursor: pointer;
}

.btn.active:hover {
  background-color: #00ACEB;
  color: #fff;
}

.bottom-box {
  position: relative;
  height: 80px;
  padding: 20px;
  box-sizing: border-box;
}

.gray-box {
  width: 100%;
  background-color: #E3E4EA;
  border: 1px solid #B3B6C0;
  padding: 50px;
  box-sizing: border-box;
}

.item-title {
  color: #14141C;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: left;
}

.data-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
}

.right .data-box {
  width: 49%;
}

.short {
    height: 70px;
}

.long {
    height: 80px;
}

.wd50 {
    width: 49%;
}

.input-box {
    width: 100%;
    height: 40px;
    border: 1px solid #B3B6C0;
    border-radius: 5px;
    background-color: #F2F2F5;
    box-sizing: border-box;
    transition: .3s;
    color: #14141c;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

.input-box:hover {
    border-color: #00ACEB;
}

.input-box:focus {
    border-color: #00ACEB;
}

.input-box.input {
    padding: 11px 15px;
}

.input-box.filled {
    border-color: #14141C;
}

.input-box.text {
    border: none;
    border-bottom: 1px solid #14141C;
    border-radius: 0px;
    background-color: transparent;
    padding: 11px 15px;
}

.input-box.select {
    position: absolute;
    top: 35px;
    transition: .3s;
    overflow: hidden;
    border-color: #14141C;
    cursor: pointer;
}

.input-box.select:hover {
    border-color: #00ACEB;
}

.input-box.select:hover path {
    fill: #00ACEB;
    transition: .3s;
}

.input-box.select .option {
    height: 40px;
    padding: 0 15px;
    transition: .3s;
}

.input-box.select .option:hover {
    color: #00ACEB;
    background-color: #E0E9F5;
}

.file-box {
    width: 100%;
}

.file-box  .btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    line-height: 14px;
}

.left {
    width: 50%;
    padding-right: 0.7%;
    box-sizing: border-box;
    align-content: space-between;
}

.right {
    width: 50%;
    /* min-width: 392px; */
    box-sizing: border-box;
    align-content: space-between;
    justify-content: space-between;
}

.preview-box {
    width: 100%;
    height: 245px;
    border: 1px solid #B3B6C0;
    background-color: #F2F2F5;
    transition: .3s;
    box-sizing: border-box;
    position: relative;
}

.noimage-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.noimage-text {
    color: #B3B6C0;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin-top: 5px;
}

.popup-wrapper {
    width: 100%;
    height: 100vh;
    background-color: rgba(14, 14, 19, 0.7);
    position: absolute;
    top: 0;
    left: 0;
}

.popup-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    height: 200px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 29px 0;
}

.popup-box h3 {
    width: 100%;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    height: 18px;
    align-content: center;
    margin-bottom: 10px;
}

.popup-box p {
    font-size: 12px;
    color: #000000;
    height: 14px;
    width: 100%;
    align-content: center;
}
 
.popup-box .blue-btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    margin: 0 5px;
    background-color: #00ACEB;
    align-content: center;
    cursor: pointer;
    transition: .3s;
    color: #fff;
    border-radius: 5px;
}

.popup-box .blue-btn:hover {
    background-color: #0088BB;
}

.popup-text-box {
    width: 100%;
    height: 82px;
    align-content: center;
}

.editor-box {
    width: 100%;
    padding-top: 30px;
    z-index: 0;
}

.editor-box .item-title {
    padding-bottom: 10px;
}

@media screen and (max-width: 1439px) {
  .contents-wrapper {
      width: calc(100% - 270px);
  }

  .contents-box {
    padding: 0 15px 0 20px;
  }

  .title-box {
    padding: 12px 20px;
    height: 40px;
    align-content: center;
  }

  .tab-title {
    font-size: 14px;
    line-height: 17px;
  }

  .item-title {
    font-size: 11px;
    line-height: 13px;
    padding-left: 5px;
  }

  .short {
    height: 48px;
  }

  .long {
    height: 55px;
  }

  .input-box {
    height: 30px;
    font-size: 11px;
    border-radius: 3px;
  }

  .input-box.input {
    padding: 5px 10px;
  }

  .input-box.text {
    padding: 5px 10px;
  }

  .btn {
    width: 80px;
    height: 25px;
    border-radius: 3px;
    font-size: 11px;
  }

  .file-box .btn {
    width: 55px;
    height: 20px;
    border-radius: 3px;
    font-size: 9px;
    margin-right: 5px;
  }

  .file-box .btn:last-child {
      margin-right: 0;
  }

  .left {
    width: 50%;
  }
  
  .right {
    width: 50%;
  }

  .preview-box {
    width: 275px;
    height: 172px;
  }

  .noimage-box > img {
    width: 30px;
    height: 30px;
  }

  .noimage-box p {
    font-size: 11px;
  }

  .editor-box {
    padding-top: 20px;
  }

  .bottom-box {
    padding: 13px 15px;
    height: 50px;
  }

  .btn.wide {
    width: 150px;
  }
}
</style>

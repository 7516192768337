<template>
    <div class="admin-wrapper ds-flex">
      <div class="mobile-header ds-flex center-center">
        <img :src="menuBtn" class="menu-btn" @click="openTab(!tabIsOpen)">
        <div class="header-logo-box ds-flex center-center">
          <img src="@/assets/admin/logo.svg">
          <h2>홈페이지 관리</h2>
        </div>
      </div>
      <div class="tab-wrapper" :style="tabStyle">
        <div class="logo-box ds-flex center-between">
            <!-- <img src="@/assets/admin/logo_row.png" @click="movePage(tabList[0].name)"> -->
            <img src="@/assets/admin/logo_row.png" @click="logout('Home')">
            <p class="blue-text">홈페이지 관리</p>
        </div> 
        <div class="user-box ds-flex center-start">
            <img src="@/assets/admin/profile_icon.png">
            <div class="user-info-box">
                <!-- <p class="blue-text">{{ user.id }}</p> -->
                <p class="blue-text">admin</p>
                <p class="access-box">최근 접속 : {{ date }}</p> 
            </div>
            <div class="logout-btn ds-flex center-center" @click="logout('Login')">로그아웃</div>
        </div>
        <div class="tab-box">
            <div 
              v-for="tab in tabList"
              :key="tab"
              class="tab ds-flex center-between"
              :class="{'active' : currTab === tab.name}"
              @click="movePage(tab.name)"
          >
            {{ tab.title }}
            <img v-if="currTab === tab.name" src="@/assets/login/r_arrow_w_icon.svg">
          </div>
        </div>
      </div>
      <router-view class="contents-wrapper" :key="$route.fullPath"></router-view>
    </div>
</template>

<script>

export default {
  name: 'AdminView',
  components: {
    
  },
  data: () => ({
    user: {},
    tabStyle: {},
    tabIsOpen: false,
    menuBtn: require("@/assets/admin/menu_btn.svg"),
    date: null,
    tabList:[
      { 
        name: "Popup",
        title: "팝업",
      },
      { 
        name: "HistoryAd",
        title: "연혁",
      },
      { 
        name: "Certificate",
        title: "기술 / 특허",
      },
      { 
        name: "News",
        title: "뉴스",
      },
      { 
        name: "InvestorRelations",
        title: "Notice",
      },
      { 
        name: "Publication",
        title: "Publications",
      },
    ],
    currTab: ''
  }),
  methods: {
    setDate() {
      let today = new Date();
      let year = today.getFullYear(); // 년도
      let month = today.getMonth() + 1;  // 월
      let date = today.getDate();  // 날짜
      let hours = today.getHours(); // 시
      let minutes = today.getMinutes();  // 분
      if(minutes < 10) {
        minutes = '0'+minutes;
      }

      this.date = year+"-"+month+"-"+date+" "+hours+":"+minutes;
    },
    logout(menu) {
      sessionStorage.removeItem('access_token');
      this.$router.push({name: menu});
    },
    movePage(name) {
      this.currTab = name;
      this.$router.push({name: name});
    },
    handleResize() {
        let width = window.innerWidth;
        if(width > 1030) {
          // this.tabStyle = {'left': 0}
        } else { // 769px ~ 1200px
          // this.tabStyle = {'left': '-1500px'}
        }
    },
    openTab(isOpen) {
      this.tabIsOpen = isOpen;
      if(isOpen) {
        this.tabStyle = {'left': '0px'};
        this.menuBtn = require("@/assets/admin/X_btn.svg");
      } else {
        this.tabStyle = {'left': '-1500px'};
        this.menuBtn = require("@/assets/admin/menu_btn.svg");
      }
    }
  },
  mounted() {
    if(sessionStorage.getItem('access_token') !== null) {
      // 새로고침 시 현재 페이지가 유지되도록 함
      for(let i=0; i<this.tabList.length; i++){
        if(this.$route.name === this.tabList[i].name) {
          this.currTab = this.tabList[i].name;
        }
      }
      window.scrollTo(0,0);
      // this.user = JSON.parse(sessionStorage.getItem('user_info'));
      this.setDate();
    } else { // 비정상적인 경로로 접근하였을 경우
      this.movePage('Caution');
    }

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
    
  },
  watch: {
    '$route' (to, from) {
      console.log('router >> ', to, from);
    }
  }
}
</script>

<style scoped>
.admin-wrapper {
  
}

.mobile-header {
  display: none;
}

.tab-wrapper {
  /* min-height: 1080px; */
  width: 360px;
  background-color: #14141C;
  height: 100vh;
}

.contents-wrapper {
  width: calc(100% - 360px);
  background-color: #EDEEF2;
  height: 100vh;
}

.logo-box, .user-box {
  width: 100%;
  height: 70px;
  padding: 0 20px;
  border-bottom: 1px solid #434349;
  box-sizing: border-box;
}

.logo-box img {
  cursor: pointer;
}

.blue-text {
  font-size: 15px;
  font-weight: 600;
  color: #00ACEB;
}

.user-box img {
  width: 40px;
  height: 40px;
}

.user-info-box {
  width: 200px;
  height: 36px;
  padding: 0 15px;
  box-sizing: border-box;
}

.user-info-box > p {
  line-height: 20px;
  text-align: left;
}

.access-box {
  font-size: 11px;
  color: #B0B0B0;
}

.logout-btn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #00ACEB;
  background-color: transparent;
  cursor: pointer;
  color: #00ACEB;
  font-size: 12px;
  box-sizing: border-box;
  align-content: center;
  transition: .3s;
}

.logout-btn:hover {
  background-color: #00ACEB;
  color: #fff;
}

.tab {
  width: 100%;
  height: 60px;
  padding: 21px 30px;
  box-sizing: border-box;
  color: #B0B0B0;
  border-bottom: 1px solid #434349;
  line-height: 1;
  text-align: left;
  cursor: pointer;
  font-size: 15px;
  transition: .3s;
  font-weight: 400;
}

.tab:hover {
  background-color: #30323D;
  color: #fff;
  font-weight: 600;
}

.tab.active {
  background-color: #30323D;
  color: #fff;
  font-weight: 600;
}

@media screen and (max-width: 1439px) {
  .logo-box img {
    width: 124px;
    height: 22px;
  }

  .tab-wrapper {
    width: 270px;
  }

  .blue-text {
    font-size: 11px;
  }

  .access-box {
    font-size: 8px;
    width: 100%;
  }

  .logout-btn {
    width: 55px;
    height: 20px;
    font-size: 9px;
    border-radius: 3px;
  }

  .user-box img {
    width: 25px;
    height: 25px;
  }

  .user-info-box {
    width: 160px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }

  .logo-box, .user-box {
    height: 40px;
    padding: 0 15px;
  }

  .tab {
    padding: 13.5px 15px;
    font-size: 11px;
    height: 40px;
  }

  .tab > img {
    width: 16px;
    height: 16px;
  }

  .user-info-box > p {
    line-height: 15px;
  }
}

/* @media screen and (max-width: 1030px) {
  .mobile-header {
    display: inline-block;
    width: 100%; 
    height: 60px;
    background-color: #14141C;
    position: fixed;
    border-bottom: 1px solid #434349;
    z-index: 15;
  }

  .mobile-header > .menu-btn {
    position: absolute;
    left: 15px;
    top: 17px;
    cursor: pointer;
  }

  .header-logo-box {
    height: 60px;
  }

  .mobile-header > .header-logo-box h2 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }

  .mobile-header > .header-logo-box img {
    width: 36px;
    height: 30px;
    margin-right: 10px;
  }

  .tab-wrapper {
    width: 100%;
    height: calc(100% - 60px);
    position: absolute;
    top: 60px;
    left: -1500px;
    background-color: rgba(20, 20, 28, 0.9);
    z-index: 10;
    transition: .5s;
  }

  .tab, .user-box {
    background-color: #14141C;
    padding: 0 65px;
    font-size: 18px;
    font-weight: 600;
    height: 60px;
    color: #fff;
  }

  .user-box img {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }

  .user-info-box {
    align-content: space-between;
    padding: 0;
  }

  .access-box {
    font-size: 10px;
  }

  .blue-text {
    font-size: 18px;
  }

  .tab:last-child {
    border-bottom: none;
  }

  .tab > img {
    width: 25px;
    height: 25px;
  }

  .logo-box {
    display: none;
  }

  .logout-btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    position: absolute;
    right: 65px;
  }
} */
</style>

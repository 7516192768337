<template>
    <div class="leadership-wrapper">
      <div class="leadership-box ds-flex center-between">
        <div class="leader-box ds-flex center-start" v-for="(list, i) in leaderList" :key="list">
          <div class="image-box">
            <img :src="list.src">
          </div>
          <div class="career-box ds-flex" :class="{'short' : i===1}">
            <div class="blue-box">{{ list.posit }}</div>
            <p>{{ list.name }}</p>
            <div class="career-info">
              <p v-for="info in list.info" :key="info">{{ info }}</p>
            </div>
            <a class="link" :href="list.link" target="_blank">
              <h3>자세히 보기</h3>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_12_357)">
                <path d="M8.01009 16C3.59143 16 0 12.4086 0 7.98991C0 3.57125 3.59143 0 8.01009 0C12.4288 0 16.0202 3.59143 16.0202 8.01009C16.0202 12.4288 12.4288 16.0202 8.01009 16.0202V16ZM8.01009 0.807062C4.03531 0.807062 0.807062 4.03531 0.807062 8.01009C0.807062 11.9849 4.03531 15.2131 8.01009 15.2131C11.9849 15.2131 15.2131 11.9849 15.2131 8.01009C15.2131 4.03531 11.9647 0.807062 8.01009 0.807062Z" fill="#1E1E1E"/>
                <path d="M9.01897 4.43884L8.45403 5.00379L11.0568 7.60656H3.34937V8.39345H11.1779L8.45403 10.9962L9.01897 11.5612L12.6508 8.07062L9.01897 4.43884Z" fill="#1E1E1E"/>
                </g>
                <defs>
                <clipPath id="clip0_12_357">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'Leadership',
  components: {
    
  },
  data: () => ({
    leaderList: [
      {
        src: require("@/assets/about/COO.png"),
        posit: "COO",
        name: "이 두 희",
        info: [
          "· 전) 메디컬아이피(주) 연구개발사업부 리더",
          "· 전) 서울대학교병원 영상의학과 선임연구원",
          "· 현) (주)지오비전 COO/상무이사",
          "· 운영총괄, 인공지능 소프트웨어 설계 및 의료기기 인허가",
        ],
        link: "https://sites.google.com/view/getback9"
      },
      // {
      //   src: require("@/assets/about/leader1.png"),
      //   posit: "CMO",
      //   name: "김 우 진",
      //   info: [
      //     "· 현) 강원대학교 진료처장",
      //     "· 서울대학교 의대 졸업",
      //     "· 강원대병원 호흡기 내과 교수",
      //     "· 대한호흡기학회 이사",
      //   ]
      // },
      {
        src: require("@/assets/about/leader2.png"),
        posit: "CTO",
        name: "최 현 수",
        info: [
          "· 전) SK 텔레콤 책임연구원",
          "· 전) 강원대학교 컴퓨터공학과 교수",
          "· 현) 서울과학기술대학교 컴퓨터공학과 교수",
          "· 현) (주)지오비전 CTO/기술이사",
          "· 연구소 총괄, AI 연구 개발 및 SW 제품화",
        ],
        link: "https://mix.seoultech.ac.kr/"
      },
    ]
  }),
  methods: {
  },
}
</script>
  
<style scoped>
.leadership-wrapper {
  padding: 80px 0;
  color: #191E2C;
  border-top: 1.5px solid #00ACEB;
}

.leadership-box {
  max-width: 1240px;
  margin: auto;
  min-height: calc(100vh - 919px);
  align-content: space-between;
}

.leader-box {
  max-width: 600px;
}

.image-box {
  width: 215px;
  height: 270px;
  box-sizing: border-box;
}

.career-box {
  box-sizing: border-box;
  padding: 10px 0 0 20px;
  width: 385px;
  height: 270px;
  align-content: space-between;
}

.career-box > p {
  color: #1E1E1E;
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
}

.blue-box {
  width: 80px;
  height: 30px;
  background-color: #00ACEB;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
}

.career-info {
  width: 100%;
  height: 150px;
}

.career-info > p {
  color: #505050;
  font-size: 16px;
  text-align: left;
}

.link {
  font-size: 14px;
  line-height: 16px;
  color: #1E1E1E;
  border: 1.5px solid #1E1E1E;
  width: 140px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 15px;
  box-sizing: border-box;
  transition: .3s;
}

.link:hover {
  background-color: #1E1E1E;
  color: white;
}

svg path {
  transition: .3s;
}

.link:hover svg path {
  fill: white;
}

@media screen and (max-width: 1240px) {
  .leadership-wrapper {
    padding: 40px 0;
  }
  
  .blue-box {
    width: 50px;
    height: 20px;
    font-size: 11px;
    line-height: 20px;
  }

  .career-box {
    width: 270px;
    height: 140px;
    padding: 0 0 0 15px;
  }

  .career-box > p {
    font-size: 14px;
    line-height: 20px;
  }

  .career-info {
    height: 85px;
  }

  .career-info > p {
    font-size: 11px;
    line-height: 11px;
    padding: 3px 0;
  }

  .image-box {
    width: 112px;
    height: 141px;
  }

  .image-box > img {
    width: 100%;
    height: 100%;
  }

  .leadership-box {
    max-width: 768px;
    height: 145px;
    min-height: calc(100vh - 631px);
  }

  .link {
    font-size: 11px;
    width: 120px;
    height: 25px;
  }
}

@media screen and (max-width: 768px) {
  .leadership-wrapper {
    padding: 0 40px;
    border-top: none;
  }

  .leader-box {
    max-width: 100%;
    width: 100%;
    padding-bottom: 60px;
  }

  .career-box {
    padding-left: 30px;
    width: calc(100% - 112px);
  }

  .career-info {
    display: flex;
    flex-wrap: wrap;
  }

  .career-info > p {
    width: 100%;
  }

  .image-box > img {
    width: 112px;
    height: 141px;
  }

  .career-box.short {
    width: calc(100% - 112px);
  }

  .leadership-box {
    height: 100%;
    align-content: flex-start;
    min-height: calc(100vh - 520px);
  }
}

@media screen and (max-width: 480px) {
  .career-box {
    padding-left: 0;
    width: 100% !important;
    justify-content: center;
    padding-top: 15px;
    height: 250px;
  }

  .leader-box {
    border-bottom: 1px solid #DBDEE7;
    justify-content: center;
    padding-top: 25px;
  }

  .leader-box:first-child {
    padding-top: 0;
  }

  .career-info {
    align-content: flex-start;
    height: 205px;
  }

  /* .career-info:first-child {
    height: 180px;
  }

  .career-info:last-child {
    height: 220px;
  } */

  .career-info > p {
    font-size: 16px;
    width: 100%;
    line-height: 35px;
  }

  .leadership-wrapper {
    height: 100%;
    padding: 0 40px;
  }

  .blue-box {
    width: 80px;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
  }

  .career-box > p {
    font-size: 20px;
    line-height: 30px;
  }

  .image-box {
    width: 149px;
    height: 188px;
  }

  .image-box > img {
    width: 100%;
    height: 100%;
  }

  /* .career-info > p[data-v-aedf4992]:last-child {
    line-height: 18px;
  } */
}
</style>

<template>
    <div class="location-wrapper">
      <div class="contents-wrapper">
        <div class="location-title title-box ds-flex start-start">
          <div class="mini-blue-box"></div>
          <h1>춘천 본사</h1>
        </div>
        <div class="chuncheon-box location-box">
          <div class="map-box">
            <div class="map">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d662.1274446306788!2d127.73772722070524!3d37.869018742838286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3562e5ce98e47241%3A0x2e2d9a2097fa0558!2z6rCV7JuQ64yA7ZWZ6rWQIOuztOuTrOq0gA!5e0!3m2!1sko!2skr!4v1668665615904!5m2!1sko!2skr" 
                width="100%" :height='height+"px"' style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <!-- <div class="detail-btn-box">
                <div class="detail-btn-black ds-flex center-center" @click="openMap('https://goo.gl/maps/5hSCCo13qREeBmSb8')">지도 자세히 보기</div>
                <div class="detail-btn-blue"></div>
              </div> -->
            </div>
            <div class="location-info-box ds-flex">
              <div class="ds-flex center-start">
                <img src="@/assets/about/address_b.svg">
                <div class="loca-text-box">
                  <p class="deep">ADDRESS</p>
                  <p>강원특별자치도 춘천시 강원대학길 1 보듬관 404호 · 405호</p>
                </div>
              </div>              
              <div class="ds-flex center-start">
                <img src="@/assets/about/call_b.svg">
                <div class="loca-text-box">
                  <p class="deep">CALL / FAX</p>
                  <p>+82. 033 - 244 - 7705 / +82. 033 - 244 - 7706</p>
                </div>
              </div>              
              <div class="ds-flex center-start">
                <img src="@/assets/about/subway_b.svg">
                <div class="loca-text-box">
                  <p class="deep">SUBWAY</p>
                  <p class="ds-flex">경춘선 <strong>남춘천역 1번 출구</strong> 방면 도보로 약 30분</p>
                </div>
              </div>              
              <div class="ds-flex center-start">
                <img src="@/assets/about/bus_b.svg">
                <div class="loca-text-box">
                  <p class="deep">BUS</p>
                    <div class="mini-box light">일반</div>
                    <p class="ds-flex center-start" style="width: auto">300</p>
                </div>
              </div>              
            </div>
          </div>
        </div>

        <div class="leadership-title title-box ds-flex start-start">
          <div class="mini-blue-box"></div>
          <h1>서울 사무소</h1>
          <!-- <p>서울 사무소</p> -->
        </div>
        <div class="seoul-box location-box">
          <div class="map-box">
            <div class="map">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.041222818369!2d126.89432691594965!3d37.48335357981369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e24f25b8799%3A0x6093f94dd623a869!2z7L2U7Jik66Gx65SU7KeA7YS47YOA7JuM67mM656A7Yq4!5e0!3m2!1sko!2skr!4v1667541342724!5m2!1sko!2skr" 
                width="100%" :height='height+"px"' style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <!-- <div class="detail-btn-box">
                <div class="detail-btn-black ds-flex center-center" @click="openMap('https://goo.gl/maps/7cs6fZecsj8W2SM76')">지도 자세히 보기</div>
                <div class="detail-btn-blue"></div>
              </div> -->
            </div>
            <div class="location-info-box ds-flex">
              <div class="ds-flex center-start">
                <img src="@/assets/about/adress_sb.svg">
                <div class="loca-text-box">
                  <p class="light">ADDRESS</p>
                  <p>서울 구로구 디지털로32길 30 코오롱디지털타워빌란트 1차, 1410호</p>
                </div>
              </div>              
              <div class="ds-flex center-start">
                <img src="@/assets/about/call_sb.svg">
                <div class="loca-text-box">
                  <p class="light">CALL</p>
                  <p>+82. 02 - 6205 - 7705</p>
                </div>
              </div>              
              <div class="ds-flex center-start">
                <img src="@/assets/about/subway_sb.svg">
                <div class="loca-text-box">
                  <p class="light">SUBWAY</p>
                  <p class="ds-flex">지하철 2호선 <strong>구로디지털단지역 2번 출구</strong> 방면 도보로 약 10분</p>
                </div>
              </div>              
              <div class="ds-flex center-start">
                <img src="@/assets/about/bus_sb.svg">
                <div class="loca-text-box">
                  <p class="light">BUS</p>
                    <div class="mini-box green">지선</div>
                    <p class="ds-flex center-start" style="width: auto">5536, 5616</p>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'Location',
  components: {
    
  },
  data: () => ({
    height: 0
  }),
  methods: {
    openMap(url) {
      window.open(url);
    },
    handleResize() {
      let width = window.innerWidth;
      if(width > 1240) {
        this.height = 500;
      }else {
        this.height = 300;
      }
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>
.location-wrapper {
  padding-top: 80px;
  color: #191E2C;
  border-top: 1.5px solid #00ACEB;
}

.contents-wrapper {
  width: 1240px;
  margin: auto;  
}

.location-box {
  padding-top: 30px;
  margin-bottom: 85px;
}

.mini-blue-box {
  width: 8px;
  height: 8px;
  background-color: #00ACEB;
}

.location-wrapper .title-box h1 {
  font-size: 22px;
  font-weight: bold;
  width: 100%;
  text-align: left;
  padding-left: 10px;
}

.location-wrapper .title-box p {
  font-size: 18px;
  font-weight: bold;
}

.map {
  position: relative;
  width: 100%;
}

.detail-btn-box {
  position: absolute;
  right: 30px;
  bottom: -15px;
  cursor: pointer;
  z-index: 1;
}

.detail-btn-box div {
  width: 227px;
  height: 60px;
}

.detail-btn-black {
 background-color: #191E2C;
 color: #fff;
 font-size: 20px;
}

.detail-btn-blue {
  background-color: #00ACEB;
  position: absolute;
  right: -7px;
  top: 7px;
  z-index: -1;
}

.location-info-box {
  padding-top: 30px;
  height: 160px;
  align-content: space-between;
}

.loca-text-box {
  height: 45px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.location-info-box > div {
  width: 620px;
}

.location-info-box p {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  text-align: left;
}

.location-info-box p.deep {
  color: #0C2E6A;
  font-weight: bold;
}

.location-info-box p.light {
  color: #00ACEB;
  font-weight: bold;
}

.location-info-box img {
  padding-right: 15px;
}

.mini-box {
  width: 45px;
  height: 25px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.mini-box.light {
  background-color: #87C700;
}

.mini-box.green  {
  background-color: #3CC344;
}

strong {
  font-weight: bold;
  line-height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 1240px) {
  .contents-wrapper {
    width: 768px;
  }

  .location-wrapper {
    padding: 40px 0 0 0;
  }
  
  .title-box {
    position: relative;
  }
  
  .location-wrapper .title-box h1 {
    font-size: 18px;
  }

  .mini-blue-box {
    width: 4px;
    height: 4px;
    position: absolute;
    top: 3px;
    left: 3px;
  }

  .location-box {
    height: 500px;
    padding-top: 20px;
    margin-bottom: 0;
  }

  .location-info-box img {
    width: 50px;
    height: 50px;
    padding-right: 13px;
  }

  .location-info-box > div {
    width: 384px;
  }
  
  .location-info-box p {
    font-size: 11px;
  }

  .location-info-box p.deep,  .location-info-box p.light {
    font-size: 14px;
  }
  
  .location-info-box {
    padding-top: 20px;
    height: 130px;
  }

  .loca-text-box {
    height: 40px;
    width: 300px;
  }

  .mini-box {
    width: 40px;
    height: 20px;
    font-size: 11px;
  }

  .bold {
    line-height: 4px;
  }
}

@media screen and (max-width: 768px) {
  .location-wrapper {
    border-top: none;
    padding: 0 40px;
  }

  .contents-wrapper {
    width: 100%;
  }

  .location-info-box > div {
    width: 50%;
  }

  .loca-text-box {
    /* height: 100%; */
    width: 270px;
  }

  .location-info-box img {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .location-info-box > div {
    width: 100%;
    min-width: 360px;
    margin-bottom: 25px;
  }

  .location-box {
    height: 650px;
  }

  .location-info-box img {
    width: 50px;
    height: 50px;
    padding-right: 10px;
  }

  .location-info-box p.deep, .location-info-box p.light {
    font-size: 16px;
  }

  .location-info-box p {
    font-size: 13px;
  }
}

@media screen and (max-width: 415px) {
  .location-wrapper {
    padding: 0 20px;
  }
}
</style>

import { createWebHashHistory, createRouter } from "vue-router";
// import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home";
import Login from "./components/Login";
import Caution from "./components/Caution";
import Join from "./components/Join";
import AboutView from "./components/about/AboutView";
import Introduction from "./components/about/Introduction";
import History from "./components/about/History";
import Leadership from "./components/about/Leadership";
import TechAndPatent from "./components/about/TechAndPatent";
import Partner from "./components/about/Partner";
import Publications from "./components/about/Publications";
import CorpIdentity from "./components/about/CorpIdentity";
import ProductView from "./components/product/ProductView";
import SocialAll from "./components/product/social/AllProduct";
import ZioSummary from "./components/product/social/ZioSummary";
import ZioVIS from "./components/product/social/ZioVIS";
import ZioKeeper from "./components/product/social/ZioKeeper";
import ZioEdge from "./components/product/social/ZioEdge";
import MedicalAll from "./components/product/medical/AllProduct";
import ZioMed from "./components/product/medical/ZioMed";
import AiCDSS from "./components/product/medical/AiCDSS";
import SmartHospital from "./components/product/medical/SmartHospital";
import NewsView from "./components/news/NewsView";
import Newsroom from "./components/news/Newsroom";
import NewsDetailView from "./components/news/NewsDetailView";
import IR from "./components/news/InvestorRelations";
import IRDetailView from "./components/news/IrDetailView";
import AdminView from "./components/admin/AdminView";
// import Login from "./components/admin/Login";
import Popup from "./components/admin/Popup";
import PopupAdd from "./components/admin/PopupAdd";
import HistoryAd from "./components/admin/History";
import Certificate from "./components/admin/Certificate";
import CertificateAdd from "./components/admin/CertificateAdd";
import News from "./components/admin/News";
import NewsAdd from "./components/admin/NewsAdd";
import InvestorRelations from "./components/admin/Ir";
import IrAdd from "./components/admin/IrAdd";
import Publication from "./components/admin/Publication";
import PublicationAdd from "./components/admin/PublicationAdd";
// import News from "./components/admin/NewsView";
import NewsWriteView from "./components/admin/backup/NewsWriteView";
import ContactView from "./components/contact/ContactView";
import Location from "./components/contact/Location";
import Inquiry from "./components/contact/Inquiry";


const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/caution",
        name: "Caution",
        component: Caution,
    },
    {
        path: "/join",
        name: "Join",
        component: Join,
    },
    /* about */
    {
        path: "/about",
        name: "AboutView",
        component: AboutView,
        children: [
            {
                path: "greeting",
                name: "Introduction",
                component: Introduction,
            },
            {
                path: "history",
                name: "History",
                component: History,
            },
            {
                path: "location",
                name: "Location",
                component: Location,
            },
            {
                path: "leader",
                name: "Leadership",
                component: Leadership,
            },
            {
                path: "tech",
                name: "TechAndPatent",
                component: TechAndPatent,
            },
            {
                path: "partner",
                name: "Partner",
                component: Partner,
            },
            {
                path: "publication",
                name: "Publications",
                component: Publications,
            },
            {
                path: "corp",
                name: "CorpIdentity",
                component: CorpIdentity,
            },
        ]
    },
    /* product */
    {
        path: "/product",
        name: "ProductView",
        component: ProductView,
        children: [
            {
                path: "social",
                name: "SocialAll",
                component: SocialAll,
            },
            {
                path: "ziosummary",
                name: "ZioSummary",
                component: ZioSummary,
            },
            {
                path: "ziovis",
                name: "ZioVIS",
                component: ZioVIS,
            },
            {
                path: "ziokeeper",
                name: "ZioKeeper",
                component: ZioKeeper,
            },
            {
                path: "ziodege",
                name: "ZioEdge",
                component: ZioEdge,
            },
            {
                path: "medical",
                name: "MedicalAll",
                component: MedicalAll,
            },
            {
                path: "ziomed",
                name: "ZioMed",
                component: ZioMed,
            },
            {
                path: "aicdss",
                name: "AiCDSS",
                component: AiCDSS,
            },
            {
                path: "smarthospital",
                name: "SmartHospital",
                component: SmartHospital,
            },
        ]
    },
    /* news */
    {
        path: "/news",
        name: "NewsView",
        component: NewsView,
        children: [
            {
                path: "newsroom",
                name: "Newsroom",
                component: Newsroom,
            },
            {
                path: "view",
                name: "NewsDetailView",
                component: NewsDetailView,
            },
            {
                path: "write",
                name: "NewsWriteView",
                component: NewsWriteView,
            },
            {
                path: "ir",
                name: "IR",
                component: IR,
            },
            {
                path: "contents",
                name: "IRDetailView",
                component: IRDetailView,
            },
        ]
    },
    /* contact */
    {
        path: "/contact",
        name: "ContactView",
        component: ContactView,
        children: [
            {
                path: "location",
                name: "Location",
                component: Location,
            },
            {
                path: "inquiry",
                name: "Inquiry",
                component: Inquiry,
            },
        ]
    },
    /* admin */
    {
        path: "/admin",
        name: "AdminView",
        component: AdminView,
        children: [
            {
                path: "popup",
                name: "Popup",
                component: Popup,
            },
            {
                path: "popup/add",
                name: "PopupAdd",
                component: PopupAdd,
            },
            {
                path: "history",
                name: "HistoryAd",
                component: HistoryAd,
            },
            {
                path: "certificate",
                name: "Certificate",
                component: Certificate,
            },
            {
                path: "certificate/add",
                name: "CertificateAdd",
                component: CertificateAdd,
            },
            {
                path: "news",
                name: "News",
                component: News,
            },
            {
                path: "news/add",
                name: "NewsAdd",
                component: NewsAdd,
            },
            {
                path: "ir",
                name: "InvestorRelations",
                component: InvestorRelations,
            },
            {
                path: "ir/add",
                name: "IrAdd",
                component: IrAdd,
            },
            {
                path: "publication",
                name: "Publication",
                component: Publication,
            },
            {
                path: "publication/add",
                name: "PublicationAdd",
                component: PublicationAdd,
            },
        ]
    },
]


export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router
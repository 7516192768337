<template>
    <div class="bar-wrapper ds-flex center-start">
        <div class="ds-flex start-center">
            <div class="logo ds-flex center-start">
                <img src="@/assets/main/White.png">
                <!-- <div class="admin-btn-box ds-flex center-center" @click="test()"> -->
                <div class="admin-btn-box ds-flex center-center" @click="movePage('Login')">
                    관리자 페이지
                </div>
            </div>
            <div class="bar-box ds-flex">
                <p>(주) 지오비전 / 대표(CEO) 김 윤</p>
                <p class="pc">24341 강원특별자치도 춘천시 강원대학길 1 보듬관 404-405호 / 08390 서울 구로구 디지털로 32길 30 코오롱디지털타워빌란트 1410호</p>
                <p class="mobile">24341 강원특별자치도 춘천시 강원대학길 1 보듬관 404-405호</p>
                <p class="mobile">08390 서울 구로구 디지털로 32길 30 코오롱디지털타워빌란트 1410호</p>
                <div class="ds-flex">
                    <div class="info-box"><h3>Tel</h3><p> 033.244.7705 / </p></div>
                    <div class="info-box"><h3>Fax</h3><p> 033.244.7706 / </p></div>
                    <div class="info-box"><h3>Email</h3><p> sales@ziovision.co.kr</p></div>
                </div>
            </div>
            <div class="ds-flex center-start bottom-box">
                <p class="copyright">Copyright ZIOVISION Co., Ltd. All rights reserved.</p>
            </div>
            <div class="top-btn" @click="scrollToTop()" @mouseover="setTopBtn(true)" @mouseout="setTopBtn(false)">
            <img :src="topBtnSrc"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'BottomBar',
  components: {
    
  },
  data: () => ({
    topBtnSrc: require("@/assets/main/top_btn_off.svg")
  }),
  methods: {
    // test() {
    //     window.open('https://ziovision.ncpworkplace.com/authn/oauthLogin');
    // },
    movePage(name) {
      this.$router.push({name: name});
    },
    scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.showTopBtn = false;
    },
    setTopBtn(bool) {
        if(bool) {
            this.topBtnSrc = require("@/assets/main/top.png");
        } else {
            this.topBtnSrc = require("@/assets/main/top_btn_off.svg");
        }
     }
  },
}
</script>

<style>
.bar-wrapper {
    align-content: center;
    height: 307px;
    position: relative;
    margin: auto;
    background-color: #182038;
    /* padding: 60px 342px; */
    box-sizing: border-box;
}

.bar-wrapper .logo {
    width: 100%;
}

.bar-wrapper .logo img {
    width: 150px;
}

.bar-wrapper > div {
    width: 1240px;
    height: 180px;
    align-content: space-between;
    margin: auto;
}

.bar-box {
    width: 100%;
    text-align: left;
}

.bar-box p {
    color: #979CA8;
    font-size: 15px;
    line-height: 25px;
    width: 100%;
    letter-spacing: 0.5px;
}

.copyright {
    font-size: 15px;
    color: #979CA8;
}

.mobile {
    display: none;
}

.admin-btn-box {
    width: 100px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 3px;
    color: #fff;
    font-size: 13px;
    margin-left: 16px;
    cursor: pointer;
    line-height: 0;
}

.bottom-box {
    width: 100%;    
}

.top-btn {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 60px;
  bottom: 60px;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0px 2px 5px #8b8b8b29;
}

.info-box {
    display: flex;
}

.info-box > * {
    line-height: 18px;
}

.info-box h3 {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #fff;
}

.info-box p {
    margin-right: 10px;
    color: #fff;
}

@media screen and (max-width: 1240px) {
    .bar-wrapper {
        height: 230px;
    }

    .bar-wrapper > div {
        width: 768px;        
        height: 150px;
    }

    .bar-wrapper .logo img {
        width: 100px;
    }

    .admin-btn-box {
        width: 85px;
        height: 24px;
        font-size: 12px;
    }

    .bar-box p, .copyright {
        font-size: 13px;
    }

    .top-btn, .top-btn img {
        width: 40px;
        height: 40px;
        bottom: 40px;
        right: 40px;
    }
}

@media screen and (max-width: 768px) {
    .bar-wrapper > div {
        width: 688px;
        padding: 0 30px;
    }

    .mobile {
        display: inline-block;
    }

    .pc {
        display: none;
    }

    .bar-box p, .copyright {
        line-height: 20px;
    }

    .bar-wrapper {
        height: 250px;
    }
}

@media screen and (max-width: 480px) {
    .bar-wrapper > div {
        width: 360px;
        height: 180px;
    }

    .top-btn, .top-btn img {
        right: 20px;
        bottom: 30px;
    }
}
</style>

<template>
    <div class="social-all-wrapper">
      <div class="product-box ds-flex center-center">
        <div v-for="product in productList" :key="product" class="ds-flex" @click="movePage(product.name)">
          <img :src="product.src">
          <div class="product-ex-box">
            <h1>{{ product.title }}</h1>
            <p>{{ product.ex }}</p>
          </div>
          <div class="more-btn ds-flex center-center">더 알아보기</div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: '',
  components: {
    
  },
  data: () => ({
    productList: [
      {
        name: 'ZioSummary',
        title: 'Zio-Summary',
        ex: 'AI 동영상 요약 · 스마트 고속검색 시스템',
        src: require("@/assets/product/social/4.png")
      },
      {
        name: 'ZioVIS',
        title: 'ZioVIS',
        ex: '지능형 관제 시스템',
        src: require("@/assets/product/social/1.png")
      },
      {
        name: 'ZioKeeper',
        title: 'ZioKeeper',
        ex: 'AI 동영상 요약 · 스마트 고속검색 시스템',
        src: require("@/assets/product/social/2.png")
      },
      {
        name: 'ZioEdge',
        title: 'ZioEdge',
        ex: '엣지 디바이스를 활용한 AI 개인 · 소규모 관제 시스템',
        src: require("@/assets/product/social/3.png")
      },
    ]
  }),
  methods: {
    movePage(name) {
      this.$router.push({name: name});
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.social-all-wrapper {
  color: #191E2C;
}

.title-box h1 {
  padding: 0 24px 0 120px;
  color: #191E2C;
}

.product-box {
  padding: 80px 0px 150px 0px;
}

.product-box > div {
  width: 1280px;
  height: 393px;
  padding: 40px;
  transition: .3s;
  border: 2px solid #DDDDDD;
  position: relative;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-bottom: 40px;
  cursor: pointer;
}

.product-box > div:last-child {
  margin-bottom: 0;
}

.product-box > div:hover {
  border: 2px solid #00ACEB;
}

.product-box > div:hover .more-btn {
  background-color: #00ACEB;
  color: #fff;
  text-decoration: underline;
  border-top: 2px solid #00ACEB;
  border-left: 2px solid #00ACEB;
}

.more-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 239px;
  height: 80px;
  transition: .3s;
  font-size: 20px;
  border-top: 2px solid #DDDDDD;
  border-left: 2px solid #DDDDDD;
  box-sizing: border-box;
}

.product-ex-box {
  padding: 30px 48px;
}

.product-ex-box h1 {
  text-align: left;
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 19px;
}

.product-ex-box p {
  text-align: left;
  font-size: 20px;
  color: #7A7A7A;
}
</style>

<template>
    <div class="caution-wrapper ds-flex center-center">
        <p>비정상적인 접근입니다.</p>
        <button @click="movePage('Login')">관리자 로그인</button>
    </div>
</template>

<script>

export default {
  name: 'Caution',
  components: {
  },
  data: () => ({
  }),
  methods: {
    movePage(name) {
      this.$router.push({name: name});
    },
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.caution-wrapper {
    height: 100vh;
    align-content: center;
}

.caution-wrapper p {
    width: 100%;
}
</style>

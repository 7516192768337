<template>
    <div class="join-wrapper">
        <img class="bg" src="@/assets/login/bg.png">
        <div class="join-box ds-flex">
            <h3>회원가입</h3>
            <div class="input-wrapper ds-flex">
                <div class="data-box ds-flex center-between">
                    <p class="ds-flex">아이디</p>
                    <input type="text" v-model="user.id" placeholder="ID" class="input-box input id" :class="[{'filled' : user.id.length>0}, {'error' : id.unavailable}]" @input="initAvailable('id'); checkInput()">
                    <div class="blue-btn ds-flex center-center" :class="{'error' : id.unavailable}" @click="checkId()">중복확인</div>
                    <p v-if="id.available" class="text-mini ds-flex center-start blue">* 사용 가능한 아이디 입니다.</p>
                    <p v-else-if="id.unavailable" class="text-mini ds-flex center-start red">* 이미 사용중인 아이디 입니다.</p>
                </div>
                <div class="data-box ds-flex"> 
                    <p class="ds-flex">비밀번호</p>
                    <input type="password" v-model="user.pw" placeholder="PW" class="input-box input" :class="{'filled' : user.pw.length>0}" @input="initAvailable('pw'); checkInput()" @keyup="checkPw()">
                </div>
                <div class="data-box ds-flex">
                    <p class="ds-flex">비밀번호 확인</p>
                    <input type="password" v-model="user.confirm" placeholder="PW" class="input-box input" :class="{'filled' : user.confirm.length>0}" @input="initAvailable('pw'); checkInput()" @keyup="checkPw()">
                    <p v-if="pw.available" class="text-mini ds-flex center-start blue">* 비밀번호가 일치합니다.</p>
                    <p v-else-if="pw.unavailable" class="text-mini ds-flex center-start red">* 비밀번호가 일치하지 않습니다.</p>
                </div>
                <div class="data-box ds-flex">
                    <p class="ds-flex">이름</p>
                    <input type="text" v-model="user.name" placeholder="홍길동" class="input-box input" :class="{'filled' : user.name.length>0}" @input="checkInput()">
                </div>
                <div class="data-box ds-flex">
                    <p class="ds-flex">휴대폰 번호</p>
                    <input type="text" v-model="user.number" placeholder="010-0000-0000" class="input-box input" :class="{'filled' : user.number.length>0}" @input="checkInput()">
                </div>
                <div class="data-box ds-flex">
                    <p class="ds-flex">이메일</p>
                    <input type="text" v-model="user.email" placeholder="example@ziovision.co.kr" class="input-box input" :class="{'filled' : user.email.length>0}" @input="checkInput()">
                </div>
                <div class="data-box ds-flex">
                    <p class="ds-flex">소속</p>
                    <div class="input-box select" :style="comboBox.style">
                    <div 
                        v-for="(option, i) in comboBox.option" 
                        :key="option" 
                        class="option ds-flex center-between" 
                        @click="setComBox(option)"
                    >
                        {{ option }}
                        <svg id="arrow" v-if="i===0" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.0006 1.8577C15.0006 2.06371 14.927 2.26972 14.7432 2.44139L8.34616 8.72466C8.34616 8.72466 7.94175 8.99933 7.6844 8.99933C7.42704 8.99933 7.20646 8.89633 7.02263 8.75899L0.257927 2.47573C-0.072955 2.13238 -0.072955 1.58303 0.257927 1.23968C0.58881 0.89633 1.21381 0.930665 1.58146 1.23968L7.6844 6.90491L13.4197 1.27401C13.7873 0.930665 14.3388 0.930665 14.7065 1.27401C14.8903 1.44569 15.0006 1.68603 15.0006 1.89204V1.8577Z" :fill=comboBox.fill />
                        </svg>
                    </div>
                </div>
                </div>
            </div>
            <div class="btn-wrapper ds-flex center-between">
                <div class="btn-box ds-flex center-center" :class="{'active': user.isFilled&&id.available&&pw.available}" @click="apprReq()">승인 요청</div>
                <div class="btn-box active ds-flex center-center" @click="setPopup('cancel')">취소</div>
            </div>
        </div>

        <div v-if="popup.show" class="popup-wrapper">
        <!-- <div class="popup-wrapper"> -->
            <div class="popup-box ds-flex center-center">
                <img :src="popup.content.src">
                <div class="popup-text-box ds-flex center-center">
                    <h3 class="ds-flex center-center">{{ popup.content.title }}</h3>
                    <p class=" ds-flex center-center">{{ popup.content.text }}</p>
                </div>
                <div class="blue-btn" :class="[popup.type === 'cancel' ? 'red-btn': '']" @click="movePage('Login')">확인</div>
                <div v-if="popup.type === 'cancel'" class="blue-btn red-btn" @click="popup.show = false;">취소</div>
            </div>
        </div>

        <img class="logo" src="@/assets/login/logo_oper.svg">
    </div>
</template>

<script>
// import axios from "axios"
import {API} from "../api.js";

export default {
  name: 'joinView',
  components: {
    
  },
  data: () => ({
    comboBox:{ 
        isopen: false,
        style: {},
        origin: ['기획', '영업', '경영지원', '개발'], // 기존 순서
        option: [], // 사용자가 선택한 옵션에 따라 순서가 바뀜
        selected: '', // 사용자가 선택한 옵션
        fill: '#ffffff', // 화살표 색상
    },
    user: {
        id: '',
        pw: '',
        confirm: '',
        name: '',
        number: '',
        email: '',
        selected: '기획',
        isFilled: false, // 모든 데이터가 채워졌는지 체크
    },
    popup: {
        type: '',
        show: false,
        confirm: {
            src: require("@/assets/login/check_icon.svg"),
            title: "승인요청 완료",
            text: "승인요청이 완료되었습니다.",
        },
        cancel: {
            src: require("@/assets/login/important_icon.svg"),
            title: "회원가입을 취소하시겠습니까?",
            text: "취소할 경우, 작성된 내용은 전부 사라집니다.",
        },
        content: {}
    },
    id: { // id 중복 확인
        available: false,
        unavailable: false
    },
    pw: { // pw 일치확인
        available: false,
        unavailable: false
    },
    height: 0,
    h1920px: 40,
    h1200px: 30,
  }),
  methods: {
    // 모든 값이 입력되었는지 확인한다.
    checkInput() {
        let user = this.user;
        for(let key in user){
            if(user[key] === '') {
                user.isFilled = false;
                break;
            } else {
                user.isFilled = true;
            }
        }
    },
    apprReq() {
        if(this.user.isFilled&&this.id.available&&this.pw.available) {
            let singleton = this;
            API.signUp(this.user, function(status, res){
                if(res.data.result === "Success") {
                    singleton.setPopup('confirm');
                }
            })
        } 
    },
    checkId() {
        let singleton = this;
        API.checkDuplication(this.user.id, function(status, res){
            if(res.data.result === "Available") {
                singleton.id.available = true;
            } else {
                singleton.id.unavailable = true;
            }
        })
    },
    checkPw() {
        let pw = this.user.pw;
        let confirm = this.user.confirm;

        if(pw !== '' && confirm !== '') {
            if(pw === confirm) {
                this.pw.available = true;
            } else {
                this.pw.unavailable = true;
            }
        }
    },
    initAvailable(category){
        if(category === 'id') {
            this.id.available = false;
            this.id.unavailable = false;
        } else if(category === 'pw') {
            this.pw.available = false;
            this.pw.unavailable = false;
        }
    },
    setOption() {
        let comb = this.comboBox;
        comb.option = [];
        
        if(comb.selected === '') {
            for(let i=0; i<comb.origin.length; i++) {
                comb.option.push(comb.origin[i]);
            }
        } else {
            comb.option.push(comb.selected);
            for(let i=0; i<comb.origin.length; i++) {
                if(comb.origin[i] !== comb.selected) {
                    comb.option.push(comb.origin[i]);
                }
            }
        }
    },
    setComBox(selected) {
        let comb = this.comboBox;
        let select = document.getElementsByClassName('select')[0];
        let option = document.getElementsByClassName('option');
        let arrow = document.getElementById('arrow');
        
        if(comb.isopen) {
            for(let i=0; i<option.length; i++) {
                option[i].classList.remove('hover', 'active');
            }
            select.classList.remove('active');
            comb.style = {'background-color': 'transparent'};
            comb.fill = '#ffffff';
            comb.selected = selected;
            this.user.selected = selected;
            arrow.style.transform = `rotate(360deg)`
            this.setOption();
        } else {
            for(let i=0; i<option.length; i++) {
                if(i===0) { option[i].classList.add('hover', 'active'); } // comboBox가 열려있을 경우에만 hover css 동작하도록
                else { option[i].classList.add('hover'); }
            }
            select.classList.add('active');
            comb.style = {'background-color': '#14141C', 'border-color': '#00ACEB'};
            comb.fill = '#00ACEB';
            arrow.style.transform = `rotate(180deg)`
        }

        comb.isopen = !comb.isopen;
    },
    setPopup(type) {
        let popup = this.popup;
        popup.type = type;
        popup.show = true;
        
        if(type==='confirm') {
            popup.content = popup.confirm;
        } else {
            popup.content = popup.cancel;
        }
    },
    movePage(name) {
      this.popup.show = false;
      this.$router.push({name: name}); 
    },
    handleResize() {
        let width = window.innerWidth;
        if(width > 1200) { // 1201px 이상
            this.height = this.h1920px;
        } else if(width > 768) { // 769px ~ 1200px
            this.height = this.h1200px;
        }
    }
  },
  mounted() {
    window.scrollTo(0,0);
    this.setOption();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
  }
}
</script>

<style scoped>
.join-wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.join-wrapper .bg {
    width: 100%;
    height: 100vh;
}

.join-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 410px;
    height: 872px;
    /* height: 800px; */
    align-content: space-between;
}

h3 {
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    width: 100%;
    height: 30px;
}

.input-wrapper {
    width: 100%;
    height: 655px;
    /* height: 600px; */
    align-content: space-between;
}

.btn-wrapper {
    width: 100%;
}

.btn-box {
    width: 195px;
    height: 40px;
    background-color: transparent;
    border: 1px solid #707070;
    border-radius: 5px;
    color: #707070;
    font-size: 15px;
    align-content: center;
    transition: .3s;
}

.btn-box.active {
    cursor: pointer;
    border: 1px solid #00ACEB;
    color: #00ACEB;
}

.btn-box.active:hover {
    background-color: #00ACEB;
    color: #fff;
}

.data-box {
    position: relative;
    width: 100%;
    height: 65px;
    align-content: space-between;
    box-sizing: border-box;
}

.data-box > p {
    color: #FFFFFF;
    font-size: 15px;
    text-align: left;
    line-height: 15px;
    align-content: center;
    width: 100%;
}

.data-box .text-mini {
    padding-top: 5px;
    font-size: 11px;
    position: absolute;
    bottom: -20px;
}

.text-mini.blue {
    color: #00ACEB;
}

.text-mini.red {
    color: #FF3E3E;
}

.input-box {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    color: #fff;
    transition: .3s;
}

.input-box.id{
    width: 300px;
}

.input-box.select {
    border: 1px solid #fff;
    overflow: hidden;
    position: absolute;
    top: 25px;
    cursor: pointer;
}

.input-box.select.active {
    height: 120px;
}

.input-box.select:hover {
    border-color: #00ACEB;
}

.input-box.select:hover .option:first-child  {
    color: #00ACEB;
}

.input-box.select:hover path {
    fill: #00ACEB;
    transition: .3s;
}

.input-box.select .option {
    height: 40px;
    text-align: left;
    box-sizing: border-box;
    padding: 0 15px 2px 15px;
    color: #ffffff;
    transition: .3s;
    font-size: 15px;
}

.input-box.select .option.active {
    color: #00ACEB;
}

.input-box.select .option.hover:hover {
    background-color: #1B1B25;
}

.blue-btn {
    background-color: #00ACEB;
    align-content: center;
    cursor: pointer;
    transition: .3s;
    width: 100px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    font-size: 15px;
}

.blue-btn:hover {
    background-color: #0088BB;
}

.blue-btn.error {
    background-color: #FF3E3E;
}

.red-btn {
    background-color: #fff;
    color: #FF3E3E;
    border: 1px solid #FF3E3E;
}

.red-btn:hover {
    background-color: #FF3E3E;
    color: #fff;
}

.input-box.input {
    padding: 11px 15px;
    border: 1px solid #707070;
    background-color: transparent;
    font-size: 15px;
}

.input-box.input:hover {
    border-color: #00ACEB;
}

.input-box.input:focus {
    border-color: #00ACEB !important;
}

.input-box.input.error {
    border-color: #FF3E3E !important;
}

.input-box.input.filled {
    border-color: #ffffff;
}

.popup-wrapper {
    width: 100%;
    height: 100vh;
    background-color: rgba(14, 14, 19, 0.7);
    position: absolute;
    top: 0;
    left: 0;
}

.popup-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    height: 200px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 29px 0;
}

.popup-box h3 {
    width: 100%;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
    height: 18px;
    align-content: center;
    margin-bottom: 10px;
}

.popup-box p {
    font-size: 12px;
    color: #000000;
    height: 14px;
    width: 100%;
    align-content: center;
}
 
.popup-box .blue-btn {
    width: 80px;
    height: 30px;
    font-size: 12px;
    margin: 0 5px;
}

.popup-text-box {
    width: 100%;
    height: 82px;
    align-content: center;
}

.logo {
    position: absolute;
    right: 50px;
    bottom: 40px;
}

@media screen and (min-width: 1201px) {
    .join-wrapper .bg {
        min-width: 1920px;
        min-height: 1080px;
    }
}

@media screen and (max-width: 1200px) {
    .join-wrapper .bg {
        min-width: 1200px;
        min-height: 675px;
    }
    
    .join-box {
        width: 250px;
        height: 600px;
    }

    .join-box h3 {
        font-size: 18px;
    }
    
    .input-wrapper {
        height: 470px;
    }

    .logo {width: 120px;
        height: 55px;
    }

    .input-box {
        height: 30px;
        border-radius: 4px;
    }

    .input-box.input {
        padding: 5px 10px;
        font-size: 11px;
    }

    .input-box.id {
        width: 170px;
    }

    .data-box {
        height: 50px;
    }

    .data-box > p {
        font-size: 11px;
        line-height: 13px;
    }

    .blue-btn {
        width: 70px;
        height: 30px;
        font-size: 11px;
    }

    .btn-box {
        width: 120px;
        height: 30px;
        font-size: 11px;
        border-radius: 4px;
    }
    
    .input-box.select {
        height: 30px;
    }
   
   .input-box.select.active {
        height: 90px;
    }

    .input-box.select .option {
        font-size: 11px;
        height: 30px;
        padding: 0 10px;
    }

    #arrow {
        width: 12px;
    }

    .popup-box {
        width: 265px;
        height: 140px;
        padding: 20px;
    }

    .popup-box > img {
        width: 20px;
        height: 20px;
    }

    .popup-box h3 {
        font-size: 11px;
        margin-bottom: 5px;
    }

    .popup-box p {
        font-size: 9px;
    }

    .popup-box .blue-btn {
        width: 55px;
        height: 20px;
        font-size: 9px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .popup-text-box {
        height: 60px;
    }
}

@media screen and (max-width: 768px) {
    .logo {
        display: none;
    }

    .join-box {
        width: 360px;
        height: 925px;
    }

    .join-box h3 {
        font-size: 22px;
    }

    .input-wrapper {
        height: 733px;
    }

    .data-box {
        height: 75px;
    }

    .data-box > p {
        font-size: 16px;
    }

    .input-box {
        height: 50px;
    }

    .input-box.id {
        width: 245px;
    }

    .blue-btn {
        width: 100px;
        height: 50px;
        font-size: 16px;
    }

    .input-box.input {
        font-size: 16px;
        padding: 0 15px;
    }

    .input-box.select {
        height: 50px;
    }

    .input-box.select.active {
        height: 200px;
    }

    .input-box.select .option {
        height: 50px;
        font-size: 16px;
        padding: 0 15px;
    }

    .btn-box {
        width: 170px;
        height: 50px;
        font-size: 16px;
    }

    .data-box .text-mini {
        font-size: 13px;
    }

    #arrow {
        width: 18px;
        height: 9px;
    }

    .popup-box {
        width: 380px;
        height: 220px;
    }

    .popup-box > img {
        width: 40px;
        height: 40px;
    }

    .popup-box h3 {
        font-size: 16px;
    }

    .popup-box p {
        font-size: 14px;
    }

    .popup-box .blue-btn {
        width: 90px;
        height: 40px;
        font-size: 14px;
        border-radius: 5px;
    }
}
</style>

<template>
    <div class="ir-wrapper">
        <div class="ir-list-box">
            <div class="list-box ds-flex center-between" style="padding-bottom: 20px">
                <p class="count-box ds-flex center-start">총 <span class="blue-highlight">{{ corrList.length }}개</span>의 Notice가 있습니다.</p>
                <div class="search-box ds-flex center-between">
                    <input type="text" placeholder="검색어를 입력해주세요" v-model="searchText" @keyup.enter="searchList()">
                    <img src="@/assets/news/search.png" @click="searchList()" />
                </div>
            </div>
            <div class="ir-list ds-flex start-start">
                <div v-for="list in currPageList" :key="list" class="ir-box" @click="openDetailPage(list)">
                    <div class="ir-title-box ds-flex start-between">
                        <div class="title-box ds-flex">
                            <p class="title">{{list.title}}</p>
                            <p class="date">{{list.date}}</p>
                        </div>
                        <div class="detail-btn">
                            자세히 보기
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 25 25">
                                <defs>
                                <clipPath id="clip-path">
                                    <rect id="사각형_91" data-name="사각형 91" width="25" height="25" fill="#1e1e1e"/>
                                </clipPath>
                                </defs>
                                <g id="arrow_icon_b" transform="translate(0 0)">
                                <g id="그룹_486" data-name="그룹 486" transform="translate(0 0)" clip-path="url(#clip-path)">
                                    <path id="패스_20" data-name="패스 20" d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.516,12.516,0,0,1,12.5,25m0-23.739A11.238,11.238,0,1,0,23.736,12.5,11.253,11.253,0,0,0,12.5,1.261" transform="translate(0 0)" fill="#1e1e1e"/>
                                    <path id="패스_21" data-name="패스 21" d="M10.515,2.2l-.883.883,4.056,4.056H1.658V8.386H13.878l-4.233,4.05.86.9,5.7-5.446Z" transform="translate(3.568 4.732)" fill="#1e1e1e"/>
                                </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-box ds-flex center-center">
                <pagenation ref="pagenation" @page="changePage"></pagenation>
            </div>
        </div>
    </div>
</template>

<script>
import Pagenation from "../Pagenation.vue";
import {API} from "../../api.js";
import moment from "moment";

export default {
  name: 'ir',
  components: {
    Pagenation
  },
  data: () => ({
    server: '',
    irList: [], // 전체 뉴스 리스트
    corrList: [], // 선택한 연도에 해당되는 리스트
    currPageList: [], // 현재 페이지에서 보여주고 있는 리스트
    currPage: 1,
    currTab: 'All',
    searchText: ''
  }),
  methods: {
    // 검색 결과에 맞는 리스트를 설정한다.
    searchList() {
        let list = this.newsList;
        let txt = (this.searchText).toUpperCase();

        this.currPage = 1;
        this.corrList = [];

         for(let i=0; i<list.length; i++) {
            let title = (list[i].title).toUpperCase();
            if(title.includes(txt)) {
                this.corrList.push(list[i]);
            }
            if(i===list.length-1) {
                this.$refs.pagenation.setSize(this.corrList.length, 12);
                this.setCurrList();
            }
        }
    },
    // 선택한 연도에 맞게 corrList를 설정한다.
    setList(year) {
        let list = this.newsList;
        this.currTab = year;
        this.currPage = 1;
        this.corrList = [];

        if(year !== 'All')
         for(let i=0; i<list.length; i++) {
            if(list[i].year === year) {
                this.corrList.push(list[i]);
            }

            if(i===list.length-1) {
                this.$refs.pagenation.setSize(this.corrList.length, 12);
                this.setCurrList();
            }
        } else {
            this.corrList = this.newsList;
            this.$refs.pagenation.setSize(this.corrList.length, 12);
            this.setCurrList();
        }
    },
    // 전체 리스트를 가져온다.
    getIrList() {
        let singleton = this;
        API.getIrList(function(status, res){
            if(res.data.length > 0) {
                for(let i=0; i<res.data.length; i++) {
                    singleton.irList.push(res.data[i]);
                    singleton.irList[i].date = moment(singleton.irList[i].date).format('YYYY-MM-DD')

                    if(i === res.data.length-1) {
                        singleton.$refs.pagenation.setSize(singleton.irList.length, 12);
                        singleton.corrList = singleton.irList;
                        singleton.setCurrList();
                    }
                }
            }
        })
    },
    openDetailPage(ir) {
        this.$router.push({
            name:'IRDetailView',
            query: {idx: ir.idx}
        });
    },
    // 현재 페이지에 보여질 리스트 set
    setCurrList() {
      this.currPageList = [];
      var range = this.currPage * 12;

      for (let i = range - 12; i < range; i++) {
        if (this.corrList[i] !== undefined) {
          this.currPageList.push(this.corrList[i]);
        }
      }
    },
    // 페이지 이동 버튼 클릭 시 동작
    changePage(page) {
      window.scrollTo(0,0);
      this.currPage = page;
      this.setCurrList();
    },
  },
  mounted() {
    this.getIrList();
  },
  created() {
  }
}
</script>

<style scoped>
.ir-wrapper {
    color: #191E2C;
    border-top: 1.5px solid #00ACEB;
    padding: 80px 0;
}

.ir-title{
    padding-left: 120px;
}

.ir-title h1 {
  font-size: 45px;
  font-weight: bold;
  padding-right: 24px;
  padding-left: 50px;
}

.ir-title p {
  font-size: 20px;
  font-weight: bold;
}

.ir-wrapper .ir-title h1 {
    width: 100%;
    text-align: left;
    padding-left: 0;
}

.ir-title p {
    color: #505050;
}

.top-box {
    position: relative;
    padding-top: 250px;
    border-bottom: 1.5px solid #DDDDDD;
    overflow: hidden;
    height: 445px;
    box-sizing: border-box;
}

.top-box-bg {
    position: absolute;
    bottom: -130px;
    right: 120px;
}

.tab-box {
    margin: 70px 0 0 70px;
    box-sizing: border-box;
}

.year {
    font-weight: bold;
    color: #A3A3A3;
    font-size: 20px;
    width: 170px;
    border-right: 2px solid #A3A3A3;
}

.year.last {
    border-right: none;
}

.tab {
    width: 170px;
    cursor: pointer;
}

.tab > .blue-box {
    width: 74px;
    height: 4px;
    background-color: #191E2C;
    margin-top: 20px;
    transition: .5s;
    opacity: 0;
}

.tab > .blue-box.active {
    opacity: 1;
}

.ir-wrapper .ir-list-box {
    width: 1240px;
    margin: auto;
    box-sizing: border-box;
}

.news-count {
    padding: 45px 0;
    text-align: left;
    color: #505050;
    font-size: 18px;
    font-weight: 500;
}

.ir-list-box .ir-box {
  width: 100%;
  height: 92px;
  padding: 22px 25px;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid #DBDEE7;
}

.ir-list-box .ir-title-box {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    align-items: center;
} 

.title-box {
    height: 45px;
    align-content: space-between;
}

.ir-list-box .ir-title-box .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #1E1E1E;
    width: 100%;
    text-align: left;
}

.ir-list-box .ir-title-box .date {
    font-size: 14px;
    line-height: 14px;
    color: #979CA8;
}

.ir-list {
    border-top: 2px solid #707070;
    min-height: 20vh;
}

.search-box {
    width: 400px;
    height: 40px;
    border: 1px solid #DDDDDD;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px 15px;
}

.search-box > input {
    border: none;
    height: 100%;
    width: 87%
}

.search-box > img {
    width: 17.5px;
    height: 17.5px;
    cursor: pointer;
}

.bottom-box {
    /* padding: 55px 0 80px 0; */
    max-width: 1365px;
    padding-top: 40px;
}

.count-box {
  color: #B3B6C0;
  font-size: 15px;
  line-height: 18px;
}

.blue-highlight {
  color: #00ACEB;
  font-weight: 600;
  margin-left: 3px;
}

.detail-btn {
    width: 150px;
    height: 40px;
    border: 1.5px solid #1E1E1E;
    color: #1E1E1E;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
}

.ir-box:hover .detail-btn {
  color: #fff;
  background-color: #1E1E1E;
  transition: .3s;
}

.ir-box:hover path {
  fill: #fff;
  transition: .3s;
}

.ir-list {
    min-height: calc(100vh - 1051px);
    align-content: baseline;
}

@media screen and (max-width: 1240px) {
    .ir-wrapper {
        padding: 40px 0;
    }
    
    .ir-wrapper .ir-list-box {
        width: 768px;
    }

    .ir-list-box .ir-box {
        padding: 20px;
        height: 72px;
    }

    .title-box {
        height: 40px;
    }

    .ir-list-box .ir-title-box .title {
        font-size: 14px;
    }

    .ir-list-box .ir-title-box .date {
        font-size: 10px;
    }

    .ir-box .detail-btn {
        width: 115px;
        height: 30px;
        font-size: 12px;
        padding: 8px 10px;
    }

    svg {
        width: 16px;
        height: 16px;
    }

    .search-box {
        width: 246px;
        height: 30px;
        font-size: 11px;
        padding: 0 15px ;
    }

    .search-box > img {
        width: 12px;
        height: 12px;
    }

    .count-box {
        font-size: 12px;
    }

    .ir-list {
        min-height: calc(100vh - 753.5px);
    }
}

@media screen and (max-width: 768px) { 
  .ir-wrapper {
    border-top: none;
    padding: 0 40px;
  }

  .ir-wrapper .ir-list-box {
    width: 100%;
  }

  .bottom-box {
    padding: 40px 0;
  }

  .ir-list {
    min-height: calc(100vh - 672px);
  }
}

@media screen and (max-width: 480px) {
    .list-box {
        padding-left: 20px;
        padding-right: 20px;
    }

    .search-box {
        width: 100%;
    }

    .search-box > input {
        font-size: 12px;
    }

    .ir-list-box .ir-title-box .title {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .ir-list-box .ir-title-box .date {
        font-size: 14px;
    }

    .ir-list-box .ir-box {
        height: 85px;
        padding: 20px 10px;
    }
    
    .ir-box .detail-btn {
        width: 100px;
        height: 30px;
        font-size: 12px;
        padding: 0 10px;
    }

    svg {
        width: 15px;
        height: 15px;
    }

    .count-box {
        font-size: 13px;
    }

    .ir-wrapper {
        width: 360px;
        padding: 0;
        margin: auto;
    }
}
</style>
